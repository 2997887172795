import styled from "@emotion/styled"
import { EventEmitter } from "events"
import { Layer } from "foundation/components/Layer"
import { Markdown } from "foundation/components/markdown"
import { Theme } from "foundation/styles/theme"
import { zIndex } from "foundation/styles/theme/zIndex"
import React, { useEffect, useState } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { ulid } from "ulid"
import closeButtonImage from "./times_w.png"
import immer from "immer"

const globalNoticeEvent = new EventEmitter()

/** `AppNotice`を表示します
 * - 同じIDで表示することで、置き換えが可能です
 */
export function ShowAppNotice(
  text: string,
  color:
    | "primary"
    | "danger"
    | "information"
    | "positive"
    | "warning" = "primary",
  options?: {
    id: string
    autodismiss?: boolean | number
  }
) {
  const id = options?.id ?? ulid()
  globalNoticeEvent.emit("event", { text, color, id } as NoticeWithID)

  const autodismiss = options?.autodismiss !== undefined
  if (autodismiss) {
    const autodismissMsec =
      typeof autodismiss === "number" ? autodismiss : 10_000
    setTimeout(() => {
      globalNoticeEvent.emit("dismiss", id)
    }, autodismissMsec)
  }
}

/** `AppNotice`を隠します */
export function DismissAppNotice(id?: string) {
  globalNoticeEvent.emit("dismiss", id)
}

type Notice = {
  text: string
  translationID?: string
  color?: NoticeColor
}

type NoticeColor = "primary" | "danger" | "information" | "positive" | "warning"

type NoticeWithID = Notice & {
  id: string
}

/** アプリグローバルなお知らせ
 */
export const AppNoticeProvider: React.FC<{}> = () => {
  const [events, setEvents] = useState<NoticeWithID[]>([])

  useEffect(() => {
    globalNoticeEvent.addListener("event", (notice: NoticeWithID) => {
      setEvents((events) => {
        return immer(events, (events) => {
          const cid = events.findIndex((e) => e.id === notice.id)
          if (cid !== -1) {
            events[cid] = notice
          } else {
            events.push(notice)
          }
        })
      })
    })
    globalNoticeEvent.addListener("dismiss", (id?: string) => {
      setEvents((events) => (id ? events?.filter((e) => e.id !== id) : []))
    })
    return () => {
      globalNoticeEvent.removeAllListeners()
    }
  }, [])

  return (
    <Layer>
      {events.length > 0 ? (
        <AppNoticeLayer>
          <TransitionGroup component={null}>
            {events.map((ev) => (
              <CSSTransition key={ev.id} timeout={200} classNames={"item"}>
                <AppNoticeElem color={ev.color ?? "primary"}>
                  <span className="notice-text">
                    <Markdown markdown={ev.text} />
                  </span>
                  <span
                    className="notice-close"
                    onClick={DismissAppNotice.bind(undefined, ev.id)}
                  >
                    <CloseButton />
                  </span>
                </AppNoticeElem>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </AppNoticeLayer>
      ) : null}
    </Layer>
  )
}

const CloseButton = () => <img src={closeButtonImage} alt="close" />

const AppNoticeLayer = styled.div`
  position: fixed;
  left: 30px;
  right: 30px;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column-reverse;

  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`

const AppNoticeElem = styled.div<{ color: NoticeColor }>`
  display: flex;
  justify-content: space-between;
  height: 56px;
  line-height: 56px;
  border-radius: 4px;
  padding: 0 24px 0 32px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  width: 100%;
  margin: 7.5px 0;
  z-index: ${zIndex.appNotice};

  background: ${(props) => {
    const theme = props.theme as Theme
    switch (props.color) {
      case "danger":
        return theme.colors.danger
      case "information":
        return theme.colors.information
      case "positive":
        return theme.colors.positive
      case "warning":
        return theme.colors.warning
      case "primary":
      default:
        return theme.colors.primary
    }
  }};

  .notice-close {
    cursor: pointer;
    img {
      width: 13px;
    }
  }
`
