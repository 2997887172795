import styled from "@emotion/styled"

const DialogWrapper = styled.section`
  background: white;
  padding: 2rem 2.2rem;
`

DialogWrapper.displayName = "Dialog.Dialog"

const Title = styled.h1``

Title.displayName = "Dialog.Title"

const Content = styled.div`
  margin-bottom: 1rem;
`
Content.displayName = "Dialog.Content"

const Footer = styled.div``
Footer.displayName = "Dialog.Footer"

export const Dialog = {
  Dialog: DialogWrapper,
  Title: Title,
  Content: Content,
  Footer: Footer,
}
