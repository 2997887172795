import styled from "@emotion/styled"
import { __ } from "context/i18n/components/I18n"
import { useForm } from "foundation/components/hooks/use-form"
import { useMouted } from "foundation/components/hooks/use-mouted"
import { sendPasswordReset } from "foundation/utils/authenticate"
import React, { useState } from "react"

/** パスワードのリセットフォーム **/
export const ResetForm: React.FC<IResetFormProps> = ({
  presenter: Presenter = ResetFormPresenter,
  defaultEmail = "",
  ...props
}) => {
  const [formState, setFormState] = useState<{
    error: string | undefined
    submitting: boolean
    submitted: boolean
  }>({
    error: undefined,
    submitting: false,
    submitted: false,
  })

  const mouted = useMouted()

  const { values, handleChange, handleSubmit } = useForm(
    { email: defaultEmail },
    async (values) => {
      setFormState({ error: undefined, submitting: true, submitted: false })

      try {
        await sendPasswordReset(
          values.email,
          `${document.location.protocol}//${document.location.host}/login`
        )
        if (!mouted.current) return
        setFormState({ error: undefined, submitting: false, submitted: true })
      } catch (e) {
        console.error(e)
        if (!mouted.current) return
        setFormState({ error: e.message, submitting: false, submitted: false })
      }
    }
  )
  return (
    <Presenter
      defaultEmail={defaultEmail}
      values={values}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      formState={formState}
    />
  )
}

export type IResetFormProps = {
  presenter?: React.ComponentType<IResetFormPresenterProps>
  defaultEmail?: string
}

export type IResetFormPresenterProps = {
  defaultEmail?: string
  handleSubmit: any
  handleChange: any
  formState: any
  values: any
}
const ResetFormPresenter: React.FC<IResetFormPresenterProps> = ({
  ...props
}) => {
  if (props.formState.submitted) {
    return (
      <p>
        {__("login_reset.form.done", {
          values: {
            email: <code>{props.values.email}</code>,
          },
        })}
      </p>
    )
  }
  return (
    <ResetFormElem onSubmit={props.handleSubmit}>
      <label>
        {__`login_reset.form.email`}
        <br />
        <input
          onChange={props.handleChange}
          value={props.values.email || ""}
          required
          disabled={props.formState.submitting}
          readOnly={props.formState.submitting}
          placeholder="you@example.com"
          autoComplete="email"
          type="email"
          name="email"
        />
      </label>
      {props.formState.error ? <p>{props.formState.error}</p> : ""}
      <button disabled={props.formState.submitting} type="submit">
        {__`login_reset.form.email_button`}
      </button>
    </ResetFormElem>
  )
}

const ResetFormElem = styled.form`
  margin: 0 auto;
  width: 280px;
`
