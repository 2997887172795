import React from "react"

/** ページのヘッダーで使用するタイトル **/
export const Title: React.FC<TitleProps> = (props) => (
  <>
    {props.text}
    {props.subText}
  </>
)

export type TitleProps = {
  text: React.ReactNode
  subText?: React.ReactNode
  children?: never
}
