import React from "react"
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom"
import { PageLoading } from "./PageLoading"
import { useUserContext } from "context/auth/hooks/user-context"
import { popNextPath } from "context/auth"

/** 未ログインのユーザーのみが閲覧可能なルート */
export const PublicRoute: React.FC<
  RouteProps & {
    component:
      | React.ComponentType<RouteComponentProps<any>>
      | React.ComponentType<any>

    // ユーザーがログイン済みだった時にリダイレクトするパス
    fallbackPath?: string
  }
> = ({ component: Component, fallbackPath = "/", ...routeProps }) => {
  // const [checked, auth] = useSelector(s => [s.auth.checked, s.auth.user.authn]);
  const { user, checked } = useUserContext()
  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (!checked) return <PageLoading />
        if (user.type !== "guest") {
          if (user.type === "user" && fallbackPath === "/") {
            fallbackPath = popNextPath() || fallbackPath
          }
          return <Redirect to={fallbackPath} />
        }
        return <Component {...props} />
      }}
    />
  )
}
