import {
  StoreLike,
  TCollection,
  TDocument,
} from "../../libs/firebase/commonTypes"
import { WithID } from "../common"
import { TeamFB } from "./Team.fb"
import { Team } from "./Team.type"

export const TeamCollectionID = "teams"

export function TeamCollectionPath<S extends StoreLike>(store: S) {
  return store.collection(TeamCollectionID) as TCollection<S>
}

export function TeamPath<S extends StoreLike>(store: S, teamID: string) {
  return TeamCollectionPath(store).doc(teamID) as TDocument<S>
}

export const deserializeTeam = (team: WithID<TeamFB>): Team => {
  return { ...team, author: team.author.id }
}
