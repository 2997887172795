import {
  StorageRefLike,
  StoreLike,
  TDocument,
  TCollection,
} from "../../libs/firebase/commonTypes"
import { WithID } from "../common"
import { TeamCollectionID } from "../team"
import { Page, PageFB } from "./Page.type"
import { ProjectCollectionID } from "./Project.type"
import { ProjectResourceBasePath } from "./Project"

export function PageCollectionPath<S extends StoreLike>(
  db: S,
  teamID: string,
  projectID: string
) {
  return db
    .collection(TeamCollectionID)
    .doc(teamID)
    .collection(ProjectCollectionID)
    .doc(projectID)
    .collection(PageCollectionID) as TCollection<S>
}

export function PagePath<S extends StoreLike>(
  db: S,
  teamID: string,
  projectID: string,
  pageID: string
) {
  return db
    .collection(TeamCollectionID)
    .doc(teamID)
    .collection(ProjectCollectionID)
    .doc(projectID)
    .collection(PageCollectionID)
    .doc(pageID) as TDocument<S>
}

/** ヘッダー画像のアップロード先Reference */
export function HeaderImagePath<T extends StorageRefLike>(
  rootRef: T,
  {
    pageID,
    ...paths
  }: {
    teamID: string
    projectID: string
    revisionID: string
    pageID: string
  }
): T {
  return ProjectResourceBasePath(rootRef, paths)
    .child("pages")
    .child(pageID)
    .child("header_image")
}

/** コンテンツ内画像のアップロード先Reference */
export function ContentImagePath<T extends StorageRefLike>(
  rootRef: T,
  {
    pageID,
    ...paths
  }: {
    teamID: string
    projectID: string
    revisionID: string
    pageID: string
  }
): T {
  return ProjectResourceBasePath(rootRef, paths)
    .child("pages")
    .child(pageID)
    .child("contents")
}

/** コンテンツ内ファイルのアップロード先Reference */
export function ContentFilePath<T extends StorageRefLike>(
  rootRef: T,
  {
    pageID,
    ...paths
  }: {
    teamID: string
    projectID: string
    revisionID: string
    pageID: string
  }
): T {
  return ProjectResourceBasePath(rootRef, paths)
    .child("pages")
    .child(pageID)
    .child("contents")
}

// Create our initial value...
const initialValue = {
  document: {
    nodes: [
      {
        object: "block",
        type: "paragraph",
        nodes: [
          {
            object: "text",
            text: "A line of text in a paragraph.",
          },
        ],
      },
    ],
  },
}

/** `Page`を`WithID<PageFB>`に変換する */
export const serializePage = (page: Page): WithID<PageFB> => {
  return {
    ...page,
    content: page.content
      ? JSON.stringify(page.content)
      : JSON.stringify(initialValue),
  }
}

/** `WithID<PageFB>`を`Page`に変換する */
export const deserializePage = (page: WithID<PageFB>): Page => {
  return {
    ...page,
    content: page.content ? JSON.parse(page.content) : initialValue,
  }
}

const PageCollectionID = "pages"
