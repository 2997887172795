/**
 * inputとtextareaのコンポーネント
 *
 * Mixinの構造はbulmaのSCSSの構造を踏襲している
 */
import React from "react"
import styled from "@emotion/styled"
import { compose, ResponsiveValue, variant } from "styled-system"
import { css } from "../../styles/theme/theme"
import { InputMixin } from "./base"

/** Inputの大きさを変更するバリエーション
 * テーマのinputSizesで定義してあります
 * @see InputVariantThemeProps
 */
const InputSizeVariant = variant({
  key: "inputSizes",
  prop: "sizes",
})

/** InputSizeVariantのProps */
type InputSizeProps = {
  sizes: ResponsiveValue<number>
}

// TODO: テーマからCSSを引っ張ってくるけど、レスポンシブにできないので方法を考える
const InputStyleVariant = variant({ key: "inputStyles", prop: "inputStyle" })

type InputStyleProps = {
  inputStyle: ResponsiveValue<"rounded" | "static">
}

export const InputBaseMixin = css`
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  max-width: 100%;
  width: 100%;

  &[readonly] {
    box-shadow: none;
  }
`

const textInputVariants = compose(InputStyleVariant, InputSizeVariant)

/** テキスト入力
 *
 * @todo VariantごとにinputStyleのCSSが重複するので直したい
 *
 * **/
export const TextInput = styled<"input">("input")<
  Partial<InputSizeProps & InputStyleProps>
>(() => [InputMixin, InputBaseMixin, textInputVariants])

export type ITextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  Partial<InputSizeProps & InputStyleProps>

const FixedSizeMixin = (props: FixedSizeProps) =>
  props.fixedSize
    ? css`
        resize: none;
      `
    : undefined

type FixedSizeProps = { fixedSize?: boolean }

// TODO modifiers: has-fixed-size
export const TextArea = styled("textarea")<TextAreaVariantProps>`
  ${InputMixin}
  ${InputBaseMixin}

  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: 0.625em;
  resize: vertical;

  &:not([rows]) {
    max-height: 600px;
    min-height: 120px;
  }
  &[rows] {
    height: initial;
  }

  ${InputSizeVariant}
  ${FixedSizeMixin}
`

type TextAreaVariantProps = Partial<InputSizeProps & FixedSizeProps>

export type ITextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  Partial<InputSizeProps & FixedSizeProps>
