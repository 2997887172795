import { IFirebaseApp } from "../../libs/firebase"
import { Team } from "./Team.type"
import { UserCollectionID } from "../user"
export type TeamFB = {
  author: IFirebaseApp.firestore.DocumentReference
  displayName: string
  description: string
}

export const TeamConverter: (
  store: firebase.firestore.Firestore
) => firebase.firestore.FirestoreDataConverter<Team> = (store) => ({
  toFirestore(model) {
    const team: TeamFB = {
      displayName: model.displayName,
      description: model.description,
      author: store.collection(UserCollectionID).doc(model.author),
    }
    return team
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data() as TeamFB
    return { ...data, id: snapshot.id, author: data.author.id }
  },
})
