import { FSImage, FSImageProps } from "foundation/components/FSImage"
import React from "react"
import { Image, ImageProps } from "foundation/components"

// 画像を最大幅で表示するコンポーネント
export type CoverImageProps = Omit<FSImageProps, "css"> & {
  src?: string | null
}

export const CoverImage: React.FC<CoverImageProps> = (props) => {
  // fileRefの無い古いデータも表示できるようにしてあります
  return (
    (props.fileRef && <FSImage width="100%" minWidth={904} {...props} />) || (
      <Image width="100%" minWidth={904} {...props} />
    )
  )
}

export const CoverImageOld: React.FC<Omit<ImageProps, "css">> = (props) => {
  return <Image minWidth={904} width="100%" {...props} />
}
