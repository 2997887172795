import { ulid } from "ulid"
import { SchemaOf } from "../common"
import { TeamCollectionID } from "../team"
import { Page } from "./Page.type"
import { Project, ProjectCollectionID, ProjectPageTree } from "./Project.type"
import {
  StoreLike,
  StorageRefLike,
  TDocument,
  TCollection,
} from "../../libs/firebase/commonTypes"

export function ProjectCollectionPath<S extends StoreLike>(
  db: S,
  teamID: string
) {
  return db
    .collection(TeamCollectionID)
    .doc(teamID)
    .collection(ProjectCollectionID) as TCollection<S>
}

export function ProjectPath<S extends StoreLike>(
  db: S,
  teamID: string,
  projectID: string
) {
  return ProjectCollectionPath(db, teamID).doc(projectID) as TDocument<S>
}

export const ProjectSchema: SchemaOf<Project, "displayName" | "description"> = {
  displayName: { type: "string", empty: false },
  description: { type: "string", empty: true },
}

/** 新規作成時の既定のページ構造 */
export const DefaultPageTree: ProjectPageTree = {
  categories: {
    c1: {
      name: "アイデンティティー",
      pages: [],
    },
    c2: {
      name: "ブランド",
      pages: [],
    },
    c3: {
      name: "プロダクト",
      pages: [],
    },
    c4: {
      name: "マーケティング",
      pages: [],
    },
  },
  category_order: ["c1", "c2", "c3", "c4"],
}

export const EmptyPage = (
  id: string = ulid(),
  displayName = "Empty page",
  content = ""
): Page => {
  return {
    id,
    displayName,
    content: EmptyPageContent(content),
  }
}

export const EmptyPageContent = (content = ""): Page["content"] => ({
  document: {
    nodes: [
      {
        object: "block",
        type: "paragraph",
        nodes: [
          {
            object: "text",
            text: content,
          },
        ],
      },
    ],
  },
})

/** プロジェクト用リソースのアップロード先Reference */
export function ProjectResourceBasePath<T extends StorageRefLike>(
  rootRef: T,
  {
    teamID,
    projectID,
    revisionID,
  }: {
    teamID: string
    projectID: string
    revisionID: string
  }
): T {
  const ref = rootRef
    .child("teams")
    .child(teamID)
    .child("projects")
    .child(projectID)
    .child(revisionID)
  return ref
}
