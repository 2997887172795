import NProgress from "nprogress"
import React, { useEffect, useState } from "react"

export const PageLoading = () => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    NProgress.start()
    let mnt = true
    const timer = setTimeout(() => {
      if (mnt) {
        setMounted(true)
      }
    }, 300)
    return () => {
      mnt = false
      clearTimeout(timer)
      NProgress.done()
    }
  }, [setMounted])
  return <div style={{ opacity: mounted ? 1 : 0 }}>...</div>
}
