/**
 * Promise関連のユーティリティ
 */

/**
 * setTimeoutのPromise版
 *
 * async関数内でスリープしたい時に使います。
 *
 * @param msec スリープ時間(millseconds)
 *
 * @example
 * async () => {
 *  const ret = await fooFunc()
 *  await Sleep(1000) // 1秒待つ
 *  return barFunc(ret)
 * }
 *
 * @remaks
 * とりあえずそれらしいdelayをつけて仮実装する時にAPIクライアントで使ったりするために使います。
 */
export const Sleep = (msec: number) => new Promise((r) => setTimeout(r, msec))
