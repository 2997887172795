import editor from "context/project-editor/slices"
import project from "context/project/slices/projectSlice"
import userContent from "context/user-content/slices/userContentSlice"
import { combineReducers } from "redux"
import { Action } from "@reduxjs/toolkit"
import { ThunkAction } from "redux-thunk"
import { Selector } from "reselect"
import { createTypedReduxHooks, StoreType } from "utils/redux"
import root from "../slices/rootSlice"

const rootReducer = combineReducers({
  root,
  project,
  editor,
  userContent,
})

export default rootReducer

export const { useDispatch, useSelector, useStore } = createTypedReduxHooks(
  rootReducer
)

export type RootStoreType = StoreType<typeof rootReducer>

export type AppThunk<R = void> = ThunkAction<
  R,
  RootStoreType,
  null,
  Action<string>
>

export const rootSelector: Selector<RootStoreType, RootStoreType["root"]> = (
  s
) => s.root

export const projectSelector: Selector<
  RootStoreType,
  RootStoreType["project"]
> = (s) => s.project

export const editorSelector: Selector<
  RootStoreType,
  RootStoreType["editor"]
> = (s) => s.editor
