import { ErrorDetail } from "../../../utils"
import { FirebaseError } from "firebase"

export const parseFirebaseError = (err: Error): ErrorDetail | undefined => {
  if (err.name !== "FirebaseError") return
  const ferr: FirebaseError = err as any
  return {
    error: ferr.message,
    errorID: `firebase.errors.${ferr.code}` as any,
  }
}
