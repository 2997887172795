import { Project } from "@common/domain/project"
import { Team } from "@common/domain/team"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ErrorDetail, IRecord } from "utils"

export type IProjectEditorStore = {
  project?: { id: string; teamID: string }
  content: IRecord<IProjectEditorContent>
}

type IProjectEditorContent = {
  project: Project
  team: Team
  pageCategoryIndex: { [key: string]: string }
}

const initialState: IProjectEditorStore = { content: {} }

const ProjectEditorSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectLoading: (
      state,
      action: PayloadAction<{ id: string; teamID: string }>
    ) => {
      state.project = action.payload
      state.content.state = "loading"
      state.content.error = undefined
    },
    setLoadedProjectContent: (
      state,
      action: PayloadAction<
        | { value: { project: Project; team: Team }; error?: undefined }
        | { error: ErrorDetail }
      >
    ) => {
      if (!action.payload.error) {
        state.content.state = "success"
        state.content.value = {
          ...action.payload.value,
          // pageTree.categoriesから { pageID: categoryID } を導出する
          pageCategoryIndex: createPageCategoryIndex(
            action.payload.value.project.pageTree.categories
          ),
        }
        state.content.error = undefined
      } else {
        state.content.state = "failure"
        state.content.value = undefined
        state.content.error = action.payload.error
      }
    },
    setProject: (
      state,
      action: PayloadAction<
        { project: Project } | { project: Partial<Project>; merge: true }
      >
    ) => {
      if (!state.content.value) return
      if ("merge" in action.payload) {
        state.content.value.project = {
          ...state.content.value.project,
          ...action.payload.project,
        }
      } else {
        state.content.value.project = action.payload.project
      }
      state.content.value.pageCategoryIndex = createPageCategoryIndex(
        state.content.value.project.pageTree.categories
      )
    },
  },
})

export const {
  setProjectLoading,
  setLoadedProjectContent,
  setProject,
} = ProjectEditorSlice.actions
export default ProjectEditorSlice.reducer

const createPageCategoryIndex = (
  categories: Project["pageTree"]["categories"]
): { [x: string]: string } => {
  return Object.fromEntries(
    Object.entries(categories).flatMap(([categoryID, v]) =>
      v.pages.map((p) => [p, categoryID])
    )
  )
}
