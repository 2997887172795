export const getProps = <P extends { [key: string]: any }>(
  props: P,
  ...propTypes: any[]
) => {
  return flatMap(propTypes, (pt) => Object.keys(pt)).reduce((obj, key) => {
    obj[key] = props[key]
    return obj
  }, {})
}

const flatMap = <T, X>(input: T[], f: (elem: T) => X[]): X[] => {
  return input
    .filter((v) => v)
    .reduce<X[]>((l, elem) => {
      l = [...l, ...f(elem)]
      return l
    }, [])
}
