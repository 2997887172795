import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "../store"
import { UpdateManager } from "../updateManager"
import { loadTranslations } from "@common/libs/translation"

const loadInitialLocale = () => localStorage?.getItem("locale") || "ja"

const persistLocale = (locale: string) => {
  if (localStorage && locale) {
    localStorage.setItem("locale", locale)
  }
}

export type IInitialState = {
  updateAvailable: boolean
  locale: string
  messages: any

  // 外部リソースの情報
  resources: {
    userContentBucket: string
  }
}

const initialState: IInitialState = {
  updateAvailable: false,
  locale: loadInitialLocale(),
  messages: null,
  resources: {
    userContentBucket: process.env.REACT_APP_STORAGE_USER_BUCKET!,
  },
}

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setUpdateAvailable: (
      state,
      action: PayloadAction<{ updateAvailable: boolean }>
    ) => {
      state.updateAvailable = action.payload.updateAvailable
    },
    setLocale: (
      state,
      action: PayloadAction<{ locale: string; messages: any }>
    ) => {
      state.locale = action.payload.locale
      state.messages = action.payload.messages
      persistLocale(state.locale)
    },
  },
})

export const { setUpdateAvailable, setLocale } = rootSlice.actions
export default rootSlice.reducer

export const reloadWebsite = (): AppThunk => (dispatch) => {
  console.log("reload worker")
  UpdateManager.shared().allowUpdateSW()
}

export const loadLocaleAction = (
  locale: string
): AppThunk<Promise<void>> => async (dispatch) => {
  const msg = await loadTranslations(locale)
  dispatch(setLocale({ locale, messages: msg }))
}
