/** 文字の修飾
 *
 * TODO: mock.stories.tsxの様な形式に変更する
 */
import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"
import React from "react"
import { Link } from "react-router-dom"
import { EditorMark } from "@common/domain/editor/EditorDataType"

/** 太字 */
export const UTextAnnotationBold = styled.b`
  font-weight: bold;
`

/** 下線 */
export const UTextAnnotationUnderline = styled.span`
  text-decoration: underline;
`

/** 打ち消し線 */
export const UTextAnnotationStrike = styled.span`
  text-decoration: line-through;
`

export type UTextAnnotationColorProps = {
  color: string
}

/** 色指定 */
export const UTextAnnotationColor = styled.span<UTextAnnotationColorProps>`
  color: ${(props) => props.color};
`

/** 小さい文字 */
export const UTextAnnotationSmall = styled.small`
  text-indent: -16px;
  margin-left: 18px;
  display: block;
  font-size: 12px;
  line-height: 18px;
  &::before {
    content: "※ ";
  }
`

/** リンク */
export const UTextAnnotationLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: underline;
  &:hover {
    color: ${colors.darkPrimary};
  }
`

export const UTextAnnotationA = styled.a`
  color: ${colors.primary};
  text-decoration: underline;
  &:hover {
    color: ${colors.darkPrimary};
  }
`

export const UTextAnnotation: React.FC<{
  type: EditorMark
  options?: any
  attributes?: any
}> = React.forwardRef(({ type, attributes, options, ...props }, ref) => {
  const Component = componentType[type]
  return <Component ref={ref} {...attributes} {...options} {...props} />
})

const componentType = {
  [EditorMark.Bold]: UTextAnnotationBold,
  [EditorMark.Strike]: UTextAnnotationStrike,
  [EditorMark.Color]: UTextAnnotationColor,
  [EditorMark.Link]: UTextAnnotationLink,
}
