import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { GuidelineFooter } from "context/layout/components/GuidelineFooter"
import {
  GuidelineContent,
  GuidelineLayout,
  GuidelineMain,
} from "context/layout/templates/GuidelineLayout"
import { ProjectSidebar } from "context/project-editor/components"
import {
  CoverImageOld,
  PageTitle,
  UBlockLink,
  UColorGridItem,
  UFile,
  UHeading,
  DeprecatedUImageBlock,
  ULayoutGrid,
  UList,
  UListItem,
  UTable,
  UTableCell,
  UTableRow,
  UTextAnnotationA,
  UTextAnnotationLink,
  UTextLine,
  UUl,
} from "context/user-content/components/page"
import React, { useCallback } from "react"
import {
  HashRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom"
import blockImage01 from "./assets/block_01.jpg"
import blockImage02 from "./assets/block_02.jpg"
import blockImage03 from "./assets/block_03.jpg"
import blockImage04 from "./assets/block_04.jpg"
import blockImage05 from "./assets/block_05.jpg"
import blockImage06 from "./assets/block_06.jpg"
import coverImagePath from "./assets/coverImg.jpg"
import moodborad1 from "./assets/fujihome_image_001.jpg"
import moodborad2 from "./assets/fujihome_image_002.jpg"
import moodborad3 from "./assets/fujihome_image_003.jpg"
import moodborad4 from "./assets/fujihome_image_004.jpg"
import moodborad5 from "./assets/fujihome_image_005.jpg"
import moodborad6 from "./assets/fujihome_image_006.jpg"
import logoa from "./assets/fujihome_logo_a.png"
import logob from "./assets/fujihome_logo_b.png"
import logoc from "./assets/fujihome_logo_c.png"
import logod from "./assets/fujihome_logo_d.png"
import logo1 from "./assets/logo1.png"
import logo2 from "./assets/logo2.png"
import typoKanji415 from "./assets/typo_kanji415.png"
import notosans from "./assets/typo_notosans.png"

const HujihomePage: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <HashRouter hashType="noslash">
      <GuidelineLayout>
        <Sidebar />
        <GuidelineMain>
          <Switch>
            <Route exact path="/" component={PTop} />
            {/** BRAND */}
            <Route path="/philosophy" component={PPhilosophy} />
            <Route path="/logo" component={PLogo} />
            <Route path="/color" component={PColor} />
            <Route path="/moodboard" component={PMoodboard} />
            <Route path="/download" component={PDownload} />
            <Route path="/information" component={PInformation} />

            {/** web */}
            <Route path="/web-design" component={PWebDesign} />
            <Route path="/web-typography" component={PWebTypography} />
            <Route path="/web-logo" component={PWebLogo} />
            <Route path="/web-color" component={PWebColor} />
            <Route path="/web-writing" component={PWebWriting} />
            <Redirect to="/" />
          </Switch>
          <GuidelineFooter />
        </GuidelineMain>
      </GuidelineLayout>
    </HashRouter>
  )
}

export default HujihomePage

/** サイドバー */
const Sidebar = withRouter((props) => (
  <ProjectSidebar
    logoSrc={logo1}
    logoAlt="sample"
    currentKey="/"
    onSelectPage={props.history.push}
    css={{
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
    }}
    dataDontUse={[
      {
        category: {
          key: "BRAND",
          label: "BRAND",
        },
        pages: [
          {
            key: "philosophy",
            label: "フィロソフィー",
          },
          {
            key: "logo",
            label: "ロゴ",
          },
          {
            key: "color",
            label: "カラー",
          },
          {
            key: "moodboard",
            label: "ムードボード",
          },
          {
            key: "download",
            label: "ダウンロード",
          },
          {
            key: "information",
            label: "基本情報",
          },
        ],
      },
      {
        category: {
          key: "web",
          label: "WEB",
        },
        pages: [
          {
            key: "web-design",
            label: "デザイン原則",
          },
          {
            key: "web-logo",
            label: "ロゴ",
          },
          {
            key: "web-color",
            label: "カラー",
          },
          {
            key: "web-typography",
            label: "タイポグラフィ",
          },
          {
            key: "web-writing",
            label: "ライティング",
          },
        ],
      },
    ]}
  ></ProjectSidebar>
))

const PTop: React.FC<RouteComponentProps> = ({ history }) => {
  const handleBlockLink = useCallback(
    (key: string) => {
      history.push(key)
      window.scrollTo(0, 0)
    },
    [history]
  )

  return (
    <>
      <CoverImageOld src={coverImagePath} />
      <GuidelineContent readOnly>
        <PageTitleSection title="『フジホーム』ブランドガイドライン" />
        <UTextLine
          content={
            <>
              フジホームは東京都葛飾区金町の地域の不動産を専門とする不動産会社です。
              <br />
              当社は常に金町の物件や相場動向に精通するため、金町の不動産に特化して取り扱いを行っています。
              <br />
              お客様お一人ひとりにあった最適な売買プランをご提案できるように、不動産情報の「いつ・いくらで・どこが」をしっかり把握するよう努めています。
              <br />
              <br />
              当サイトはフジホームブランドのデザインガイドラインです。
              <br />
              フジホームの魅力を伝えるため、ブランドイメージの伝達手段における指針として利用してください。
            </>
          }
        />
        <ULayoutGrid itemsPerLine={3}>
          <UBlockLink
            link="philosophy"
            imageSrc={blockImage01}
            alt="sample image"
            category="BRAND"
            title="フィロソフィー"
            onClick={handleBlockLink}
          />
          <UBlockLink
            link="logo"
            imageSrc={blockImage02}
            alt="sample image"
            category="BRAND"
            title="ロゴ"
            onClick={handleBlockLink}
          />

          <UBlockLink
            title="カラー"
            link="color"
            imageSrc={blockImage03}
            alt=""
            category="BRAND"
            onClick={handleBlockLink}
          ></UBlockLink>
          <UBlockLink
            title="ムードボード"
            link="moodboard"
            imageSrc={blockImage04}
            alt=""
            category="BRAND"
            onClick={handleBlockLink}
          ></UBlockLink>
          <UBlockLink
            title="ダウンロード"
            link="download"
            imageSrc={blockImage05}
            alt=""
            category="BRAND"
            onClick={handleBlockLink}
          ></UBlockLink>
          <UBlockLink
            title="基本情報"
            link="information"
            imageSrc={blockImage06}
            alt=""
            category="BRAND"
            onClick={handleBlockLink}
          ></UBlockLink>
        </ULayoutGrid>
      </GuidelineContent>
    </>
  )
}

const PPhilosophy: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="BRAND" title="フィロソフィー" />
    <UTextLine
      content={
        <>
          当社は金町地区を専門で取り扱う不動産会社です。
          <br />
          一般的には葛飾区全域、さらには東京23区全ての物件を取り扱った方がお客様との出会いの機会も増え、よりビジネスチャンスも増すのでしょうが、知らない土地の知らない物件をお客様に満足して頂けるようご案内出来る自信がありませんし、なにより自社から遠く離れた物件の販売活動を地元の会社より密に行うという事が難しいのです。
          <br />
          そのため当社は金町周辺の地域の専門会社になろうと決めました。
          <br />
          地域を絞った会社だから出来るサービスをモットーに、これからお会いさせて頂くお客様全ての方々の幸せと充実のために力の限りを尽くして取り組んでいきたいと思います。
          <br />
          <br />
          【夢に描いたマイホームを持つ】
          <br />
          <br />
          人生で何回とない、お客様の幸せな笑顔が見られるこの瞬間に当社が関わらせて頂ければこの上ない喜びです。
          <br />
          ほとんどの方が一生に一度の買い物ですので色々な不安や悩みがあると思います。
          <br />
          どんな些細なことでも結構ですのでぜひ一度我々に相談してみてください。
          <br />
          必ずお客様の夢の実現の為に全社員一丸となって尽力する事をここにお約束致します。
        </>
      }
    />
    <UHeading level={2} content="企業理念" />
    <UList>
      <UUl>
        <UListItem>全社員とその家族の心と物の幸せを最大限追及する</UListItem>
        <UListItem>
          全社員の人間的成長を願い、社会から必要とされる集団に成る
        </UListItem>
        <UListItem>
          不平等をなくし社員全員がお互いを尊重しあい切磋琢磨し成長していく会社に成る
        </UListItem>
        <UListItem>
          いついかなる時も誠実に、お客様の夢の実現の為に全力を尽くし地域に必要とされる会社に成る
        </UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PMoodboard: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="BRAND" title="ムードボード" />
    <UTextLine
      content={
        <>
          ムードボードとは、ブランドの雰囲気をビジュアルイメージで共有し、デザイン認識のズレを防ぐためのものです。
          <br />
          フジホームのデザインでは、ブランドカラーやフィロソフィーをもとに、なるべくこれらの雰囲気に近いイメージを採用するようにしてください。
        </>
      }
    />
    <DeprecatedUImageBlock src={moodborad1} alt="ムードボード1" />
    <DeprecatedUImageBlock src={moodborad2} alt="ムードボード2" />
    <DeprecatedUImageBlock src={moodborad3} alt="ムードボード3" />
    <DeprecatedUImageBlock src={moodborad4} alt="ムードボード4" />
    <DeprecatedUImageBlock src={moodborad5} alt="ムードボード5" />
    <DeprecatedUImageBlock src={moodborad6} alt="ムードボード6" />
  </GuidelineContent>
)

const PWebLogo: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="WEB" title="ロゴ" />
    <DeprecatedUImageBlock src={logo1} alt="イエステーション ロゴ" />
    <DeprecatedUImageBlock src={logo2} alt="イエステーション ロゴ 目盛付き" />
  </GuidelineContent>
)

const PColor: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="BRAND" title="カラー" />
    <UHeading level={2} content="フジホームブランドカラー" />
    <UTextLine
      content={
        <>
          『葛飾区から金町へ、夜から朝へ、よりよい未来へ』
          <br />
          3色まとめて緩やかなグラデーションに利用してください。
        </>
      }
    />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem title="Rose Madder" hex="#95234e" rgb={"R149 G35 B78"} />
      <UColorGridItem title="Beni Kaba" hex="#bf5a4e" rgb={"R191 G89 B78"} />
      <UColorGridItem title="Koji" hex="#e28a4e" rgb={"R226 G137 B78"} />
    </ULayoutGrid>
    <UHeading level={2} content="イエステーションブランドカラー" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem hex="#44928e" rgb={"R68 G146 B142"} />
      <UColorGridItem hex="#02a1e8" rgb={"R3 G161 B232"} />
      <UColorGridItem hex="#ee820d" rgb={"R238 G130 B13"} />
    </ULayoutGrid>
  </GuidelineContent>
)

const PDownload: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="BRAND" title="ダウンロード" />
    <UHeading level={2} content="「イエステーション」メディアキット" />
    <UFile url="" size="" filename="ダウンロードする" />
    <UHeading level={2} content="「フジホーム金町店」ロゴ" />
    <UFile url="" size="" filename="ダウンロードする" />
  </GuidelineContent>
)

const PInformation: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="BRAND" title="基本情報" />
    <UTable>
      <UTableRow>
        <UTableCell>運営会社</UTableCell>
        <UTableCell>株式会社フジホーム</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>本店所在地</UTableCell>
        <UTableCell>
          東京都葛飾区金町五丁目24番12号イースタイル金町1F
        </UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>設立</UTableCell>
        <UTableCell>平成25年9月19日</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>事業内容</UTableCell>
        <UTableCell>
          不動産の売買・賃貸管理及びその仲介
          <br />
          総合リース業
          <br />
          建設工事業
          <br />
          土木工事業
          <br />
          内外装リフォーム工事業
          <br />
          設備工事業
          <br />
          解体工事業
        </UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>代表取締役</UTableCell>
        <UTableCell>飯島 翔</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>資本金</UTableCell>
        <UTableCell>500万円</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>提携先</UTableCell>
        <UTableCell>
          さいたま税理士法人
          <br />
          社労士事務所　HR人事支援研究所
        </UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>WEBサイト</UTableCell>
        <UTableCell>
          <UTextAnnotationA href="https://yesfuji.com">
            https://yesfuji.com
          </UTextAnnotationA>
        </UTableCell>
      </UTableRow>
    </UTable>
  </GuidelineContent>
)
const PWebDesign: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="WEB" title="デザイン原則" />
    <UHeading level={2} content="直感的かつインタラクティブに" />
    <br />
    些細なアクションには小さめの反応を、重要なアクションには大きめな反応がユーザーにとって直感的なモーションで返ってくるようにすること。
    <br />
    <UHeading level={2} content="情報の階層を整理する" />
    <br />
    情報は階層化され、整理整列すること。
    <br />
    <UHeading level={2} content="過去から未来へと" />
    <br />
    葛飾区金町という歴史的要素と、ITという先進的要素の2面性を表現上のコンセプトに据えるよう心掛けること。
  </GuidelineContent>
)

const PWebTypography: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="WEB" title="タイポグラフィ" />
    <UHeading level={2} content="H2 - 漢字タイポス４１５ Std" />
    <DeprecatedUImageBlock src={typoKanji415} alt="typoKanji415 font" />
    <UTextLine
      content={
        <>
          タイポスの漢字デザインは
          <br />
          1968年グループ・タイポの機関誌に発表された後、2008年に漢字タイポスとして初めてフォント化された書体です。
          <br />
          キュートでコケティッシュな表情を持ち、そのシンプルで明るい字形が魅力です。
          <br />
          印刷媒体だけでなくWebなどの画面表示でも読みやすい書体です。
          <br />
          Adobe Fonts
          <br />
          <UTextAnnotationA href="https://fonts.adobe.com/fonts/kan415typos-std">
            https://fonts.adobe.com/fonts/kan415typos-std
          </UTextAnnotationA>
          <UHeading level={2} content="H2 - Notosans Std" />
          <DeprecatedUImageBlock src={notosans} alt="notosans font" />
        </>
      }
    />
  </GuidelineContent>
)

const PLogo: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="BRAND" title="ロゴ" />
    <UHeading level={2} content="Pattern A" />
    <DeprecatedUImageBlock src={logoa} alt="イエステーション ロゴ" />
    <UHeading level={2} content="Pattern B" />
    <DeprecatedUImageBlock src={logob} alt="イエステーション ロゴ" />
    <UHeading level={2} content="Pattern C" />
    <DeprecatedUImageBlock src={logoc} alt="イエステーション ロゴ" />
    <UHeading level={2} content="Pattern D" />
    <DeprecatedUImageBlock src={logod} alt="イエステーション ロゴ" />
    <UTextLine
      content={
        <UTextAnnotationLink to="download">
          各種素材のダウンロードへ
        </UTextAnnotationLink>
      }
    />
  </GuidelineContent>
)

const PWebColor: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="WEB" title="カラー" />
    <UHeading level={2} content="フジホームブランドカラー" />
    <UTextLine
      content={
        <>
          『葛飾区から金町へ、夜から朝へ、よりよい未来へ』
          <br />
          3色まとめて緩やかなグラデーションに利用してください。
        </>
      }
    />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem title="Rose Madder" hex="#95234e" rgb={"R149 G35 B78"} />
      <UColorGridItem title="Beni Kaba" hex="#bf5a4e" rgb={"R191 G89 B78"} />
      <UColorGridItem title="Koji" hex="#e28a4e" rgb={"R226 G137 B78"} />
    </ULayoutGrid>
    <UHeading level={2} content="イエステーションブランドカラー" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem hex="#44928e" rgb={"R68 G146 B142"} />
      <UColorGridItem hex="#02a1e8" rgb={"R3 G161 B232"} />
      <UColorGridItem hex="#ee820d" rgb={"R238 G130 B13"} />
    </ULayoutGrid>
    <UHeading level={2} content="ベースカラー" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem title="100%" hex="#4a4a4a" />
      <UColorGridItem title="80%" hex="#6e6e6e" />
      <UColorGridItem title="60%" hex="#929292" />
      <UColorGridItem title="40%" hex="#b6b6b6" />
      <UColorGridItem title="20%" hex="#dadada" />
      <UColorGridItem title="10%" hex="#ededed" />
    </ULayoutGrid>
  </GuidelineContent>
)

const PWebWriting: React.FC = () => (
  <GuidelineContent>
    <PageTitleSection category="WEB" title="ライティング" />
    <UHeading level={1} content="ライティング原則" />
    <UHeading level={2} content="1. 意味を変えず文字を減らす" />
    <UTextLine
      content={
        <>
          言いたいことはきちんと伝えながら
          <br />
          不要な言い回しや「てにをは」を削ったり、
          <br />
          重複する言葉は代名詞に置き換えたりして
          <br />
          文章をできるだけ短くする。
        </>
      }
    />
    <UHeading level={2} content="2. 文字量のバランスを取る" />
    <UTextLine
      content={
        <>
          並列関係の複数のブロックがあれば
          <br />
          文字数はできるだけ揃える。
          <br />
          特にブロックを横に並べる場合には
          <br />
          文字量の調整は必ず行う。
        </>
      }
    />
    <UHeading level={2} content="3. 段落をデザインする" />
    <UTextLine
      content={
        <>
          頻繁に段落変えするとガタガタした印象になったり、
          <br />
          一つの段落の文章量が多すぎると読みにくくなる。
          <br />
          文章内容から段落を変えたり、繋げたりして
          <br />
          段落の量とカタチもデザインしていく。
        </>
      }
    />
    <UHeading level={2} content="4. 抽象的な表現は具体的にする" />
    <UTextLine
      content={
        <>
          抽象的な言葉や表現を多用すると、
          <br />
          読み手はイメージができず、説得力も生まれない。
          <br />
          伝えたい要点はできるだけ具体的に記述し、
          <br />
          説得力のある文章にする。
        </>
      }
    />
    <UHeading level={2} content="5. 平易な表現にする" />
    <UTextLine
      content={
        <>
          専門性を出そうと難しい言葉を多用すると、
          <br />
          結局は多くの人に通じない言葉になる。
          <br />
          リテラシーの高いユーザーがターゲットであっても
          <br />
          平易な言葉で表現したほうがいい。
        </>
      }
    />
    <UHeading level={2} content="6. 見出しで内容を伝える" />
    <UTextLine
      content={
        <>
          Web上では、ユーザーは見出しを見てから
          <br />
          本文を読むかどうかを決定する傾向がある。
          <br />
          見出しに魅力的な情報が含まれていないと、
          <br />
          それ以上読んでもらえる確率が減ってしまう。
        </>
      }
    />
    <UHeading level={2} content="7. 長い文章は分割する" />
    <UTextLine
      content={
        <>
          長く繋がった文章だと、
          <br />
          主語と述語の関係も分かりにくくなり、
          <br />
          読んでいて理解するのが難しくなる。
          <br />
          ワンセンテンス=ワンテーマを基本とし、 読みやすい長さに分割する。
        </>
      }
    />
    <UHeading level={2} content="8. キーワードを自然に含める" />
    <UTextLine
      content={
        <>
          各ページに割り当てられたキーワードを
          <br />
          見出しや本文に登場させることで、
          <br />
          SEO効果がより期待できる文章になる。
          <br />
          ただし、自然な使い方をしなければならない。
        </>
      }
    />
    <UHeading level={2} content="9. 語調を統一する" />
    <UTextLine
      content={
        <>
          漢字の開きや送り仮名、カタカナの綴り
          <br />
          ですます調にする箇所とそうでない箇所など
          <br />
          文中で登場する語句の表記は必ず統一する。
        </>
      }
    />
    <UHeading level={2} content="10. 表記を統一する" />
    <UTextLine
      content={
        <>
          漢字の開きや送り仮名、カタカナの綴り
          <br />
          文中で登場する専門用語など、
          <br />
          語句の表記は必ず統一する。
        </>
      }
    />
    <UHeading level={2} content="11. 改行を調整する" />
    <UTextLine
      content={
        <>
          デフォルトの文字サイズで見たとき
          <br />
          一文字だけ改行されるなどがあれば
          <br />
          文字を短くするなどして
          <br />
          なるべく改行しないように調整する。
        </>
      }
    />
    <UHeading level={1} content="ボイス＆トーン" />
    <UTable>
      <UTableRow>
        <UTableCell>文体</UTableCell>
        <UTableCell>用法1</UTableCell>
        <UTableCell>用法2</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>推定</UTableCell>
        <UTableCell>でしょう。</UTableCell>
        <UTableCell>ます。</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>断定</UTableCell>
        <UTableCell>です。</UTableCell>
        <UTableCell></UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>疑問</UTableCell>
        <UTableCell>でしょうか？</UTableCell>
        <UTableCell>ませんか？</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>過去</UTableCell>
        <UTableCell>でした。</UTableCell>
        <UTableCell>ました。</UTableCell>
      </UTableRow>
    </UTable>
  </GuidelineContent>
)

const PageTitleSection = styled(PageTitle)(
  css({
    marginTop: 60,
    marginBottom: 50,
  })
)
