import { IFirebaseApp } from "./firebaseShim"
import { UnreachableCaseError, SystemError } from "../errors"

let _env: "client" | "admin" | null

const admin = (): typeof import("firebase-admin") => {
  return require("firebase-admin")
}
const firebase = (): typeof import("firebase/app") => {
  const firebase = require("firebase/app")
  require("firebase/auth")
  require("firebase/firestore")
  require("firebase/functions")
  require("firebase/storage")
  return firebase
}

export const FirebaseShim: typeof IFirebaseApp = {} as any

export const setupFirebase = (env: "client" | "admin") => {
  _env = env
  applyEnv()
}

const applyEnv = () => {
  switch (_env) {
    case "client":
      FirebaseShim.app = firebase().app as any
      FirebaseShim.firestore = firebase().firestore as any
      FirebaseShim.auth = firebase().auth as any
      return
    case "admin":
      FirebaseShim.app = admin().app as any
      FirebaseShim.firestore = admin().firestore as any
      FirebaseShim.auth = admin().auth as any
      return
    case null:
      throw new SystemError("please run `setupFirebase` before `applyEnv`")
    default:
      throw new UnreachableCaseError(_env)
  }
}
