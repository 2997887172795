import styled from "@emotion/styled"
import { css, getThemed } from "foundation/styles/theme"
import React from "react"

export type UHeadingProps = {
  level: 1 | 2
  content: string
  attributes?: any
  children?: never
}

/** 見出し文字 */
export const UHeading: React.FC<UHeadingProps> = React.forwardRef(
  ({ level, content, attributes }, ref) => {
    return (
      <UHeadingElem
        ref={ref}
        {...attributes}
        as={HeaderLevel[level]}
        level={level}
      >
        {content}
      </UHeadingElem>
    )
  }
)

const HeaderLevel = {
  1: "h1",
  2: "h2",
} as const

const UHeadingElem = styled.h1<{ level: 1 | 2 } & { as?: string }>`
  font-family: ${(p) => getThemed(p, "fonts.user_sans")};
  font-weight: bold;
  ${(props) =>
    props.level === 1 &&
    css`
      margin-top: 64px;
      line-height: 40px;
      font-size: 27px;
    `}
  ${(props) =>
    props.level === 2 &&
    css`
      margin-top: 45px;
      line-height: 27px;
      font-size: 18px;
    `}
  .subText {
    font-size: 10pt;
  }
`
