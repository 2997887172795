import styled from "@emotion/styled"
import React from "react"
import { getThemed } from "foundation/styles/theme"

export type UTextLineProps = {
  content?: React.ReactNode
  children?: never
  attributes?: any
}

/** テキスト1行
 *
 * 複数行のテキストを構成する場合はこのコンポーネントを複数使用します。
 * 画面幅での折返しで問題ない場合はそのままコンテンツを入れます。
 * NOTE: 複数行テキストに使われている!!
 */
export const UTextLine: React.FC<UTextLineProps> = React.forwardRef(
  ({ content, attributes }, ref) => (
    <UTextLineElem ref={ref} {...attributes}>
      {content}
    </UTextLineElem>
  )
)

const UTextLineElem = styled.div`
  margin-top: 18px;
  line-height: 30px;
  font-family: ${(p) => getThemed(p, "fonts.user_sans")};
  font-size: 15px;
`
