import { NonNulalbleMap } from "../../libs"

export const UserCollectionID = "users"

/** パーミッションがコピーされるユーザーが参照するためのパーミッション情報 */
export const UserMembershipCollectionID = "memberships"

export type UserMembership = UserMembershipTeam | UserMembershipProject

type UserMembershipTeam = {
  scope: "team"
  path: [string]
  role: "owner" | "member" | "reader"
}

type UserMembershipProject = {
  scope: "project" | "team"
  path: [string, string]
  role: "owner" | "member" | "reader"
}

export type AdminUserCreateRequest = {
  /** see admin.auth.CreateRequest */
  uid?: string
  disabled?: boolean
  displayName?: string | null
  email?: string
  emailVerified?: boolean
  password?: string
  phoneNumber?: string | null
  photoURL?: string | null
}

export type AdminUserUpdateRequest = {
  /** see admin.auth.UpdateRequest */
  disabled?: boolean
  displayName?: string | null
  email?: string
  emailVerified?: boolean
  password?: string
  phoneNumber?: string | null
  photoURL?: string | null
}

/** Firebase Authenticate上のユーザー
 * だいたいどのSDKからも同じ形
 * @see firebase-admin.auth.UserRecord
 */
export type FirebaseUserRecord = {
  uid: string
  email?: string
  emailVerified: boolean
  displayName?: string
  phoneNumber?: string
  photoURL?: string
  disabled: boolean
  // metadata: admin.auth.UserMetadata;
}

type FirebaseUserInfo = {
  displayName: string | null
  email: string | null
  phoneNumber: string | null
  photoURL: string | null
  providerId: string
  uid: string
}

/**
 * @see firebase-admin.auth.UserRecord
 */
export type AdminUserRecord = {
  uid: string
  email?: string
  emailVerified: boolean
  displayName?: string
  phoneNumber?: string
  photoURL?: string
  disabled: boolean
  metadata: {
    lastSignInTime: string
    creationTime: string
  }
  providerData: NonNulalbleMap<FirebaseUserInfo>
  passwordHash?: string
  passwordSalt?: string
  customClaims?: ICustomClaim | null
  tokensValidAfterTime?: string
}

export type ICustomClaim = {} & ICustomClaimRoles

export type ICustomClaimRoles = {
  roleAdmin: boolean
}
