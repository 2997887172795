import { dataWithID, WithID } from "@common/domain/common"
import {
  DefaultPageTree,
  deserializePage,
  deserializeProject,
  EmptyPageContent,
  NewProjectPayload,
  PageCollectionPath,
  Project,
  ProjectCollectionPath,
  ProjectConverter,
  ProjectDraft,
  ProjectFB,
  ProjectPath,
  serializePage,
  serializeProject,
} from "@common/domain/project"
import {
  deserializeTeam,
  Team,
  TeamCollectionPath,
  TeamConverter,
  TeamDraft,
  TeamFB,
  TeamPath,
} from "@common/domain/team"
import {
  UserCollectionID,
  UserMembership,
  UserMembershipPath,
} from "@common/domain/user"
import { ApplicationError, Sleep } from "@common/libs"
import { FirebaseShim } from "@common/libs/firebase"
import {
  AvailableTemplates,
  GetProjectTemplate,
} from "context/project-editor/context/template"
import { keyBy } from "lodash"

// NOTE: とりあえずここに置いてあるけどcontext/teamに移動する
export const createTeam = async (payload: {
  team: Pick<TeamDraft, "displayName" | "description">
  uid: string
  noWaitPublished?: boolean
}) => {
  const db = FirebaseShim.firestore()
  const userRef = db.collection(UserCollectionID).doc(payload.uid)

  const team: TeamDraft = { ...payload.team, author: userRef.id }
  const teamRef = TeamCollectionPath(db).doc()

  const batch = db.batch()
  batch.set(teamRef, TeamConverter(db).toFirestore(team as any))
  batch.set(teamRef.collection("members").doc(userRef.id), {
    enabled: true,
    role: "owner",
  })
  await batch.commit()

  // hookTeamIAM によってチームがユーザー領域にコピーされるのを待つ
  const teamMirrorRef = UserMembershipPath(db, payload.uid).doc(teamRef.id)

  const result = ({ ...team, id: teamRef.id } as any) as Team

  if (payload.noWaitPublished) {
    return result
  }

  let cancelFunc: () => void | undefined
  const ret = await Promise.race<boolean>([
    new Promise<boolean>((r) => {
      cancelFunc = teamMirrorRef.onSnapshot((snap) => {
        if (snap.exists) {
          r(true)
          cancelFunc()
        }
      })
    }),
    Sleep(30_0000).then(() => {
      cancelFunc?.()
      return false
    }),
  ])

  if (!ret) {
    throw new ApplicationError("failed to create project")
  }

  return result
}

export const createProject = async (payload: {
  teamID: string
  template?: {
    name: AvailableTemplates
    option?: any
  }
  project: Pick<ProjectDraft, "displayName" | "description">
}) => {
  const db = FirebaseShim.firestore()

  const tmpl = await (payload.template
    ? GetProjectTemplate(payload.template?.name, payload.template?.option)
    : GetProjectTemplate("default"))

  const newProject: NewProjectPayload = {
    ...payload.project,
    pageTree: tmpl.pageTree,
    publishedAt: null,
    publishCompletedAt: null,
  }
  const projectRef = ProjectCollectionPath(db, payload.teamID).doc()

  try {
    await projectRef.withConverter(ProjectConverter).set(newProject as any)

    const batch = db.batch()
    tmpl.pages.forEach((page) => {
      batch.set(
        projectRef.collection("pages").doc(page.id),
        serializePage(page)
      )
    })
    await batch.commit()
  } catch (e) {
    // TODO: User friendly error
    console.error(e)
    throw e
  }

  return ({ ...newProject, id: projectRef.id } as any) as Project
}

export const listProjectAcrossTeams = async ({
  uid,
}: {
  uid: string
}): Promise<TeamAndProjectList> => {
  const db = FirebaseShim.firestore()

  // 1. 自分のパスの中にチーム一覧があるので取得する
  // 2. 各チームのCollectionからProjectをlistする
  // NOTE: これだと一部だけプロジェクトに入っている場合は非対応
  const membershipPath = UserMembershipPath(db, uid)
  const memberships = (await membershipPath.get()).docs
    .map((doc) => dataWithID<UserMembership>(doc))
    .filter((d) => d.scope === "team")

  const [teamsData, teamsProjectsData] = await Promise.all([
    Promise.all(
      memberships.map((teamRef) =>
        TeamPath(db, teamRef.path[0])
          .get()
          .then((snap) => dataWithID<TeamFB>(snap))
      )
    ),
    Promise.all(
      memberships.map((teamRef) =>
        ProjectCollectionPath(db, teamRef.path[0])
          .get()
          .then((snap) => snap.docs.map((doc) => dataWithID<ProjectFB>(doc)))
      )
    ),
  ])
  const ret = teamsData.map((team, i) => ({
    team,
    projects: teamsProjectsData[i],
    membership: memberships[i],
  })) as {
    team: WithID<TeamFB>
    projects: WithID<ProjectFB>[]
    membership: UserMembership
  }[]

  return ret.reduce<TeamAndProjectList>((rh, item) => {
    rh[item.team.id] = {
      team: deserializeTeam(item.team),
      projects: item.projects.reduce((ph, proj) => {
        ph[proj.id] = {
          project: deserializeProject(proj),
          membership: item.membership,
        }
        return ph
      }, {}),
    }
    return rh
  }, {})
}

export type TeamAndProjectList = {
  [key: string]: TeamAndProject
}

export type TeamAndProject = {
  team: Team
  projects: {
    [key: string]: { project: Project; membership: UserMembership }
  }
}

export const updateProject = async (payload: {
  teamID: string
  project: Project
}) => {
  const store = FirebaseShim.firestore()
  const projectRef = ProjectPath(store, payload.teamID, payload.project.id)
  await projectRef.set(serializeProject(payload.project), {
    merge: true,
  })
}

export const updateProjectField = async (payload: {
  teamID: string
  id: string
  fields: { [key: string]: any }
}) => {
  const store = FirebaseShim.firestore()
  const projectRef = ProjectPath(store, payload.teamID, payload.id)

  await projectRef.update(payload.fields)
}

/** プロジェクト取得 */
export const fetchProject = async (payload: { id: string; teamID: string }) => {
  // TODO: batch get
  const store = FirebaseShim.firestore()
  const [teamDoc, projectDoc, pageDocs] = await Promise.all([
    TeamPath(store, payload.teamID).get(),
    ProjectPath(store, payload.teamID, payload.id).get(),
    PageCollectionPath(store, payload.teamID, payload.id).get(),
  ])

  if (!projectDoc.exists || !teamDoc.exists) {
    throw new Error("project not exist")
  }

  const project = deserializeProject(dataWithID<ProjectFB>(projectDoc))
  const team = deserializeTeam(dataWithID<TeamFB>(teamDoc))
  const pages = keyBy(
    pageDocs.docs
      .map<any>(dataWithID)
      .map(deserializePage)
      .map((page) => {
        if (!page.content) {
          page.content = EmptyPageContent()
        }
        return page
      }),
    "id"
  )

  // 開発中のデータでTreeが無いものがあるので入れる
  if (!project.pageTree) project.pageTree = DefaultPageTree

  return { project, team, pages }
}

export const publishProject = async (payload: {
  id: string
  teamID: string
  waitPublished?: boolean
}) => {
  const store = FirebaseShim.firestore()
  const projectRef = ProjectPath(store, payload.teamID, payload.id)

  let publishWait: Promise<void> | null = null
  if (payload.waitPublished) {
    // 更新されて公開日が更新されていたら完了
    publishWait = new Promise((r) => {
      const unsub = projectRef.onSnapshot(
        (snap) => {
          const updated = snap.data() as ProjectFB | undefined
          const published =
            (updated &&
              updated.publishCompletedAt &&
              updated.publishedAt &&
              updated.publishCompletedAt.toMillis() >=
                updated.publishedAt.toMillis()) ||
            false
          if (published) {
            unsub()
            r()
          }
        },
        () => unsub()
      )
    })
  }

  const v: Partial<ProjectFB> = {
    publishedAt: FirebaseShim.firestore.FieldValue.serverTimestamp() as any,
  }
  await projectRef.set(v, { merge: true })

  if (payload.waitPublished) {
    await Promise.resolve(publishWait)
  }
  return Date.now()
}
