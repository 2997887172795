import React from "react"
import { Gateway, GatewayDest, GatewayProvider } from "react-gateway"
import PropTypes from "prop-types"

/** コンポーネントをツリー外の場所にマウントするためのコンポーネント
 */
export const Layer: React.FC<{
  /** マウントするLayerHostのIDを指定する。省略時はdocumentの一番下に表示される`spirally-portal`が使用される */
  gatewayID?: string
}> = ({ gatewayID = "spirally-portal", ...props }) => {
  return <Gateway into={gatewayID}>{props.children}</Gateway>
}

interface LayerHostProps<CP> {
  gatewayID: string
  tagName?: string
  className?: string
  component?: string | React.ComponentType<CP>
  componentProps?: CP
}

export function LayerHost<T = {}>({
  gatewayID,
  componentProps,
  ...props
}: LayerHostProps<T>) {
  return <AppGatewayDest name={gatewayID} {...componentProps} {...props} />
}

export const LayerProvider = GatewayProvider

class AppGatewayDest extends GatewayDest {
  static propTypes = {
    ...(GatewayDest as any).propTypes,
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.elementType,
    ]),
  }
}
