/**
 *
 *  アプリケーション全体で使用するエラー型
 */

/** すべてのアプリケーション内エラーのベース */
export abstract class BaseError extends Error {
  name = "BaseError"
  constructor(message: string) {
    super(message)
    this.message = message
    Object.setPrototypeOf(this, new.target.prototype)
  }

  toString() {
    return `${this.name}: ${this.message}`
  }
}

/** システムエラー
 *
 * 用途
 * - 復帰不可能なシステム上のエラー
 */
export class SystemError extends BaseError {
  name = "SystemError"
}

/**
 * 到達不能コード用エラー
 *
 * 引数がnever型のため、enumとswitchを組み合わせた時にありえないコードに通った時にthrowします。
 *
 * @example
 *
 * enum Kind {
 *  A = 'A'
 *  B = 'B'
 * }
 *
 * (input: Kind) => {
 *   switch (input) {
 *   case Kind.A:
 *     return "Aです"
 *   case Kind.B:
 *     return "Bです"
 *   default:
 *     throw new UnreachableCaseError(input) // input is never type
 * }
 */
export class UnreachableCaseError extends SystemError {
  name = "UnreachableCaseError"
  constructor(val: never) {
    super(`Unreachable: ${JSON.stringify(val)}`)
  }
}

/** 設定エラー */
export class ConfigurationError extends SystemError {
  name = "ConfigurationError"
  documentRef?: string
  constructor(msg: string, docRef?: string) {
    super(`ConfigurationError: ${msg}`)
    this.documentRef = docRef
  }
}

/** 未実装エラー */
export class NotImplementedError extends SystemError {
  name = "NotImplementedError"
  constructor(msg?: string) {
    super(`not implemented: ${msg}`)
  }
}

/** ビジネスロジックでのエラーのベース */
export class ApplicationError extends BaseError {
  name = "ApplicationError"
  userMessage: { id?: string; defaultMessage: string }
  debugInfo?: string

  constructor(defaultMessage: string, id?: string, debugInfo?: string) {
    super(debugInfo ? `${defaultMessage}: ${debugInfo}` : defaultMessage)
    this.userMessage = { defaultMessage, id }
    this.debugInfo = debugInfo
  }
}

/** 認証エラー */
export class UnauthorizedError extends ApplicationError {
  name = "UnauthorizedError"
  constructor() {
    super("Please login")
  }
}

/** バリデーションエラー(ユーザー起因) */
export class ValidationError extends ApplicationError {
  name = "ValidationError"
  constructor(defaultMessage: string, id?: string, debugInfo?: string) {
    super(defaultMessage, id, debugInfo)
  }
}

export const errorI18n = (error: Error) => {
  if (error instanceof ApplicationError) {
    return error.userMessage
  }
}
