import {
  FileMetaStore,
  FileMetaStorePath,
  FileMetaTrigger,
  StorageRef,
} from "./Storage.type"

export const GetStorageRefVariant = (
  ref: StorageRef | null | undefined,
  variants: string[] | string,
  fallback = true
): { ref: StorageRef; key: string } | undefined => {
  if (!ref) return
  if (ref.variants) {
    if (typeof variants === "string") {
      const v = ref.variants[variants]
      if (v) {
        return { key: variants, ref: v }
      }
    } else {
      for (const variantKey of variants) {
        const v = ref.variants[variantKey]
        if (v) {
          return { key: variantKey, ref: v }
        }
      }
    }
  }
  return (ref && fallback && { key: "", ref: ref }) || undefined
}

export const GetTriggerRequest = (
  meta: { [key: string]: any } | undefined
): string | undefined => {
  return meta?.[FileMetaTrigger]
}

export const GetFirestorePathFieldFromMetadata = (
  meta: { [key: string]: any } | undefined
): FileMetaStore[] | undefined => {
  try {
    return JSON.parse(meta?.[FileMetaStorePath])
  } catch (e) {
    return
  }
}
