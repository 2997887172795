import { FirebaseShim } from "../../libs/firebase"
import { Page, PageFB, PagePath } from "../project"
import { assert } from "@common/libs"

/** ページを更新する
 *
 * page.id, page.contentは必須
 */
export const updatePageContent = async (payload: {
  projectID: string
  teamID: string
  page: Partial<Page> & Pick<Page, "id" | "content">
}) => {
  assert(payload.page.id, "page.idは必須")
  assert(payload.page.content !== undefined, "page.contentは必須")
  const store = FirebaseShim.firestore()
  const pageRef = PagePath(
    store,
    payload.teamID,
    payload.projectID,
    payload.page.id
  )

  // 必要なプロパティのみを設定
  const v: Partial<PageFB> = {}
  v.content = JSON.stringify(payload.page.content)
  if (payload.page.displayName) {
    v.displayName = payload.page.displayName
  }
  if (payload.page.coverImage) {
    v.coverImage = payload.page.coverImage
  }
  if (payload.page.assets) {
    v.assets = payload.page.assets
  }

  await pageRef.set(v, {
    merge: true,
  })
}

export const getPage = async (payload: {
  teamID: string
  projectID: string
  pageID: string
}) => {
  const store = FirebaseShim.firestore()
  const pageRef = PagePath(
    store,
    payload.teamID,
    payload.projectID,
    payload.pageID
  )
  return pageRef.get()
}

export const deletePage = async (payload: {
  teamID: string
  projectID: string
  pageID: string
}) => {
  const store = FirebaseShim.firestore()
  const pageRef = PagePath(
    store,
    payload.teamID,
    payload.projectID,
    payload.pageID
  )
  await pageRef.delete()
}
