/** 公開ページのルーティング用ルール
 * ReactRouter用
 */
export const PublishedPageRoutePath = "/_/:slug"

/** `PublishedPageRoutePath`にマッチした時のパラメーター
 */
export type IPublishedPageRouteParams = { slug: string }

/** slugに応じた公開ページのパスを返す */
export const getPublishedPagePath = (slug: string) => `/_/${slug}`
