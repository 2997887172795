import { assert } from "@common/libs"

/** 使用可能なテンプレートの一覧 */
export const AvailableTemplateDefs = {
  default: {
    name: "デフォルト",
    description: "デフォルトのテンプレート",
    importer: async () => (await import("./templates/defaultTemplate")).default,
  },
} as const

export type AvailableTemplates = keyof typeof AvailableTemplateDefs

/** 指定したテンプレートを取得する */
export const GetProjectTemplate = async (
  name: AvailableTemplates = "default",
  options?: any
) => {
  assert(AvailableTemplateDefs[name])
  return (await AvailableTemplateDefs[name].importer()).template(options)
}

/** 指定したテンプレートのダミー素材を取得する */
export const GetProjectTemplateResource = async (
  name: AvailableTemplates = "default",
  key: string
) => {
  assert(AvailableTemplateDefs[name], `${name} is unknown theme`)
  return (await AvailableTemplateDefs[name].importer()).resource(key)
}
