import React from "react"
import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"

const UTableElem = styled.table`
  margin-top: 33px;
  width: 100%;
  min-height: 30px;
  border-collapse: collapse;
`

export const UTableBody = styled.tbody``

export const UTable: React.FC<{
  attributes?: any
}> = (props) => {
  return (
    <UTableElem {...props.attributes}>
      <UTableBody>{props.children}</UTableBody>
    </UTableElem>
  )
}

export const UTableRow = styled.tr``
export const UTableCell = styled.td`
  padding: 12px 17px;
  min-width: 20px;
  min-height: 16px;
  border: 1px solid ${colors.borderGray};
`

export const UTableText = styled.div`
  line-height: 24px;
  font-size: 15px;
`
