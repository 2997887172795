import {
  deletePage as deletePageFB,
  updatePageContent as updatePageContentFB,
} from "@common/domain/editor/editorActions"
import { Page } from "@common/domain/project"
import { AppThunk } from "context/entrypoint/store"
import { deletePage, mergePages, setPage } from "../pageEditorSlice"

/** ページを更新します
 *
 * 指定しない項目については既存のものとマージされます
 *
 * - 更新できるもの
 *  - コンテンツ
 *  - ページタイトル
 */
export const updatePageAction = (payload: {
  page: Partial<Page> & Pick<Page, "id">
}): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const s = getState().editor
  const currentProject = s.project
  if (!currentProject.project) return

  // 新規ページの時はcurrent === undefined
  const current = s.projectPage.pages[payload.page.id] as Page | undefined

  // Pageを浅くmergeする
  const page = mergePages({ current, page: payload.page })

  try {
    await updatePageContentFB({
      page,
      teamID: currentProject.project.teamID,
      projectID: currentProject.project.id,
    })
    dispatch(setPage({ page }))
  } catch (e) {
    console.error(e)
  }
}

export const deletePageAction = (payload: {
  key: string
}): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const s = getState().editor
  const currentProject = s.project
  if (!currentProject.project) return

  try {
    await deletePageFB({
      teamID: currentProject.project.teamID,
      projectID: currentProject.project.id,
      pageID: payload.key,
    })
    dispatch(deletePage({ id: payload.key }))
  } catch (e) {
    console.error(e)
  }
}
