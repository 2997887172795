import { unauthenticate } from "foundation/utils/authenticate"
import React, { useCallback } from "react"

/** ログアウト用フォーム **/
export const LogoutForm: React.FC<ILogoutFormProps> = ({
  presenter: Presenter = LogoutFormPresenter,
  ...props
}) => {
  const logoutHandler = useCallback(() => {
    unauthenticate()
  }, [])
  return <Presenter logoutHandler={logoutHandler} />
}

export type ILogoutFormProps = {
  presenter?: React.ComponentType<ILogoutFormPresenterProps>
}

export type ILogoutFormPresenterProps = {
  logoutHandler: any
}
const LogoutFormPresenter: React.FC<ILogoutFormPresenterProps> = ({
  ...props
}) => (
  <div css={{ minWidth: 250, margin: "0 auto" }}>
    <p>Are you sure you want to logout?</p>
    <button onClick={props.logoutHandler}>Logout</button>
  </div>
)
