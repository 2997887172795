/**
 * GoogleAnalytics関連ライブラリ
 */

/** GAのトラッキングID */
export const GA_TAG_ID =
  process.env.NODE_ENV === "production"
    ? process.env.GA_ID || process.env.REACT_APP_GA_ID
    : undefined

/** アクセスログを書き込みます
 *
 * トラッキングIDがない場合とGAのトラッキング用JSが埋め込まれていない場合は何もしません
 */
export const writeAccessLater = (url?: string) => {
  if (!(GA_TAG_ID && GA_TAG_ID.length > 0)) {
    return
  }

  if (!window?.__gtag) {
    return
  }

  window.__gtag("config", GA_TAG_ID, {
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: window.document.title,
  })
}

/** ユーザーIDをGAに紐づけます
 *
 * トラッキングIDがない場合とGAのトラッキング用JSが埋め込まれていない場合は何もしません
 */
export const saveAccesslogUserID = (uid: string) => {
  if (!(GA_TAG_ID && GA_TAG_ID.length > 0)) {
    return
  }
  if (window?.__gtag && GA_TAG_ID) {
    window.__gtag("set", { user_id: uid })
  }
}
