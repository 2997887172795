import { StorageRef } from "../../common"

/** dataオブジェクト内でImageOptionが格納されている場所のキー */
export const EditorImageOptionKey = "imageOption"

/** URLをそのまま表示するタイプ
 * 廃止するかも?
 */
export type ImageOptionRemote = {
  type: "REMOTE"
  src: string
  padding?: string
}

/** アップロードしたファイル */
export type ImageOptionStorage = {
  type: "STORAGE"
  file: StorageRef
  padding?: string
}

/**テンプレート用のダミー画像のための型 */
export type ImageOptionDummy = {
  type: "DUMMY"

  /** テンプレート名 */
  template: string
  /** テンプレートのリソースのキー */
  key: string

  padding?: string
}

/** 画像インライン要素(実際はブロックのように表示)のオプション */
export type ImageOption =
  | ImageOptionRemote
  | ImageOptionStorage
  | ImageOptionDummy

export enum EditorBlock {
  P = "paragraph",
  H1 = "heading-one",
  H2 = "heading-two",
  File = "file",
  Quote = "quote",
  HR = "horizontal-rule",
  UL = "unordered-list",
  OL = "ordered-list",
  Image = "image",
  LI = "list-item",
  ListWrap = "list-wapper",
  Table = "table",
  TableRow = "table_row",
  TableCell = "table_cell",
  TableText = "table_text",
  BlockLink = "block-link",
  ColorPicker = "color-picker",
  LayoutGrid = "layout-grid",
}
