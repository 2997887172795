import { mapValues } from "lodash"
import { WithID } from "../common"
import { Team } from "../team"
import { deserializePage } from "./Page"
import { deserializeProject } from "./Project.fb"
import { Project } from "./Project.type"
import { PublicProject, PublicProjectFB } from "./PublicProject.type"
import { StorageRefLike, PathShim } from "../../libs/firebase/commonTypes"

export const PublicProjectCollectionID = "public_projects"

export function slugFromProject(team: Team, project: Project)
export function slugFromProject(
  teamID: string,
  projectID: string,
  slug?: string
)
export function slugFromProject(
  team: Team | string,
  project: Project | string,
  slug?: string
) {
  const teamID = typeof team === "string" ? team : team.id
  const projectID = typeof project === "string" ? project : project.id
  slug = typeof project === "string" ? slug : project.publicSlug
  return slugFromProjectID(teamID, projectID, slug)
}

const slugFromProjectID = (
  teamID: string,
  projectID: string,
  slug?: string
) => {
  // NOTE: とりあえず元のプロジェクトIDとチームIDをそのまま使うが保存できるようにするタイミングで変える
  if (slug) return slug
  const publicProjectSlug = `${teamID}-${projectID}`
  return publicProjectSlug
}

export const deserializePublicProject = (
  project: WithID<PublicProjectFB>
): PublicProject => {
  const projectClone = { ...project }
  delete projectClone["projectRef"]
  return {
    ...projectClone,
    project: deserializeProject(project.project),
    pages: mapValues(project.pages, deserializePage),
  }
}

export function PublicProjectResourceBasePath<T extends StorageRefLike>(
  rootRef: T,
  {
    teamID,
    projectID,
    revisionID,
  }: {
    teamID: string
    projectID: string
    revisionID: string
  }
): T {
  return rootRef
    .child("public")
    .child(`${teamID}-${projectID}`)
    .child(revisionID)
}

export function PublicProjectResourceBasePathString({
  ...params
}: {
  teamID: string
  projectID: string
  revisionID: string
}) {
  return PublicProjectResourceBasePath(new PathShim(), params).toString()
}
