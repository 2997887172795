/**
 * 認証ライブラリ
 *
 * ./firebase/authenticateとのsimです。
 */
import { isClientSide } from "../../../../common/src/libs/env"
import {
  authFBWithPassword,
  runAsyncMigration,
  sendFBPasswordReset,
  signupFBWithPassword,
  subscribeAuthChange as subscribeAuthChangeFB,
  unauthenticateFB,
} from "./firebase/authenticate"
import { ICustomClaim } from "@common/domain/user"

export const guestUser: GuestUser = {
  type: "guest",
}

/** convert firebase.User to internal AuthUser */
const loadUser = (
  fbUser: firebase.User | null,
  idToken?: firebase.auth.IdTokenResult | null
): AuthUser => {
  if (!fbUser) return guestUser

  if (fbUser.isAnonymous) {
    return {
      type: "anonymous",
      uid: fbUser.uid,
    }
  }

  let role: AuthenticatedUser["role"] = "user"
  let claim: ICustomClaim | null = (idToken?.claims as any) ?? null
  if (claim?.roleAdmin) {
    role = "user:admin"
  } else if (fbUser.uid === "Gm3f4fTy9WXamVaE8g6Io3N56hj2") {
    // NOTE: デモ用アカウント対応で、強制的にreadOnlyにする
    role = "readonly"
  }

  return {
    type: "user",
    name: fbUser.displayName ?? "",
    uid: fbUser.uid,
    verified:
      (process.env.NODE_ENV === "development" &&
        process.env.REACT_APP_DEV_ALWAYS_VERIFIED === "1") ||
      fbUser.emailVerified,
    photoURL: fbUser.photoURL ?? undefined,
    email: fbUser.email ?? undefined,
    role,
  }
}

/** パスワードで認証 */
export const authWithPassword = async (email: string, password: string) => {
  if (!isClientSide()) return null
  await authFBWithPassword(email, password)
  return
}

/** パスワードリセット */
export const sendPasswordReset = (email: string, nextURL?: string) => {
  if (!isClientSide()) return null
  return sendFBPasswordReset(email, nextURL)
}

/** ユーザー作成 */
export const signupWithPassword = async (
  email: string,
  password: string,
  nextURL?: string
) => {
  if (!isClientSide()) return null
  return loadUser(await signupFBWithPassword(email, password, nextURL))
}

/** ログアウト */
export const unauthenticate = () => {
  if (!isClientSide()) return null
  return unauthenticateFB()
}

export type AuthChangedHandler = (user: AuthUser) => void

/** 認証状態が変化したイベントを監視する
 *
 * @returns 監視削除用のハンドラーのPromise, 監視を停止する際は呼ぶ必要があります。
 */
export const subscribeAuthChange = (handler: AuthChangedHandler) => {
  if (!isClientSide()) return () => {}
  return subscribeAuthChangeFB((fbUser, idToken) => {
    fbUser && !fbUser?.isAnonymous && runAsyncMigration(fbUser.uid)
    return handler(loadUser(fbUser, idToken))
  })
}

/** 匿名認証ユーザー
 *
 * 問い合わせページのために使用しています。
 */
export type AnonymousUser = {
  type: "anonymous"
  /** ユーザーUUID */
  uid: string
}

export type UserRole = "user" | "user:admin" | "readonly"

/** ログイン済みユーザー */
export type AuthenticatedUser = {
  type: "user"
  name: string
  /** ユーザーUUID */
  uid: string
  /** メール認証の状態 */
  verified: boolean
  /** プロフィール写真のURL */
  photoURL?: string
  email?: string
  role: UserRole
}

/** 未ログインユーザー */
export type GuestUser = {
  type: "guest"
}

/** ユーザーのデータ型 */
export type AuthUser = AnonymousUser | AuthenticatedUser | GuestUser
