/**
 * 翻訳
 */

/**
 * 有効な翻訳用のキーの型
 *
 * i18nコンポーネントでエディタの補完をさせるための型だったりします
 */
export type translationKeys = keyof typeof import("./translations/ja.json")

/** 引数に応じたメッセージ一覧を返します
 *
 * @todo 翻訳ファイルをJSONにして、バンドルを分けて非同期に読み込む
 */
// TODO: @commonに翻訳ファイルとともに移す
export const loadTranslations = async (locale: string): Promise<any> => {
  switch (locale) {
    case "en":
      return (await import("./translations/en.json")).default
    case "ja":
    default:
      return (await import("./translations/ja.json")).default
  }
}
