import React from "react"
import ReactDOM from "react-dom"
import { App, ErrorApp, UpdateManager } from "./context/entrypoint"
import { configureStore } from "./context/entrypoint/store"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/browser"
import { setUpdateAvailable } from "context/entrypoint/slices/rootSlice"
import { setupCommon, ConfigurationError } from "@common/libs"
import { initializeFirebase } from "foundation/utils/firebase/webapp"
import { applicationEnv } from "context/env"

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: "https://aeac9ef0f6e84f7e9b9143e08a8f653d@sentry.io/5177246",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: applicationEnv(),
  })
}

if (process.env.NODE_ENV !== "production") {
  if ("browser" in process) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React)
  }

  let error: any = null
  if (!process.env.REACT_APP_FIREBASE_TOKEN) {
    error = new ConfigurationError(
      ".envにREACT_APP_FIREBASE_TOKENを追加してください。",
      "setup.md#react_app_firebase_token"
    )
  }
  if (!process.env.REACT_APP_STORAGE_USER_BUCKET) {
    error = new ConfigurationError(
      ".envにREACT_APP_STORAGE_USER_BUCKETを追加してください。",
      "setup.md#react_app_storage_user_bucket"
    )
  }
  if (error) {
    ReactDOM.render(<ErrorApp error={error} />, document.getElementById("root"))
  } else {
    main()
  }
} else {
  main()
}

function main() {
  setupCommon({ firebase: "client" })
  initializeFirebase()

  const globalStore = configureStore()
  ReactDOM.render(<App store={globalStore} />, document.getElementById("root"))
  if (process.env.NODE_ENV === "development") {
    if (module.hot) {
      console.log("HMR enabled for React components")
      module.hot.accept()
      module.hot.accept("./context/entrypoint", () => {
        console.log("update component tree")
        ReactDOM.render(
          <App store={globalStore} />,
          document.getElementById("root")
        )
      })
    }
  }

  serviceWorker.register({
    onUpdate: (sw) => {
      /**  SWのアップデートがあったらまずユーザーに表示、リロードボタンを押したら実行
     onUpdate -> 
     -> store.updateAvailable
     -> button -> store.reloadWebsite 
     -> UpdateManager.allowUpdateSW
     -> UpdateManager.callback
     -> sw(postMessage) 
     -> sw(statechange) 
     -> window.location.reload
    */
      const next = sw.waiting
      if (next) {
        // アップデートメッセージのリロードボタンのハンドラーを設定
        UpdateManager.shared().setCallback(() => {
          // swのアップデートを再読込より先に実行する
          console.log("allow SW update")
          next.postMessage({ type: "SKIP_WAITING" })
        })

        // swのアップデートが完了したのでページを再読込する
        next.addEventListener("statechange", (event: any) => {
          console.log("SW updated")
          if (event.target.state === "activated") {
            window.location.reload()
          }
        })

        // ユーザーにアップデートを促すメッセージを表示する
        globalStore.dispatch(setUpdateAvailable({ updateAvailable: true }))
      }
    },
  })
}
