import { colors } from "foundation/styles/theme"
import React, { ComponentPropsWithoutRef } from "react"
import { Button, ButtonProps } from "rebass"

/** アイコンのみのボタン
 *
 * ボタンにアイコンを入れたい場合は通常の`Button`を使用する
 * TODO: ベースカラーを元にhover, disabledを決めたい
 */
export const IconButton: React.FC<
  {
    title: string
    icon: string
    children?: never
  } & ButtonProps
> = React.forwardRef(({ icon, ...props }, ref) => (
  <Button
    ref={ref}
    color={colors.gray}
    {...props}
    sx={{
      cursor: "pointer",
      textAlign: "center",
      outline: "none",
      lineHeight: 1,
      "&:disabled": {
        color: colors.lightGray,
      },
      ...props.sx,
    }}
    variant="buttons.icon"
  >
    <i className={icon} />
  </Button>
))

export const SquareDotIconButton = React.forwardRef<
  any,
  Omit<ComponentPropsWithoutRef<typeof IconButton>, "icon">
>(({ ...props }, ref) => (
  <IconButton
    ref={ref}
    icon="fal fa-ellipsis-h"
    fontSize={"24px"}
    p={"4px"}
    width={"32px"}
    height={"32px"}
    color="gray3"
    {...props}
    sx={{
      borderRadius: "50%",
      boxShadow: "0 1px 15px 0 rgba(0,0,0,0.09)",
      background: "white",
      ...props.sx,
    }}
  />
))
