import { decode, encode } from "blurhash"
import cache from "memory-cache"

type BlurhashWithParams = {
  bh: string
  width: number
  height: number
}

export const encodeBlurHashParams = ({
  bh: h,
  width,
  height,
}: BlurhashWithParams) => {
  return `${h}/${width}/${height}`
}

export const decodeBlurHashParams = (u: string): BlurhashWithParams => {
  const [h, width, height] = u.split("/")
  return { bh: h, width: Number(width), height: Number(height) }
}

/** パラメーター埋め込み済みのblurhash文字列を画像に変換します */
export const decodeImage = (u: string | BlurhashWithParams) => {
  let bhp: BlurhashWithParams
  if (typeof u === "string") {
    bhp = decodeBlurHashParams(u)
  } else {
    bhp = u
  }
  const { bh: h, width, height } = bhp
  const canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext("2d")
  if (ctx) {
    const image = ctx.createImageData(width, height)
    const data = decode(h, width, height)
    image.data.set(data)
    ctx.putImageData(image, 0, 0)
    return { src: canvas.toDataURL(), width, height }
  }
}

/** 画像をblurthashで変換します
 * エンコードできない場合は画像のメタデータのみ返します
 */
export const encodeImage = async (
  file: File | HTMLImageElement
): Promise<BlurhashWithParams | { width: number; height: number }> => {
  // 画像を読み込む
  let image: { data: HTMLImageElement; width: number; height: number }
  if (file instanceof HTMLImageElement) {
    image = { data: file, width: file.naturalWidth, height: file.naturalHeight }
  } else {
    image = await new Promise<{
      data: HTMLImageElement
      width: number
      height: number
    }>((done, fail) => {
      const image = new Image()
      image.onload = function () {
        done({
          data: image,
          width: image.naturalWidth,
          height: image.naturalHeight,
        })
      }
      image.src = URL.createObjectURL(file)
    })
  }

  // 縮小して描画
  const canvas = document.createElement("canvas")
  const ratio = Math.min(
    Math.min(image.height, 250) / image.height,
    Math.min(image.width, 250) / image.width
  )
  canvas.width = image.width * ratio
  canvas.height = image.height * ratio
  const ctx = canvas.getContext("2d")
  if (!ctx) {
    URL.revokeObjectURL(image.data.src)
    return { width: image.width, height: image.height }
  }
  ctx.drawImage(image.data, 0, 0, canvas.width, canvas.height)
  URL.revokeObjectURL(image.data.src)
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

  // エンコード
  return {
    bh: encode(imageData.data, imageData.width, imageData.height, 4, 3),
    width: imageData.width,
    height: imageData.height,
  }
}

export const decodeToURLCached = (
  u: string
): ReturnType<typeof decodeImage> => {
  const cached = cache.get(`blurhash_${u}`)
  return cached ?? cache.put(`blurhash_${u}`, decodeImage(u))
}
