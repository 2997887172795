export enum EditorMark {
  Bold = "bold",
  Strike = "strike",
  Color = "color",
  Link = "link",
}
/** `EditorMark`の値のSet */
export const EditorMarkValues = new Set(Object.values<string>(EditorMark))
/** typeに応じたMarkのオプション用のキーを取得
 * NOTE: 一緒にしたい
 */
export const EditorMarkOptionKey = (type: EditorMark) => {
  switch (type) {
    case EditorMark.Link:
      return EditorMarkLinkOptionKey
    case EditorMark.Color:
      return EditorMarkColorOptionKey
    default:
      return
  }
}
export type EditorMarkOptionTypeMap = {
  [EditorMark.Link]: EditorMarkLinkOption
  [EditorMark.Color]: EditorMarkColorOption
  [EditorMark.Bold]: undefined
  [EditorMark.Strike]: undefined
}
export const EditorMarkLinkOptionKey = "linkURL"
export const EditorMarkColorOptionKey = "colorStr"
export type EditorMarkLinkOption =
  | string
  | {
      url: string
      newWindow?: boolean
    }
export type EditorMarkColorOption = string
