import { assert } from "@common/libs"

/** 実行環境, 設定値の管理を行ないます */

/**
 * 実行環境の種類を返す
 *
 * - production: 本番, 本番DBを使用(TODO)
 * - beta: beta版, 開発用DBを使用
 * - development: 上記以外, DBは環境変数もしくは開発用DB
 */
export const applicationEnv = () => {
  assert(window && window.location)
  if (process.env.NODE_ENV === "production") {
    const host = window.location.hostname
    switch (host) {
      case "spirally.io":
        return "production"
      case "beta.spirally.io":
        return "beta"
    }
    return "development"
  } else {
    return "development"
  }
}
