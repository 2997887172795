import { StorageRef } from "@common/domain/common"
import {
  EmptyPage,
  Page,
  Project,
  RootCategoryKey,
  TopPageKey,
  ProjectPageTreeCategory,
  PageFB,
} from "@common/domain/project"
import { AppThunk } from "context/entrypoint/store"
import { fetchMyProjectsAction } from "context/project/slices"
import {
  fetchProject,
  publishProject,
  updateProject,
  updateProjectField,
} from "context/project/slices/projectAction"
import { parseError } from "foundation/utils/error"
import produce from "immer"
import { findKey } from "lodash"
import { ulid } from "ulid"
import { deletePageAction, updatePageAction } from "./pageAction"
import { setPages } from "./pageEditorSlice"
import {
  setLoadedProjectContent,
  setProject,
  setProjectLoading,
} from "./projectEditorSlice"
import { getPage } from "@common/domain/editor/editorActions"

export const updateProjectAction = (payload: {
  teamID: string
  project: Project
}): AppThunk<Promise<void>> => async (dispatch) => {
  // ローカルをまず反映
  dispatch(setProject({ project: payload.project }))
  try {
    await updateProject(payload)
  } catch (e) {
    // TODO: 必要であればローカルのプロジェクトをロールバックする
    console.error(e)
  }
}

export const updateProjectThumbnailAction = (payload: {
  teamID: string
  id: string
  ref: StorageRef
}): AppThunk => async (dispatch) => {
  dispatch(
    setProject({
      merge: true,
      project: { coverImage: payload.ref },
    })
  )

  await updateProjectField({
    teamID: payload.teamID,
    id: payload.id,
    fields: {
      coverImage: payload.ref,
    },
  })
  await dispatch(fetchMyProjectsAction())
}

/** プロジェクトを公開する
 * @todo 進捗、エラーハンドリング
 */
export const publishProjectAction = (
  payload: {
    id: string
    teamID: string
  },
  waitPublished?: boolean
): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    const publishedAt = await publishProject({
      id: payload.id,
      teamID: payload.teamID,
      waitPublished,
    })
    dispatch(setProject({ project: { publishedAt }, merge: true }))
  } catch (e) {
    console.error(e)
  }
}

export const loadProjectAction = (payload: {
  id: string
  teamID: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  // ローディング
  dispatch(setProjectLoading(payload))

  try {
    const { project, team, pages } = await fetchProject({
      id: payload.id,
      teamID: payload.teamID,
    })

    // OK
    dispatch(
      setPages({
        pages,
      })
    )
    dispatch(
      setLoadedProjectContent({
        value: { team, project },
      })
    )
  } catch (e) {
    console.error(e)
    // NG
    dispatch(
      setLoadedProjectContent({
        error: parseError(e),
      })
    )
  }
}

export const renameProjectCategoryAction = (payload: {
  teamID: string
  project: Project
  key: string
  name: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  const updated = produce(payload.project, (draft) => {
    if (draft.pageTree.categories[payload.key]) {
      draft.pageTree.categories[payload.key].name = payload.name
    }
  })
  await dispatch(
    updateProjectAction({
      teamID: payload.teamID,
      project: updated,
    })
  )
}

export const appendProjectCategoryAction = ({
  teamID,
  project,
  baseKey,
  direction,
  name,
}: {
  teamID: string
  project: Project
  baseKey: string | undefined
  direction: "prev" | "next"
  name: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  const key = ulid()
  const updated = produce(project, (draft) => {
    // カテゴリ追加
    draft.pageTree.categories[key] = { name, pages: [] }

    // 指定された場所にカテゴリを追加
    let cats = draft.pageTree.category_order
    if (baseKey === undefined) {
      cats = direction === "prev" ? [key, ...cats] : [...cats, key]
    } else {
      const i = cats.indexOf(baseKey)
      const ti = Math.max(direction === "prev" ? i : i + 1, 0)
      cats = [...cats.slice(0, ti), key, ...cats.slice(ti)]
    }
    draft.pageTree.category_order = cats
  })
  await dispatch(updateProjectAction({ teamID: teamID, project: updated }))
}

export const copyProjectCategoryAction = ({
  teamID,
  project,
  baseKey,
  category,
}: {
  teamID: string
  project: Project
  baseKey: string
  category: ProjectPageTreeCategory
}): AppThunk<Promise<void>> => async (dispatch) => {
  if (category.pages.length === 0) {
    return
  }

  const newCategoryKey = ulid()
  const updated = produce(project, (draft) => {
    // ページを作ったカテゴリにコピー
    const copy_pages = category.pages.map((_) => ulid())
    category.pages.map(async (page, index) => {
      const pageFB = await getPage({
        teamID,
        projectID: project.id,
        pageID: page,
      })
      if (!pageFB.data()) {
        return
      }
      const _pageFB = pageFB.data() as PageFB
      await dispatch(
        updatePageAction({
          page: {
            id: copy_pages[index],
            displayName: _pageFB.displayName + " のコピー",
            content: JSON.parse(_pageFB.content),
          },
        })
      )
    })

    // カテゴリ追加 pagesに配列を入れる
    draft.pageTree.categories[newCategoryKey] = {
      name: category.name + " のコピー",
      pages: copy_pages,
    }
    // 指定された場所にカテゴリを追加
    let cats = draft.pageTree.category_order
    const i = cats.indexOf(baseKey)
    const ti = Math.max(i + 1, 0)
    cats = [...cats.slice(0, ti), newCategoryKey, ...cats.slice(ti)]
    draft.pageTree.category_order = cats
  })

  await dispatch(updateProjectAction({ teamID: teamID, project: updated }))
}

export const appendProjectPageAction = ({
  teamID,
  project,
  categoryKey,
  basePage,
  direction,
  name,
}: {
  teamID: string
  project: Project
  categoryKey: string | undefined
  basePage: string | undefined
  direction: "prev" | "next"
  name: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  const key = ulid()
  const updated = produce(project, (draft) => {
    // 追加先のカテゴリを決定
    if (!categoryKey) {
      if (basePage === undefined) {
        categoryKey = draft.pageTree.category_order[0]
      } else if (basePage === TopPageKey) {
        categoryKey = RootCategoryKey
      } else {
        categoryKey = findKey(draft.pageTree.categories, (obj) =>
          obj.pages.includes(basePage)
        )
      }
    }
    if (!categoryKey) return
    if (categoryKey !== RootCategoryKey) {
      if (!draft.pageTree.categories[categoryKey]) return
      if (!draft.pageTree.categories[categoryKey].pages) return
    } else {
      if (!draft.pageTree.categories[categoryKey]) {
        draft.pageTree.categories[categoryKey] = { name: "", pages: [] }
      } else if (!draft.pageTree.categories[categoryKey].pages) {
        draft.pageTree.categories[categoryKey].pages = []
      }
    }

    // ページ追加
    let pages = draft.pageTree.categories[categoryKey].pages
    if (basePage === undefined) {
      pages = direction === "prev" ? [key, ...pages] : [...pages, key]
    } else {
      const i = pages.indexOf(basePage)
      const ti = Math.max(direction === "prev" ? i : i + 1, 0)
      pages = [...pages.slice(0, ti), key, ...pages.slice(ti)]
    }
    draft.pageTree.categories[categoryKey].pages = pages
  })

  await dispatch(updatePageAction({ page: EmptyPage(key, name) }))
  await dispatch(updateProjectAction({ teamID: teamID, project: updated }))
}

export const copyProjectPageAction = ({
  teamID,
  project,
  page,
  categoryKey,
}: {
  teamID: string
  project: Project
  page: Page
  categoryKey: string | undefined
}): AppThunk<Promise<void>> => async (dispatch) => {
  const key = ulid()
  const updated = produce(project, (draft) => {
    // 追加先のカテゴリを決定
    if (!categoryKey) {
      categoryKey = RootCategoryKey
    }
    let pages = draft.pageTree.categories[categoryKey].pages
    pages = [...pages, key]
    draft.pageTree.categories[categoryKey].pages = pages
  })
  await dispatch(
    updatePageAction({
      page: {
        id: key,
        displayName: page.displayName + " のコピー",
        content: page.content,
      },
    })
  )
  await dispatch(updateProjectAction({ teamID: teamID, project: updated }))
}

export const deleteProjectPageAction = ({
  teamID,
  project,
  key,
}: {
  teamID: string
  project: Project
  key: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  const updated = produce(project, (draft) => {
    Object.values(draft.pageTree.categories).forEach((cat) => {
      cat.pages = cat.pages.filter((pkey) => pkey !== key)
    })
  })
  await dispatch(updateProjectAction({ teamID, project: updated }))
  await dispatch(deletePageAction({ key }))
}

export const deleteProjectCategoryAction = ({
  teamID,
  project,
  key,
}: {
  teamID: string
  project: Project
  key: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  if (key === RootCategoryKey) return
  const updated = produce(project, (draft) => {
    const cat = draft.pageTree.categories[key]
    delete draft.pageTree.categories[key]
    draft.pageTree.category_order = draft.pageTree.category_order.filter(
      (k) => k !== key
    )

    // ページが存在する場合はトップページに移動しちゃう
    if (cat.pages?.length > 0) {
      // 初期状態ではRootCategoryKeyには何もないので必要であれば作成する
      if (!draft.pageTree.categories[RootCategoryKey]) {
        draft.pageTree.categories[RootCategoryKey] = { name: "", pages: [] }
      }
      draft.pageTree.categories[RootCategoryKey].pages.push(...cat.pages)
    }
  })
  await dispatch(updateProjectAction({ teamID, project: updated }))
}

export const moveProjectCategoryAction = ({
  teamID,
  project,
  from,
  to,
}: {
  teamID: string
  project: Project
  from: number
  to: number
}): AppThunk<Promise<void>> => async (dispatch) => {
  // NOTE: とりあえず保存せずに動かす
  const item = project.pageTree.category_order[from]
  const updated = produce(project, (draft) => {
    draft.pageTree.category_order = [
      ...draft.pageTree.category_order.slice(0, from),
      ...draft.pageTree.category_order.slice(from + 1),
    ]
    draft.pageTree.category_order = [
      ...draft.pageTree.category_order.slice(0, to),
      item,
      ...draft.pageTree.category_order.slice(to),
    ]
  })

  await dispatch(updateProjectAction({ teamID, project: updated }))
}

export const moveProjectPageAction = ({
  teamID,
  project,
  from,
  to,
}: {
  teamID: string
  project: Project
  from: { categoryKey: string; index: number }
  to: { categoryKey: string; index: number }
}): AppThunk<Promise<void>> => async (dispatch) => {
  const fromCatKey = from.categoryKey
  const toCatKey = to.categoryKey
  const fromItemIndex = from.index
  const toItemIndex = to.index
  const itemKey = project.pageTree.categories[fromCatKey].pages[fromItemIndex]

  const updated = produce(project, (draft) => {
    // NOTE: 製作時の経緯により2回に分けているが短くできそう
    draft.pageTree.categories[fromCatKey].pages = [
      ...draft.pageTree.categories[fromCatKey].pages.slice(0, fromItemIndex),
      ...draft.pageTree.categories[fromCatKey].pages.slice(fromItemIndex + 1),
    ]
    draft.pageTree.categories[toCatKey].pages = [
      ...draft.pageTree.categories[toCatKey].pages.slice(0, toItemIndex),
      itemKey,
      ...draft.pageTree.categories[toCatKey].pages.slice(toItemIndex),
    ]
  })

  await dispatch(updateProjectAction({ teamID, project: updated }))
}
