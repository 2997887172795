import React, { useState } from "react"
import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"

type tooltipProps = {
  /** ツールチップの有効/無効フラグ*/
  isActive?: boolean
  tooltipLabel: string
  /**ツールチップをクリック("click")で表示かホバー("hover")で表示かを指定*/
  handler: string
  top?: string
  left?: string
  /**ハンドラがクリックの時の表示秒数（ミリ秒）*/
  howlong?: number
}

const TooltipElem = styled.div`
  position: relative;
  .tooltip {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    color: white;
    background: ${colors.gray5};
    padding: 4px 8px;
    white-space: nowrap;
  }
`

export const Tooltip: React.FC<tooltipProps> = (props) => {
  const [visible, setVisible] = useState(props.isActive)
  let length = 1000
  if (props.howlong !== undefined) {
    length = props.howlong
  }
  const handleClick = () => {
    if (!visible) {
      const sleep = (msec: number) =>
        new Promise((resolve) => setTimeout(resolve, msec))
      ;(async () => {
        setVisible(true)
        await sleep(length)
        setVisible(false)
      })()
    }
  }
  const handleMouseEnter = () => {
    setVisible(true)
  }
  const handleMouseLeave = () => {
    setVisible(false)
  }

  return (
    <TooltipElem>
      <span
        className="tooltip"
        style={{
          top: props.top,
          left: props.left,
          display: visible ? "block" : "none",
        }}
      >
        {props.tooltipLabel}
      </span>
      <div
        onMouseEnter={props.handler === "hover" ? handleMouseEnter : undefined}
        onMouseLeave={props.handler === "hover" ? handleMouseLeave : undefined}
        onClick={props.handler === "click" ? handleClick : undefined}
      >
        {props.children}
      </div>
    </TooltipElem>
  )
}

Tooltip.defaultProps = {
  isActive: false,
  top: "-30px",
  left: "0px",
}
