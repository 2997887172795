import React from "react"
import { Markdown as MD } from "foundation/utils/markdown"

export const Markdown: React.FC<{
  markdown: string
  className?: string
}> = (props) => (
  <div
    className={props.className}
    dangerouslySetInnerHTML={{ __html: MD.render(props.markdown) }}
  />
)
