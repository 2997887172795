import { URL } from "url"

export interface DocumentLike {
  collection(collectionPath: string): CollectionLike
}

export interface CollectionLike {
  doc(documentPath?: string): DocumentLike
}

export interface StoreLike {
  collection(collectionPath: string): CollectionLike
  doc(documentPath?: string): DocumentLike
}

export interface StorageRefLike {
  child(s: string): this
}

// 妥協してGenericsの後に戻り値を指定するために強制キャストするのに使うやつ

export type TCollection<T extends StoreLike> = ReturnType<T["collection"]>

export type TDocument<T extends StoreLike> = ReturnType<T["doc"]>

export class PathShim {
  constructor(public segments: string[] = []) {}
  static delimiter = "/"

  child(path: string) {
    return new PathShim([...this.segments, path])
  }
  toString() {
    return this.segments.join(PathShim.delimiter)
  }
}

/** ファイルのパス(gs://)を表現するためのURLクラス
 */
export class StorageURL {
  private baseURL: URL

  static Scheme = "gs:"

  constructor(input: string, base?: string) {
    this.baseURL = new URL(input, base)
  }

  static from(bucket: string, path: string) {
    return new this(
      `${this.Scheme}//${bucket}/${path[0] === "/" ? path.slice(1) : path}`
    )
  }

  get bucket() {
    return this.baseURL.host
  }

  get path() {
    return this.baseURL.pathname.slice(1)
  }

  get url() {
    return `${StorageURL.Scheme}//${this.bucket}/${this.path}`
  }

  withPath(input: string) {
    return new StorageURL(
      input,
      `${this.baseURL.protocol}//${this.baseURL.hostname}`
    )
  }

  withChild(input: string) {
    return new StorageURL(input, this.url + "/")
  }
}
