import React from "react"
import { colors } from "foundation/styles/theme/colors"
import styled from "@emotion/styled"
import { FSImage, FSImageProps } from "foundation/components/FSImage"
import { Image, ImageProps } from "foundation/components"
import { ImageOption } from "@common/domain/editor/EditorDataType"
import { GetProjectTemplateResource } from "context/project-editor/context/template"
import { UnreachableCaseError } from "@common/libs"

export type UImageProps = ImageProps & {
  padding?: string
  attributes?: any
}

/** 汎用の画像コンポーネント
 * - ブロックリンクやグリッドでの使用も想定
 */
export const UImage: React.FC<
  {
    option: ImageOption

    /** ブロック画像として使用する */
    block?: boolean
  } & Partial<UImageProps>
> = React.forwardRef(({ option: opt, block, ...props }, ref) => {
  const padding = typeof opt.padding === "string" ? opt.padding : undefined
  switch (opt.type) {
    case "STORAGE": {
      const Component = block ? FSImageBlock : FSImage
      return (
        <Component
          {...props}
          ref={ref}
          fileRef={opt.file}
          refVariant="large"
          padding={padding}
        />
      )
    }
    case undefined: /** Compatibility */
    case "REMOTE": {
      const Component = block ? DeprecatedUImageBlock : Image
      return (
        <Component
          {...props}
          ref={ref}
          src={opt.src}
          alt={opt.src}
          padding={padding}
        />
      )
    }
    case "DUMMY": {
      const src = GetProjectTemplateResource(opt.template as any, opt.key)
      const Component = block ? DeprecatedUImageBlock : Image
      return (
        <Component
          {...props}
          padding={padding}
          ref={ref}
          src={src}
          alt={"ダミー画像"}
        />
      )
    }
    default:
      console.error("Unexpected image type", opt)
      throw new UnreachableCaseError(opt)
  }
})

/** 画像(ブロック)
 *
 * - ブロックなので画像と罫線が表示されます
 * - 本文中に挿入するのに適したmarginが設定されます(実装上はpadding)
 */
const ImageBlockBase: React.FC<UImageProps> = ({
  src,
  alt = "",
  padding,
  attributes,
}) => {
  return (
    <UImageBlockBox {...attributes} padding={padding}>
      <Image src={src} alt={alt} />
    </UImageBlockBox>
  )
}

/** 直接使用せずに、UImageを使ってください
 * @deprecated
 * srcを直接指定できる画像ブロック. UImageの内部で使用
 */
export const DeprecatedUImageBlock = ImageBlockBase

/** FSImage版のUImageBlock
 */
const FSImageBlock: React.FC<
  FSImageProps & {
    padding?: string
    attributes?: any
  }
> = ({ padding, attributes, ...props }) => (
  <UImageBlockBox {...attributes} padding={padding}>
    <FSImage {...props} width="100%" />
  </UImageBlockBox>
)

type UImageWrapperProps = {
  padding?: string
}

const UImageBlockBox = styled.div<UImageWrapperProps>`
  margin: 32px auto 0;
  width: 696px;
  border-radius: 4px;
  border: 1px solid ${colors.borderGray};
  padding: ${(props) => props.padding || 0};
  display: flex;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`
