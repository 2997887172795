import {
  GetStorageRefVariant,
  MetaProgress,
  StorageRef,
  VariantBhHash,
} from "@common/domain/common"
import { StorageRefGetURL } from "foundation/utils/firebase/storage"
import { decodeToURLCached } from "foundation/utils/blurhash"
import cache from "memory-cache"
import React, { useCallback, useMemo } from "react"
import { Image, ImageProps } from "./Image"

export type FSImageProps = Omit<ImageProps, "src" | "css"> & {
  fileRef?: StorageRef | null
  refVariant?: string | string[]
}

/**
 * FirebaseStorageの画像を表示するコンポーネント
 * fileRefをとりあえず渡せるようにしているので再レンダリング
 */
export const FSImage: React.FC<FSImageProps> = React.memo(
  React.forwardRef(({ fileRef, refVariant, ...props }, ref) => {
    // variantの指定があれば読み込む
    const { refPath, actualRef, actualRefKey } = useMemo(() => {
      if (fileRef && refVariant) {
        const ret = GetStorageRefVariant(fileRef, refVariant)
        if (ret) {
          return {
            refPath: ret.ref.path,
            actualRef: ret.ref,
            actualRefKey: ret.key,
          }
        }
      }
      // fallback
      return {
        refPath: fileRef?.path,
        actualRef: fileRef,
        actualRefKey: undefined,
      }
    }, [fileRef, refVariant])

    // 画像のsrcを取得
    const { src } = useMemo(() => {
      return {
        src:
          (actualRef &&
            refIsPersisted(actualRef) &&
            StorageRefGetURL(actualRef)) ||
          null,
      }
    }, [actualRef])

    // ブラウザに画像がダウンロードされていそうな場合を除き、Blurhashがあれば表示する
    const imageLoadedBefore = useMemo(
      () => refPath && imageResolved(refPath, actualRefKey),
      [actualRefKey, refPath]
    )
    const bhHash = fileRef?.variants?.[VariantBhHash]?.path
    const bhURL = useMemo(() => {
      return !imageLoadedBefore && bhHash && decodeToURLCached(bhHash)
    }, [bhHash, imageLoadedBefore])

    const handleLoad = useCallback(() => {
      refPath && markImageResolved(refPath, actualRefKey)
    }, [actualRefKey, refPath])

    return (
      <Image
        {...props}
        ref={ref}
        src={src}
        loading={bhURL ? bhURL.src : undefined}
        onLoad={handleLoad}
        displaySize={
          props.displaySize ??
          ((bhURL && { ratio: bhURL.height / bhURL.width }) || undefined) ??
          undefined
        }
      />
    )
  })
)

const refIsPersisted = (ref: StorageRef) => {
  const progress = ref.metadata?.[MetaProgress]
  return progress === 1 || (progress !== 0 && !progress)
}

// おそらくブラウザのキャッシュに乗っているかを覚えておく
const imageResolved = (refPath: string, variant?: string) =>
  !!cache.get(`fsimage:loaded_${refPath}:${variant}`)
const markImageResolved = (refPath: string, variant?: string) =>
  cache.put(`fsimage:loaded_${refPath}:${variant}`, true)
