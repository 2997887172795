import React, { useMemo, useState, useCallback } from "react"
import { Flex, Box } from "rebass"

type TabPaneProps = {
  label?: string
  itemKey?: string
}

export const TabPane: React.FC<TabPaneProps> = (props) => <>{props.children}</>

type TabDefinition = {
  tabs: TabLink[]
  key2Index: {
    [key: string]: number
  }
}

type TabLink = {
  key: string
  label: string
}

export const Tab: React.FC<{
  defaultSelectedKey?: string
  presenter?: React.ComponentType<ITabPresenterProps>
}> = ({ children, presenter: Presenter = DefaultTabPresenter, ...props }) => {
  const tabDef: TabDefinition = useMemo(() => {
    const result: {
      tabs: { key: string; label: string }[]
      key2Index: { [key: string]: number }
    } = {
      tabs: [],
      key2Index: {},
    }
    React.Children.map(
      React.Children.toArray(children),
      (child: any, index: number) => {
        if (child && typeof child === "object" && child.type === TabPaneType) {
          const pane = child as React.FunctionComponentElement<TabPaneProps>
          const tab = {
            key: pane.props.itemKey || index.toString(),
            label: pane.props.label || index.toString(),
          }
          result.tabs.push(tab)
          result.key2Index[tab.key] = index
        }
      }
    )
    return result
  }, [children])

  const [selectedKey, setSelectedKey] = useState(
    () => props.defaultSelectedKey || tabDef.tabs[0].key
  )

  const onClickLink = useCallback(
    (v: string) => {
      setSelectedKey(v)
    },
    [setSelectedKey]
  )

  const currentPane = useMemo(
    () => React.Children.toArray(children)[tabDef.key2Index[selectedKey]],
    [children, tabDef.key2Index, selectedKey]
  )

  return (
    <Presenter
      links={tabDef.tabs}
      onClickLink={onClickLink}
      currentKey={selectedKey}
    >
      {currentPane}
    </Presenter>
  )
}

type ITabPresenterProps = {
  links: TabLink[]
  currentKey: string
  onClickLink: (key: string) => void
}

const DefaultTabPresenter: React.FC<ITabPresenterProps> = (props) => {
  return (
    <Flex flexDirection="column">
      <Flex>
        {props.links.map((tab) => (
          <Box mx={1} key={tab.key}>
            <button onClick={props.onClickLink.bind(undefined, tab.key)}>
              {tab.label}
              {tab.key === props.currentKey && "*"}
            </button>
          </Box>
        ))}
      </Flex>
      <Box sx={{ flexGrow: 1 }}>{props.children}</Box>
    </Flex>
  )
}

const TabPaneType = React.createElement(TabPane).type
