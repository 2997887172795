import { setupFirebase } from "./firebase"
export * from "./env"
export * from "./errors"
export * from "./offline"
export * from "./promiseutil"
export * from "./typeutil"
export * from "./assert"

export const setupCommon = (options: { firebase: "client" | "admin" }) => {
  setupFirebase(options.firebase)
}
