import { css } from "@emotion/core"

export const ScreenOnly = css`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;

  &:focus,
  &:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }
`

export const prettyScrollCSS = css`
  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-corner {
    background: red;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(1, 1, 1, 0);
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(1, 1, 1, 0.2);
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`
