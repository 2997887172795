import { GuidelineFooter } from "context/layout/components/GuidelineFooter"
import {
  GuidelineContent,
  GuidelineLayout,
  GuidelineMain,
} from "context/layout/templates/GuidelineLayout"
import { ProjectSidebar } from "context/project-editor/components"
import {
  CoverImageOld,
  PageTitle,
  UBlockLink,
  UColorGridItem,
  UFile,
  UHeading,
  DeprecatedUImageBlock,
  ULayoutGrid,
  UList,
  UListItem,
  UTable,
  UTableCell,
  UTableRow,
  UTextAnnotationA,
  UTextAnnotationBold,
  UTextLine,
  UUl,
} from "context/user-content/components/page"
import React, { useCallback } from "react"
import {
  HashRouter,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom"
import blockImage01 from "./assets/block_01.jpg"
import coverImagePath from "./assets/coverImg.jpg"
import moodborad1 from "./assets/fujihome_image_001.jpg"
import logo1 from "./assets/logo1.png"

const thirdScopePage: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <HashRouter hashType="noslash">
      <GuidelineLayout>
        <Sidebar />
        <GuidelineMain>
          <Switch>
            <Route exact path="/" component={PTop} />
            {/** BRAND */}
            <Route path="/statement" component={PStatement} />
            <Route path="/philosophy" component={PPhilosophy} />
            <Route path="/moodboard" component={PMoodboard} />
            <Route path="/voiceAndTone" component={PVoiceAndTone} />
            <Route path="/history" component={PHistory} />
            <Route path="/information" component={PInformation} />
            <Route path="/designRegulation" component={PDesignRegulation} />
            <Route path="/logo" component={PLogo} />
            <Route path="/color" component={PColor} />
            <Route path="/typography" component={PTypography} />
            <Route path="/icon" component={PIcon} />
            <Route path="/photographic" component={PPhotographic} />
            <Route path="/buisinessCard" component={PBuisinessCard} />
            <Route path="/catalog" component={PCatalog} />
            <Route path="/webSite" component={PWeb} />
            <Route path="/mediakit" component={PMediakit} />
          </Switch>
          <GuidelineFooter />
        </GuidelineMain>
      </GuidelineLayout>
    </HashRouter>
  )
}

export default thirdScopePage

/** サイドバー */
const Sidebar = withRouter((props) => (
  <ProjectSidebar
    logoAlt="sample"
    logoSrc={logo1}
    currentKey="/"
    css={{
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
    }}
    onSelectPage={props.history.push}
    dataDontUse={[
      {
        category: {
          key: "IDENTITIIES",
          label: "アイデンティティー",
        },
        pages: [
          {
            key: "statement",
            label: "ステートメント",
          },
          {
            key: "philosophy",
            label: "フィロソフィー",
          },
          {
            key: "moodboard",
            label: "ムードボード",
          },
          {
            key: "voiceAndTone",
            label: "ボイス＆トーン",
          },
          {
            key: "history",
            label: "歴史・沿革",
          },
        ],
      },
      {
        category: {
          key: "BRAND",
          label: "ブランド",
        },
        pages: [
          {
            key: "information",
            label: "基本情報",
          },
          {
            key: "designRegulation",
            label: "デザイン原則",
          },
          {
            key: "logo",
            label: "ロゴ",
          },
          {
            key: "color",
            label: "カラー",
          },
          {
            key: "typography",
            label: "タイポグラフィー",
          },
          {
            key: "icon",
            label: "アイコン",
          },
          {
            key: "photographic",
            label: "フォトグラフィック",
          },
        ],
      },
      {
        category: {
          key: "PRODUCT",
          label: "プロダクト",
        },
        pages: [
          {
            key: "buisinessCard",
            label: "名刺",
          },
          {
            key: "catalog",
            label: "カタログ",
          },
          {
            key: "webSite",
            label: "Webサイト",
          },
        ],
      },
      {
        category: {
          key: "MARKETING",
          label: "マーケティング",
        },
        pages: [
          {
            key: "mediakit",
            label: "メディアキット",
          },
        ],
      },
    ]}
  ></ProjectSidebar>
))

const PTop: React.FC<RouteComponentProps> = ({ history }) => {
  const handleBlockLink = useCallback(
    (key: string) => {
      history.push(key)
      window.scrollTo(0, 0)
    },
    [history]
  )

  return (
    <>
      <CoverImageOld src={coverImagePath} />
      <GuidelineContent readOnly>
        <PageTitle title="はじめに" />
        <UTextLine
          content={
            <>
              Third Scope
              ブランドガイドライン（以下、本ガイドライン）は、私たちの哲学や理念、製品やサービスの情報、デザインや開発用のリソースなどがまとめらています。
              <br />
              <br />
              本ガイドラインは一貫性のあるブランド体験を構築することを目的に使用され、ユーザーやパートナーに対してより優れた製品やサービス、価値を提供します。
            </>
          }
        />
        <UHeading level={2} content="本ガイドラインの対象者" />
        <UList>
          <UUl>
            <UListItem>Third Scopeのメンバー</UListItem>
            <UListItem>
              外部パートナー（デザイナー、エンジニア、マネージャー、営業、代理店など）
            </UListItem>
          </UUl>
        </UList>
        <UHeading level={2} content="ピックアップ" />
        <ULayoutGrid itemsPerLine={3}>
          <UBlockLink
            link="statement"
            imageSrc={blockImage01}
            alt="sample image"
            category="アイデンティティ"
            title="ステートメント"
            onClick={handleBlockLink}
          />
          <UBlockLink
            link="philosophy"
            imageSrc={blockImage01}
            alt="sample image"
            category="アイデンティティ"
            title="フィロソフィー"
            onClick={handleBlockLink}
          />
          <UBlockLink
            link="philosophy"
            imageSrc={blockImage01}
            alt="sample image"
            category="アイデンティティ"
            title="ムードボード"
            onClick={handleBlockLink}
          />
          <UBlockLink
            link="philosophy"
            imageSrc={blockImage01}
            alt="sample image"
            category="ブランド"
            title="ロゴ"
            onClick={handleBlockLink}
          />
          <UBlockLink
            link="philosophy"
            imageSrc={blockImage01}
            alt="sample image"
            category="ブランド"
            title="カラー"
            onClick={handleBlockLink}
          />
          <UBlockLink
            link="philosophy"
            imageSrc={blockImage01}
            alt="sample image"
            category="プロダクト"
            title="名刺"
            onClick={handleBlockLink}
          />
        </ULayoutGrid>
        <UList>
          <UUl>
            <UListItem>
              <UTextAnnotationA href="https://third-scope.com/">
                株式会社サードスコープ コーポレートサイト
              </UTextAnnotationA>
            </UListItem>
            <UListItem>
              <UTextAnnotationA href="https://www.wantedly.com/companies/third-scope#_=_">
                Wantedly
              </UTextAnnotationA>
            </UListItem>
            <UListItem>
              <UTextAnnotationA href="https://github.com/thirdscope">
                GitHub
              </UTextAnnotationA>
            </UListItem>
          </UUl>
        </UList>
      </GuidelineContent>
    </>
  )
}

const PStatement: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="アイデンティティ" title="ステートメント" />
    <DeprecatedUImageBlock src={moodborad1} alt="ムードボード1" />
    <UTextLine
      content={
        <>
          サードスコープでは“未来を語ろう”をスローガンに掲げ、
          クリエイティブとテクノロジーを掛け合わせることによってユーザーやパートナーの資産価値や文化を築く事を目的に活動しています。
        </>
      }
    />
    <UTextLine
      content={
        <>
          <UTextAnnotationBold>未来を語ろう</UTextAnnotationBold>
        </>
      }
    />
    <UTextLine
      content={
        <>
          サードスコープはクリエイティビティのパフォーマンス向上を目指す、クリエイティブ・テック・カンパニーです。
        </>
      }
    />
    <UTextLine
      content={
        <>
          クリエイティブとテクノロジーの力を掛け合わせ、世界中のクリエイターにさまざまな新しい文化を創出する環境・価値を提供し、クリエイター中心のクリエイティブの仕組みづくりを目指していきます。
        </>
      }
    />
    <UTextLine
      content={<>それが、我々の目指す姿であり皆さまへの約束です。</>}
    />
  </GuidelineContent>
)

const PPhilosophy: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="アイデンティティ" title="フィロソフィー" />
    <UHeading level={1} content="ミッション (存在意義・使命)" />
    <UTextLine
      content={
        <>
          <UTextAnnotationBold>
            テクノロジーの力でブランディングをサポートする
          </UTextAnnotationBold>
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>“未来を語る”ということは、</UListItem>
        <UListItem>結果として〜</UListItem>
      </UUl>
    </UList>
    <UHeading level={1} content="ビジョン (目指す姿・ありたい姿)" />
    <UTextLine
      content={
        <>
          <UTextAnnotationBold>
            世界中のクリエイターたちへ、新しい文化を創出する
          </UTextAnnotationBold>
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>“未来を語る”ということは、</UListItem>
        <UListItem>結果として〜</UListItem>
      </UUl>
    </UList>
    <UHeading level={1} content="バリュー (価値観・行動原理)" />
    <UTextLine
      content={
        <>
          <UTextAnnotationBold>
            マーケティングを支えCreative x Techの開発
          </UTextAnnotationBold>
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>“未来を語る”ということは、</UListItem>
        <UListItem>結果として〜</UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PMoodboard: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="アイデンティティ" title="ムードボード" />
    <UTextLine
      content={
        <>
          ムードボードはブランドのあるべき姿や、伝えたいことなどを視覚化したもので、言語化が難しい要素を含めた表現を得意とします。
          ムードボードを活用することにより、ブランドに対する関係者毎の認識を統一します。
          これによりグラフィックやコピー、ブランド体験など開発でブレのない表現が可能になります。
        </>
      }
    />
    <DeprecatedUImageBlock src={moodborad1} alt="ムードボード1" />
  </GuidelineContent>
)

const PVoiceAndTone: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="アイデンティティ" title="ボイス＆トーン" />
    <UTextLine
      content={
        <>
          ボイス＆トーンはユーザーとのコミュニケーションの在り方を示した、コンテンツ及びコミュニケーションのガイドラインです。
          文章やメッセージでの表現の基準や考え方を明確にし、その状況により伝え方を使い分けることで、ユーザーとの信頼確立を助けたり、ユーザーが目的達成のために意識を集中することができるようになります。
        </>
      }
    />
    <UHeading level={1} content="ボイス" />
    <UTextLine
      content={
        <>
          ボイス（声）は、読み物やレビューで発言を紹介する時に「読者の声」や「ユーザーの声」と表現するように、その人が「話す内容」にあたります。
          また話す内容が同じでも、人によって話し方や表現が異なるため、個性の一部でもあります。
          <br />
          <br />
          このボイスの項目では、コンテンツにおけるボイス、つまり文章の書き方について意識することや考え方、方針を示します。
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>
          <UTextAnnotationBold>信頼できる。</UTextAnnotationBold>{" "}
          発言にブレがなく、思いやりのある言葉を選ぶ。
        </UListItem>
        <UListItem>
          <UTextAnnotationBold>分かりやすい。</UTextAnnotationBold>
          専門用語は必要に応じて使い、相手によって伝わりやすい言葉を選ぶ。
        </UListItem>
        <UListItem>
          <UTextAnnotationBold>有益である。 </UTextAnnotationBold>
          不要なことではなく、求められる内容を選ぶ。
        </UListItem>
        <UListItem>
          <UTextAnnotationBold>会話的。</UTextAnnotationBold>
          事務的ではなく、馴れ馴れしいわけでもない、ほどよい距離感を選ぶ。
        </UListItem>
      </UUl>
    </UList>
    <UHeading level={1} content="トーン" />
    <UTextLine
      content={
        <>
          トーンは、ワクワクするニュースや悲しいニュースを伝える時、または親友や上司と話す時のように、状況によって感情や伝え方が変わる「口調」にあたります。
          <br />
          ユーザーが困っていて、すぐにでもサポートが必要な状況では、親しみやユーモアのある接し方は必要ありません。
          その一方で、タスクが完了して緊張がほぐれた状況では、少しだけフレンドリーに接するという選択肢もあり、上手く判断できればユーザーとブランドの距離を縮めるきっかけになります。
          一番に考えることは、ユーザーの心境を考慮して接することです。
          <br />
          このトーンの項目では、コンテンツにおけるトーン、つまり文章を伝える時の状況毎にどのような口調で接するか一例を紹介します。
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>
          <UTextAnnotationBold>
            例：ユーザーがタスクを完了して緊張がほぐれた状況
          </UTextAnnotationBold>
          <UUl>
            <UListItem>○：タスクが完了しました！お疲れ様でした。</UListItem>
            <UListItem>△：タスクが完了しました。</UListItem>
            <UListItem>☓：タスク完了。</UListItem>
          </UUl>
        </UListItem>
      </UUl>
    </UList>
    <UList>
      <UUl>
        <UListItem>
          <UTextAnnotationBold>
            例：ユーザーがエラーで困り、すぐにでもサポートが必要な状況
          </UTextAnnotationBold>
          <UUl>
            <UListItem>○：タスクが完了しました！お疲れ様でした。</UListItem>
            <UListItem>△：タスクが完了しました。</UListItem>
            <UListItem>☓：タスク完了。</UListItem>
          </UUl>
        </UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PHistory: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="アイデンティティ" title="歴史/沿革" />
    <UHeading level={1} content="2018年" />
    <UList>
      <UUl>
        <UListItem>2月 株式会社サードスコープ設立</UListItem>
      </UUl>
    </UList>
    <UHeading
      level={2}
      content="CI & VIデザインのマイクロサービス開発を中心に黒字スタート"
    />
    <UTextLine
      content={
        <>
          主に中小企業を対象とし、企業ブランドのデザインコンセプトを定義するCI &
          VIデザインと、そこからの
          <br />
          各種CMS(コンテンツ管理システム)開発や、MA(マーケティングオートメーション)などの導入によって売上を形成。
        </>
      }
    />
    <UHeading level={2} content="デザイン事業" />
    <UTextLine
      content={
        <>
          デザイン科学によるアプローチで CI / VI / BI
          といったビジネスにおける影響度の高い分野に特化した手法を模索
        </>
      }
    />
    <UHeading level={2} content="システム開発事業" />
    <UTextLine
      content={
        <>
          CMS / RPA / AI / CRM / EC / MA / BlockChain
          といった幅広い技術のサービス開発に対応
          プロダクトはマイクロサービス化して、技術資産を自社サービス含む他プロジェクトに応用
        </>
      }
    />
    <UHeading level={1} content="2019年" />
    <UList>
      <UUl>
        <UListItem>4月 台湾支社設立に向けての視察</UListItem>
        <UListItem>5月 SpirallyがIT導入補助金対象サービスに認定</UListItem>
        <UListItem>7月 本社移転（渋谷一丁目→神宮前）</UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PInformation: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="基本情報" />
    <UHeading level={1} content="正式名称" />
    <UTextLine
      content={
        <>
          社名を表記する場合は正式名称で行い、特に英語表記では大文字・小文字・スペースの区別に注意して正しく表記してください。日本語表記の推奨書体は「游ゴシック」、英語表記の推奨書体は「Crimson
          Text」になります。
        </>
      }
    />
    <UTextLine
      content={
        <>
          <UTextAnnotationBold>日本語表記：</UTextAnnotationBold>
          株式会社サードスコープ
          <br />
          <UTextAnnotationBold>英語表記：</UTextAnnotationBold>Third Scope Inc.
        </>
      }
    />
    <UHeading level={1} content="所在地・電話" />
    <UHeading level={2} content="東京本社" />
    <UTextLine
      content={
        <>
          〒150-0001 東京都渋谷区神宮前6丁目19-16 U-natura 9F（
          <UTextAnnotationA href="https://www.google.com/maps/place/%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE%E3%82%B5%E3%83%BC%E3%83%89%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%97/@35.6634756,139.7025071,21z/data=!4m8!1m2!2m1!1sU-natura!3m4!1s0x60188b58a8655551:0xfdcb51297405e7b1!8m2!3d35.6634756!4d139.7025071">
            Map
          </UTextAnnotationA>
          ）
          <br />
          03-6820-0114
        </>
      }
    />
    <UTextLine
      content={
        <>
          150-0001 #901 U-natura 9F, 6-19-16 Jingumae, Shibuya, Tokyo
          <br />
          +81-03-6820-0114
        </>
      }
    />
    <UTextLine
      content={
        <>
          同じ番地にビルが2つあるため、郵便物はビル名と会社名を入れてください。
        </>
      }
    />
    <UHeading level={2} content="沖縄営業所" />
    <UTextLine
      content={
        <>
          〒900-0032 沖縄県那覇市松山2-2-13 沖縄日産商事ビル1階（
          <UTextAnnotationA href="https://www.google.com/maps?ll=26.219283,127.681368&z=20&t=m&hl=ja&gl=JP&mapclient=embed&q=%E3%80%92900-0032+%E6%B2%96%E7%B8%84%E7%9C%8C%E9%82%A3%E8%A6%87%E5%B8%82%E6%9D%BE%E5%B1%B1%EF%BC%92%E4%B8%81%E7%9B%AE%EF%BC%92%E2%88%92%EF%BC%91%EF%BC%93">
            Map
          </UTextAnnotationA>
          ）
        </>
      }
    />
  </GuidelineContent>
)

const PDesignRegulation: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="デザイン原則" />
    <UTextLine
      content={
        <>
          デザイン原則は視覚表現されるグラフィックやインターフェイス、文章表現されるコンテンツまで、様々なアウトプットの指標として機能します。この指標は私たちの哲学や思想が反映されたもので、指標を用いた判断によってブレの無い表現へ導くことができます。
          <br />
          特に複数の関係者や、関わる期間が短いメンバーがいる場合に有効です。
        </>
      }
    />
    <UHeading level={1} content="先の先のさらにその先を行く" />
    <UList>
      <UUl>
        <UListItem>
          ユーザーやパートナーが本当に必要だったものを見定める
        </UListItem>
        <UListItem>
          先を見越して考えることで、広い選択肢と高い精度を得る
        </UListItem>
        <UListItem>
          新しい表現、技術、考え方などに前のめりで、都度最適な方法で挑める状態にする
        </UListItem>
      </UUl>
    </UList>
    <UHeading level={1} content="最良のために文脈を意識する" />
    <UList>
      <UUl>
        <UListItem>
          ユーザー、パートナーによって状況や目的が異なるため、個々に向き合って理解する
        </UListItem>
        <UListItem>
          目の前の課題や出来事が思い込みでないか、一度離れたり視点を変えてみる
        </UListItem>
        <UListItem>最終的に与える体験全体を考慮して設計する</UListItem>
      </UUl>
    </UList>
    <UHeading level={1} content="分かりやすく適度に分解する" />
    <UList>
      <UUl>
        <UListItem>
          理解を得るために、伝えたいことを分解し分かりやすくする
        </UListItem>
        <UListItem>
          難しい言葉や操作が本当に必要か、状況や文脈を考える
        </UListItem>
        <UListItem>
          分解する粒度には気を配り、わずか程度に考える余地を残す
        </UListItem>
      </UUl>
    </UList>
    <UHeading level={1} content="いかに価値を提供できるか考える" />
    <UList>
      <UUl>
        <UListItem>良い変化、習慣、文化が芽生えるきっかけを設計する</UListItem>
        <UListItem>
          全体から細部までの見直しと合理化で、クリエイティブな事に時間を使えるようにする
        </UListItem>
        <UListItem>
          少しでも多く、期待を上回るような結果が得られるようにする
        </UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PLogo: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="ロゴ" />
    <UHeading level={1} content="コンセプト" />
    <UTextLine
      content={
        <>
          過去と現在を見つめ、来たる未来の行き先を見極めるための「第三の目」として機能する存在。
          <br />
          シンボルに掲げたマークのモチーフは、過去と現在を見る目であり、それらが重なる事で見える真実を表現しています。
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>
          目を並べる事で、相手の両目を真っ直ぐ見る行為（信頼、誠実、忠実、真剣）を表す
        </UListItem>
        <UListItem>
          目が重なる事で、第三の目として「未来を見る目」「心の目」「神の目」とした機能を果たす
        </UListItem>
      </UUl>
    </UList>

    <UHeading level={1} content="カラー" />
    <UTextLine
      content={
        <>
          信頼や誠実を感じさせる、青系統の色をベースに展開しています。
          <br />
          色はサードスコープブルー、ダブルブルー、ウォーターブルー、ブルーブラックの４種に大別されます。
        </>
      }
    />

    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="TS Water Blue"
        hex="#6bb5d9"
        rgb="R107 G181 B217"
        cmyk="C58 M13 Y9 K0"
        dic="DIC 2180"
      />
      <UColorGridItem
        title="TS 90 Blue"
        hex="#205290"
        rgb="R32 G82 B144"
        cmyk="C100 M78 Y21 K0"
        dic="DIC N893（90%）"
      />
      <UColorGridItem
        title="TS Blue"
        hex="#004587"
        rgb="R0 G69 B135"
        cmyk="C100 M78 Y21 K0"
        dic="DIC N893"
      />
      <UColorGridItem
        title="TS Deep Blue"
        hex="#002742"
        rgb="R0 G39 B66"
        cmyk="C100 M64 Y36 K63"
        dic="DIC N898"
      />
      <UColorGridItem />
      <UColorGridItem />
    </ULayoutGrid>

    <UHeading level={1} content="ロゴの種類" />
    <UTextLine
      content={
        <>
          ロゴを利用する際にはブランドイメージを損なわないように、規定の方法以外での利用はしないでください。
          <br />
          禁止事項の例はロゴの展開パターンすべてに対して適用されます。
        </>
      }
    />
    <UHeading level={2} content="横組み（推奨）" />
    <UHeading level={2} content="縦組み" />
    <UHeading level={2} content="シンボル単体" />
    <UHeading level={1} content="展開パターン" />
    <UTextLine
      content={
        <>
          サードスコープのロゴの展開パターンはカラー、モノクロ、単色の3種類があります。
          <br />
          カラーロゴの利用を推奨し、表示する媒体により制限がある場合は、モノクロロゴや単色ロゴを利用してください。
        </>
      }
    />
    <UHeading level={1} content="アイソレーション" />
    <UTextLine
      content={
        <>
          ロゴの周囲には一定のアイソレーション（保護エリア）を設け、この領域内にいかなる要素も配置してはいけません。
          <br />
          アイソレーションを確保していても、ロゴの印象を損ねるような配置の仕方は出来る限り避けてください。
        </>
      }
    />
    <UHeading level={2} content="アイソレーション例" />
    <UHeading level={2} content="OK/NGパターン" />
    <UHeading level={1} content="使用事例" />
    <UTextLine
      content={
        <>
          紙媒体でロゴを使用する場合はロゴの最小幅が40mm以上、用紙の端から10mm以上離して配置してください。
          <br />
          Web媒体の場合はロゴの最小幅が80px以上になるように配置してください。
        </>
      }
    />
    <UHeading level={2} content="紙媒体にロゴを使用する場合" />
    <UHeading level={2} content="Web媒体に ロゴを使用する場合" />
    <UHeading level={2} content="媒体別ロゴの最小幅・推奨幅" />
    <UTable>
      <UTableRow>
        <UTableCell>媒体</UTableCell>
        <UTableCell>媒体サイズ</UTableCell>
        <UTableCell>最小ロゴ幅</UTableCell>
        <UTableCell>推奨ロゴ幅</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>用紙 A4</UTableCell>
        <UTableCell>297*210mm</UTableCell>
        <UTableCell>40mm</UTableCell>
        <UTableCell>80mm</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>名刺</UTableCell>
        <UTableCell>297*210mm</UTableCell>
        <UTableCell>40mm</UTableCell>
        <UTableCell>80mm</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>デスクトップPC＆ノートPC</UTableCell>
        <UTableCell>
          2560*1440px
          <br />
          1920*1080px
          <br />
          1680*900px
          <br />
          1440*900px
        </UTableCell>
        <UTableCell>80px</UTableCell>
        <UTableCell>200px</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>スマートフォン</UTableCell>
        <UTableCell>750*1134px</UTableCell>
        <UTableCell>80px</UTableCell>
        <UTableCell>200px</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>タブレット</UTableCell>
        <UTableCell>1024*768px</UTableCell>
        <UTableCell>80px</UTableCell>
        <UTableCell>200px</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>スマートフォン</UTableCell>
        <UTableCell>750*1134px</UTableCell>
        <UTableCell>80px</UTableCell>
        <UTableCell>200px</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>動画 4K&Full HD</UTableCell>
        <UTableCell>
          3840*2160px
          <br />
          1920*1080px
        </UTableCell>
        <UTableCell>100px</UTableCell>
        <UTableCell>200px</UTableCell>
      </UTableRow>
      <UTableRow>
        <UTableCell>動画 HD</UTableCell>
        <UTableCell>960*490px</UTableCell>
        <UTableCell>100px</UTableCell>
        <UTableCell>200px</UTableCell>
      </UTableRow>
    </UTable>
    <UHeading level={1} content="背景色との関係" />
    <UTextLine
      content={
        <>
          ロゴの視認性を確保するために、ロゴと背景色の濃度に一定の差を設けてください。
          <br />
          背景が有彩色や写真の場合であっても、下記の表を参考に同等の差がでるようにしてください。
        </>
      }
    />
    <UHeading level={1} content="禁止事項" />
    <UTextLine
      content={
        <>
          ロゴを利用する際にはブランドイメージを損なわないように、規定の方法以外での利用はしないでください。
          <br />
          禁止事項の例はロゴの展開パターンすべてに対して適用されます。
        </>
      }
    />
  </GuidelineContent>
)

const PColor: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="カラー" />
    <UTextLine
      content={
        <>
          カラーはデザイン要素の基礎にあたり、ブランドや製品の一貫した体験に強く影響を及ぼします。
          <br />
          また色には心理的作用があり、色の意味や印象を理解した上で活用することで、より良い体験を与える事ができます。
          <br />
          そのためカラーを扱う場合は、色味や配色の比率などに細心の注意を払いながら使用してください。
        </>
      }
    />
    <UHeading level={1} content="メインパレット" />
    <UTextLine
      content={
        <>
          メインパレットは、ブランドの連想とそれを補助するカラーを扱います。
          <br />
          製品や資料などへ効果的に配色することで、ブランドのイメージカラーの定着へ繋がります。
        </>
      }
    />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="TS Blue"
        hex="#004587"
        rgb="R0 G69 B135"
        cmyk="C100 M49 Y0 K47"
        dic="DIC 578"
      />
      <UColorGridItem
        title="TS Deep Blue"
        hex="#002742"
        rgb="R0 G39 B66"
        cmyk="C100 M41 Y0 K74"
        dic="DIC 409"
      />
      <UColorGridItem
        title="TS Water Blue"
        hex="#6BB5D8"
        rgb="R107 G181 B216"
        cmyk="C50 M16 Y0 K15"
        dic="DIC 2185"
      />
      <UColorGridItem
        title="White"
        hex="#FFFFFF"
        rgb="R255 G255 B255"
        cmyk="C0 M0 Y0 K0"
        dic="DIC 583"
      />
    </ULayoutGrid>
    <UHeading level={1} content="サブパレット" />
    <UTextLine
      content={
        <>
          サブパレットは、メインパレットの機能的な補助をするカラーを扱います。
          <br />
          文字や背景の強調からUIの意味付けまで、幅広く柔軟に使用できます。
        </>
      }
    />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="R300"
        hex="#F44336"
        rgb="R244 G67 B54"
        cmyk="C0 M73 Y78 K4"
      />
      <UColorGridItem
        title="G300"
        hex="#009688"
        rgb="R0 G150 B136"
        cmyk="C100 M0 Y9 K41"
      />
      <UColorGridItem
        title="B300"
        hex="#3F51B5"
        rgb="R63 G81 B181"
        cmyk="C65 M55 Y0 K29"
      />
      <UColorGridItem
        title="Y300"
        hex="#FFC107"
        rgb="R255 G193 B7"
        cmyk="C0 M24 Y97 K0"
      />
    </ULayoutGrid>
    <UHeading level={1} content="拡張パレット" />
    <UTextLine
      content={
        <>
          拡張パレットは、サブパレットのバリエーションを広げたカラーを扱います。
          <br />
          上手く活用することで、効果的な情報整理を実現することが可能になります。
        </>
      }
    />
    <UHeading level={2} content="レッド系" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="R500"
        hex="#B71C1C"
        rgb="R183 G28 B28"
        cmyk="C0 M85 Y85 K28"
      />
      <UColorGridItem
        title="R400"
        hex="#D32F2F"
        rgb="R211 G47 B47"
        cmyk="C0 M78 Y78 K17"
      />
      <UColorGridItem
        title="R300"
        hex="#F44336"
        rgb="R244 G67 B54"
        cmyk="C0 M73 Y78 K4"
      />
      <UColorGridItem
        title="R200"
        hex="#E57373"
        rgb="R229 G115 B115"
        cmyk="C0 M50 Y50 K10"
      />
      <UColorGridItem
        title="R100"
        hex="#FFCDD2"
        rgb="R255 G205 B210"
        cmyk="C0 M20 Y18 K0"
      />
      <UColorGridItem
        title="R50"
        hex="#FFEBEE"
        rgb="R255 G235 B238"
        cmyk="C0 M8 Y7 K0"
      />
    </ULayoutGrid>
    <UHeading level={2} content="グリーン系" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="G500"
        hex="#004D40"
        rgb="R0 G77 B64"
        cmyk="C100 M0 Y17 K70"
      />
      <UColorGridItem
        title="G400"
        hex="#00796B"
        rgb="R0 G121 B107"
        cmyk="C100 M0 Y12 K53"
      />
      <UColorGridItem
        title="G300"
        hex="#009688"
        rgb="R0 G150 B136"
        cmyk="C100 M0 Y9 K41"
      />
      <UColorGridItem
        title="G200"
        hex="#4DB6AC"
        rgb="R77 G182 B172"
        cmyk="C58 M0 Y5 K29"
      />
      <UColorGridItem
        title="G100"
        hex="#B2DFDB"
        rgb="R178 G223 B219"
        cmyk="C20 M0 Y2 K13"
      />
      <UColorGridItem
        title="G50"
        hex="#E0F2F1"
        rgb="R224 G242 B241"
        cmyk="C7 M0 Y0 K5"
      />
    </ULayoutGrid>
    <UHeading level={2} content="ブルー系" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="B500"
        hex="#1A237E"
        rgb="R26 G35 B126"
        cmyk="C79 M72 Y0 K51"
      />
      <UColorGridItem
        title="B400"
        hex="#303F9F"
        rgb="R48 G63 B159"
        cmyk="C70 M60 Y0 K38"
      />
      <UColorGridItem
        title="B300"
        hex="#3F51B5"
        rgb="R63 G81 B181"
        cmyk="C65 M55 Y0 K29"
      />
      <UColorGridItem
        title="B200"
        hex="#7986CB"
        rgb="R121 G134 B203"
        cmyk="C40 M34 Y0 K20"
      />
      <UColorGridItem
        title="B100"
        hex="#C5CAE9"
        rgb="R197 G202 B233"
        cmyk="C15 M13 Y0 K9"
      />
      <UColorGridItem
        title="B500"
        hex="#E8EAF6"
        rgb="R232 G234 B246"
        cmyk="C6 M5 Y0 K4"
      />
    </ULayoutGrid>
    <UHeading level={2} content="イエロー系" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="Y500"
        hex="#FF6F00"
        rgb="R255 G111 B0"
        cmyk="C0 M56 Y100 K0"
      />
      <UColorGridItem
        title="Y400"
        hex="#FFA000"
        rgb="R255 G160 B0"
        cmyk="C0 M37 Y100 K0"
      />
      <UColorGridItem
        title="Y300"
        hex="#FFC107"
        rgb="R255 G193 B7"
        cmyk="C0 M24 Y97 K0"
      />
      <UColorGridItem
        title="Y200"
        hex="#FFD54F"
        rgb="R255 G213 B79"
        cmyk="C0 M16 Y69 K0"
      />
      <UColorGridItem
        title="Y100"
        hex="#FFECB3"
        rgb="R255 G236 B179"
        cmyk="C0 M8 Y7 K0"
      />
      <UColorGridItem
        title="Y50"
        hex="#FFF8E1"
        rgb="R255 G248 B225"
        cmyk="C0 M3 Y12 K0"
      />
    </ULayoutGrid>
    <UHeading level={2} content="ニュートラル系" />
    <ULayoutGrid itemsPerLine={6}>
      <UColorGridItem
        title="N500"
        hex="#404448"
        rgb="R64 G68 B72"
        cmyk="C11 M6 Y0 K72"
      />
      <UColorGridItem
        title="N400"
        hex="#62696F"
        rgb="R98 G105 B111"
        cmyk="C12 M5 Y0 K56"
      />
      <UColorGridItem
        title="N300"
        hex="#868C93"
        rgb="R134 G140 B147"
        cmyk="C9 M5 Y0 K42"
      />
      <UColorGridItem
        title="N200"
        hex="#ABB0B5"
        rgb="R171 G176 B181"
        cmyk="C6 M3 Y0 K29"
      />
      <UColorGridItem
        title="N100"
        hex="#D1D4D6"
        rgb="R209 G212 B214"
        cmyk="C2 M1 Y0 K16"
      />
      <UColorGridItem
        title="N50"
        hex="#F7F7F8"
        rgb="R247 G247 B248"
        cmyk="C0 M0 Y0 K3"
      />
    </ULayoutGrid>
    <UHeading level={1} content="禁止事項" />
    <UTextLine
      content={
        <>
          色味や配色の使用方法を誤ると、一貫性が保たれずブランド体験を損ねる可能性があります。
          <br />
          そのため、下記例のような使用方法は避けてください。
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>
          インクで出力される媒体にRGBカラーを使用する（CMYKに変換される際に意図しない色に変わる可能性があるため）
        </UListItem>
        <UListItem>指定の色と異なる値を使用する</UListItem>
        <UListItem>
          ブランドのイメージとかけ離れた配色をする（意図していない誤った印象を与えるため）
        </UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PTypography: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="タイポグラフィ" />
    <UTextLine
      content={
        <>
          サードスコープで使用している推奨書体で、タイトルや見出しなどデザイン性が必要な箇所に使用してください。
          <br />
          ひらがな・カタカナ・漢字は「游ゴシック」、英字・数字は「CrimsonText」になります。
        </>
      }
    />
    <UHeading level={1} content="書体について" />
    <UHeading level={2} content="游ゴシック" />
    <UTextLine
      content={
        <>
          Windows 10、Mac OS 10.7~のOSに標準で搭載されています。
          <br />
          その他のOSには標準で搭載されていない場合があり、その場合は販売元から購入するなど行ってください。
        </>
      }
    />
    <UHeading level={2} content="Crimson Text" />
    <UTextLine
      content={
        <>
          Google Fontsからダウンロードできます。
          <br />
          <UTextAnnotationA href="https://fonts.google.com/specimen/Crimson+Text">
            https://fonts.google.com/specimen/Crimson+Text
          </UTextAnnotationA>
          <br />
          Google
          FontsのAPIを利用することで、Webフォントとして使用することも可能です。
        </>
      }
    />
  </GuidelineContent>
)

const PIcon: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="アイコン" />
    <UTextLine
      content={
        <>
          アイコンは視覚的に情報を伝えられ、瞬時に意味を理解させる効果があるため、アイコンを使用する媒体の見やすさや使いやすさの向上のために使用されます。
          <br />
          サードスコープでは
          <UTextAnnotationA href="https://fontawesome.com/pro">
            Font Awesome Pro 5
          </UTextAnnotationA>
          のアイコンを使用しています。
          <br />
          種類やバリエーションが豊富で、よく使用されるアイコンはだいたい見つかるため、作業時間の短縮になります。
        </>
      }
    />
    <UList>
      <UUl>
        <UListItem>サービス名：Font Awesome</UListItem>
        <UListItem>
          URL：
          <UTextAnnotationA href="https://fontawesome.com/pro">
            https://fontawesome.com
          </UTextAnnotationA>
        </UListItem>
        <UListItem>ライセンス：Font Awesome Standard</UListItem>
        <UListItem>料金：99ドル/年</UListItem>
        <UListItem>種類：7,000以上</UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PPhotographic: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="フォトグラフィック" />
    <UTextLine
      content={
        <>
          フォトグラフィックは写真の雰囲気をコントロールし、写真から感じられる印象を方向づける事ができるため、ブランドのイメージ醸成の手段の一つとして有効です。
        </>
      }
    />
    <UHeading level={1} content="写真の仕上げについて" />
    <UTextLine
      content={
        <>
          写真を仕上げる際に露出、色味、ボケ、引き寄りや、写っている対象など様々な組み合わせがあります。
          <br />
          サードスコープでは下記をポイントに仕上げているので参考にしてください。
        </>
      }
    />
    <UHeading level={2} content="ポイント" />
    <UList>
      <UUl>
        <UListItem>露出：全体的に抑えた感じ</UListItem>
        <UListItem>色味：全体的に青〜緑がかっていて感触より</UListItem>
        <UListItem>
          コントラスト：真っ黒（白）な部分が出ない程度に弱くしている
        </UListItem>
        <UListItem>シャープ：少しあまい程度</UListItem>
        <UListItem>ノイズ：少しある</UListItem>
        <UListItem>ボケ：少しあるが、極端にぼかしたものを多用しない</UListItem>
        <UListItem>構図：引いた写真が多め</UListItem>
      </UUl>
    </UList>
    <UHeading level={2} content="サンプル" />
    <UTextLine
      content={
        <>
          Adobe Lightroomのプリセットを用意しました。
          <br />
          プリセットをRAW画像に適用するこで、サードスコープのフォトグラフィックスタイルを再現することができます。
        </>
      }
    />
    <UFile
      url=""
      filename="Third Scope Photographic Style Lightroom Presets.zip"
      size="8.4MB"
    />
  </GuidelineContent>
)

const PBuisinessCard: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="名刺" />
    <UHeading level={1} content="デザインについて" />
    <UTextLine
      content={
        <>
          サードスコープのメンバーが自信を持って名乗り、新たなパートナーと信頼関係を構築する第一歩として機能する名刺です。
          <br />
          余白を広めにとった王道なデザインで、上品さや信頼、プロフェッショナルさを感じさせます。
          <br />
          背面はサードスコープのカラーであるブルーを大胆に使用することで、カラーと社名を結びつけて覚えてもらう狙いがあります。
        </>
      }
    />
    <UHeading level={1} content="印刷について" />
    <UTextLine
      content={
        <>
          サードスコープの名刺は『
          <UTextAnnotationA href="https://raksul.com/">
            ラクスル
          </UTextAnnotationA>
          』を利用して印刷しています。
          <br />
          新規メンバーの追加、情報の変更がある場合はテンプレートを使用して入稿データを制作してください。
          <br />
          ラクスル意外の印刷所へ入稿する必要がある場合は、ブランド戦略室へご相談ください。
        </>
      }
    />
    <UHeading level={2} content="入稿条件" />
    <UList>
      <UUl>
        <UListItem>サイズ：通常サイズ（91mm×55mm）</UListItem>
        <UListItem>カラー： 両面カラー</UListItem>
        <UListItem>紙の種類：高級紙（235kg）</UListItem>
        <UListItem>
          印刷方式：オンデマンド（500部以上の注文でオフセット）
        </UListItem>
        <UListItem>参考価格：100枚 / 1,696円（送料込み）</UListItem>
      </UUl>
    </UList>
    <UHeading level={2} content="関連データ" />
    <UFile
      url=""
      filename="サードスコープ 名刺 テンプレート.zip"
      size="13.7MB"
    />
    <UHeading level={2} content="関連データ" />
    <UTextLine
      content={
        <>
          一度入稿したデータは、一定期間ラクスルに保存されています。
          <br />
          以前入稿した印刷内容と同じであれば、ラクスルのWebサイトから入稿不要で追加注文ができます。
        </>
      }
    />
  </GuidelineContent>
)

const PCatalog: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="カタログ" />
    <UHeading level={1} content="デザインについて" />
    <UTextLine
      content={
        <>
          サードスコープのブランドについて、より正確に伝えるために作られたブランドガイドラインです。
          <br />
          ブランドガイドラインを使用することで、コーポレート・アイデンティティ戦略やブランド戦略への理解を深めます。
        </>
      }
    />
    <UHeading level={2} content="配布用データ" />
    <UTextLine
      content={
        <>
          クライアントや関係者にデータとして配布したい場合はPDF版を使用してください。
          <br />
          ただし、誰でもダウンロードできる状態（SNSやアップローダーへの投稿）にするのは避けてください。
          <br />
          家庭・事務用プリンターでの簡易的なプリントには対応していますが、印刷所への入稿には使用しないでください。
        </>
      }
    />
    <UFile
      url=""
      filename="Third Scope ブランドガイドライン v0.5.2.pdf"
      size="200.7MB"
    />
    <UFile
      url=""
      filename="Third Scope ブランドガイドライン v0.5.2 Lite.pdf"
      size="13.7MB"
    />
    <UHeading level={1} content="印刷について" />
    <UTextLine
      content={
        <>
          サードスコープのカタログは『ラクスル』を利用して印刷しています。
          <br />
          <br />
          新規ページの追加、情報の変更がある場合はテンプレートを使用して入稿データを制作してください。
          <br />
          ラクスル意外の印刷所へ入稿する必要がある場合は、ブランド戦略室へご相談ください。
        </>
      }
    />
    <UHeading level={2} content="入稿条件" />
    <UList>
      <UUl>
        <UListItem>綴じ方：無線綴じ冊子、左綴じ</UListItem>
        <UListItem>ページ数：24ページ</UListItem>
        <UListItem>
          表紙の仕様：両面カラー / マット紙 / 最厚手（220kg）、表面加工なし
        </UListItem>
        <UListItem>
          本文の仕様：両面カラー / マット紙 / 少し厚手（110kg）
        </UListItem>
        <UListItem>印刷方式：オンデマンド</UListItem>
        <UListItem>参考価格：20部 / 13,535円（送料込み）</UListItem>
      </UUl>
    </UList>
  </GuidelineContent>
)

const PWeb: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="ブランド" title="カタログ" />
    <UHeading level={1} content="デザインについて" />
    <UTextLine
      content={
        <>
          サードスコープのサービスや活動、魅力を伝え、新たな顧客・人材の獲得を目指します。
          <br />
          Webサイトは外部への接点が一番多いため、優先してコンテンツの充実、クオリティアップを図りましょう。
        </>
      }
    />
    <UTextLine
      content={
        <UTextAnnotationA href="https://third-scope.com">
          https://third-scope.com
        </UTextAnnotationA>
      }
    />
    <UHeading level={2} content="関連データ" />
    <UFile url="" filename="Third Scope Web Design.zip" size="301.8MB" />
    <UTextLine
      content={
        <>
          ソースコードは
          <UTextAnnotationA href="https://github.com/thirdscope/third-scope-wp-theme">
            GitHub
          </UTextAnnotationA>
          を参照してください。
        </>
      }
    />
  </GuidelineContent>
)

const PMediakit: React.FC = () => (
  <GuidelineContent>
    <PageTitle category="マーケティング" title="メディアキット" />
    <UTextLine
      content={
        <>
          報道関係の皆さまがサードスコープの紹介の際にお使いいただけるメディアキットをご用意しました。
          <br />
          各ファイル内に含まれるReadme.txtを読み、使用条件内でご自由にお使いください。
        </>
      }
    />
    <UHeading level={1} content="ロゴ" />
    {/* ここに画像 */}
    <UFile url="" filename="Third Scope Logo 190828.zip" size="2.2MB" />
    <UHeading level={1} content="カバーイメージ" />
    {/* ここに画像 */}
    <UFile url="" filename="Third Scope Logo_190828.zip" size="2.2MB" />
    <UHeading level={1} content="スクリーンショット" />
    {/* ここに画像 */}
    <UFile url="" filename="Third Scope Screen Shot 190828.zip" size="8.4MB" />
  </GuidelineContent>
)
