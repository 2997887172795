import { css } from "foundation/styles/theme"

/*

Orginal Style from ethanschoonover.com/solarized (c) Jeremy Hull <sourdrums@gmail.com>

*/

export const SolarizedDarkCSS = css`
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 18px 40px;
    background: #002b36;
    color: #839496;
  }

  .hljs-comment,
  .hljs-quote {
    color: #586e75;
  }

  /* Solarized Green */
  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-addition {
    color: #859900;
  }

  /* Solarized Cyan */
  .hljs-number,
  .hljs-string,
  .hljs-meta .hljs-meta-string,
  .hljs-literal,
  .hljs-doctag,
  .hljs-regexp {
    color: #2aa198;
  }

  /* Solarized Blue */
  .hljs-title,
  .hljs-section,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class {
    color: #268bd2;
  }

  /* Solarized Yellow */
  .hljs-attribute,
  .hljs-attr,
  .hljs-variable,
  .hljs-template-variable,
  .hljs-class .hljs-title,
  .hljs-type {
    color: #b58900;
  }

  /* Solarized Orange */
  .hljs-symbol,
  .hljs-bullet,
  .hljs-subst,
  .hljs-meta,
  .hljs-meta .hljs-keyword,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-link {
    color: #cb4b16;
  }

  /* Solarized Red */
  .hljs-built_in,
  .hljs-deletion {
    color: #dc322f;
  }

  .hljs-formula {
    background: #073642;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: bold;
  }
`
