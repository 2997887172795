import constate from "constate"
import {
  AuthUser,
  guestUser,
  subscribeAuthChange,
} from "foundation/utils/authenticate"
import { saveAccesslogUserID } from "foundation/utils/ga"
import { useEffect, useState } from "react"

/** 認証状態
 */
export type UserContext = {
  /** サーバ側で認証チェックを終えている */
  checked: boolean
  /** ユーザーデータ */
  user: AuthUser
}

/** ユーザーデータを使用する際のhook
 *
 * @remark 認証前後, ユーザーの種別が変わった時にコンテキストが更新されます。
 */
export const [
  UserContextProvider,
  useUserContext,
  useAuthChecked,
  useAuthenticatedUser,
] = constate(
  () => {
    const [userContext, setUserContext] = useState<UserContext>({
      user: guestUser,
      checked: false,
    })

    useEffect(() => {
      let mouted = true
      let unsub = (async () => {
        if (!mouted) return
        return await subscribeAuthChange((user) => {
          if (!mouted) return
          setUserContext({
            user,
            checked: true,
          })

          if (user.type === "user") {
            try {
              saveAccesslogUserID(user.uid)
            } catch (e) {
              console.error(e)
            }
          }
        })
      })()
      return () => {
        mouted = false
        unsub.then((f) => f?.())
      }
    }, [])

    return userContext
  },
  (v) => v,
  (v) => v.checked,
  (v) => (v.user.type === "user" && v.user) || undefined
)

/** エディターアプリを使用できるか
 *
 * ここでのエディターアプリは、アプリケーション全般をさす。
 * これができないユーザーはログイン中だがプロジェクト読み取り専用など
 */
export const canUseEditor = (ctx: UserContext): boolean => {
  if (!ctx.checked || ctx.user.type !== "user") return false
  const role = ctx.user.role
  return !!role && (role === "user" || role === "user:admin")
}
