import { RefObject, useRef, useEffect } from "react"

/** コンポーネントのマウント状態を返すhook */
export const useMouted = (): RefObject<boolean> => {
  let mouted = useRef(true)
  useEffect(
    () => () => {
      mouted.current = false
    },
    [mouted]
  )
  return mouted
}
