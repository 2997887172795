import styled from "@emotion/styled"
import { ClipBoard } from "foundation/components/clipboard"
import { Tooltip } from "foundation/components/tooltip"
import { getThemed } from "foundation/styles/theme"
import { colors } from "foundation/styles/theme/colors"
import React from "react"
import cn from "classnames"

export type UColorGridItemProps = {
  title?: string
  hex?: string
  rgb?: string
  cmyk?: string
  dic?: string
}

type clickToCopyProps = {
  contents: string
}

const ClickToCopy: React.FC<clickToCopyProps> = (props) => {
  return (
    <Tooltip tooltipLabel="コピーしました" handler="click">
      <ClipBoard
        onCaptureData={() => {
          return props.contents
        }}
      >
        {props.children}
      </ClipBoard>
    </Tooltip>
  )
}

/** カラーグリッドの要素
 *
 * display inline-block で横並びにします
 */
export const UColorGridItem: React.FC<UColorGridItemProps> = ({
  title = "",
  hex = "",
  rgb = "",
  cmyk = "",
  dic = "",
}) => {
  const colors = [hex, rgb, cmyk, dic].filter((color) => color !== "")
  const values = new Array(4)
    .fill("")
    .map((_, i) => (colors[i] ? colors[i] : " "))
  return (
    <UColorGridItemElem color={hex}>
      <div
        className={cn(
          `pallet-color`,
          (hex === "#ffffff" || hex === "#FFFFFF") && "border"
        )}
      />
      {title && <h3 className="pallet-title">{title}</h3>}
      {values.map((value, i) => {
        return value === " " ? (
          <div key={i} className="pallet-code noCopy">
            {value}
          </div>
        ) : (
          <ClickToCopy contents={value} key={i}>
            <div className="pallet-code copy">{value}</div>
          </ClickToCopy>
        )
      })}
    </UColorGridItemElem>
  )
}

const UColorGridItemElem = styled.div<{ color: string }>`
  display: inline-block;
  width: 100%;
  height: 190px;
  font-family: ${(p) => getThemed(p, "fonts.user_sans")};
  cursor: default;

  .pallet-color {
    cursor: grab;
    height: 80px;
    margin-bottom: 13px;
    border-radius: 4px;
    background: ${(props) => (props.color ? props.color : colors.gray1)};
    &.border {
      border: 1px solid ${colors.borderGray};
    }
  }

  .pallet-title {
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    word-break: break-all;
  }

  .pallet-code {
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    height: 14px;
    color: ${colors.gray4};

    &.copy {
      cursor: pointer;

      &:hover {
        color: ${colors.primary};
        background: ${colors.lightPrimary};
      }
    }

    &.noCopy {
      user-select: none;
    }
  }
`
