import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"
import React, { useState, useEffect } from "react"
import { GetProjectTemplateResource } from "context/project-editor/context/template"
import {
  EditorFileOption,
  EditorFileOptionDummy,
} from "@common/domain/editor/EditorDataType"
import { StorageRefGetURL } from "foundation/utils/firebase/storage"
import { assert } from "@common/libs"
import { useIntl } from "react-intl"

export type UFileProps = EditorFileOption & {
  attributes?: any
}

/** ファイルダウンロードボタン */
export const UFile: React.FC<UFileProps> = ({ attributes, ...props }) => {
  const [downloadLink, setDownloadLink] = useState(
    !props.dummy ? (props.url as string) : "#loading"
  )
  const [title, setTitle] = useState<string>()
  const intl = useIntl()
  useEffect(() => {
    let mounted = true
    if (props.type === "DUMMY" || (props.dummy && props.url)) {
      // ダミー
      const option = props as EditorFileOptionDummy
      assert(!!option.url)
      GetProjectTemplateResource(
        option.url.template as any,
        option.url.key
      ).then((s) => mounted && setDownloadLink(s))
    } else if (props.type === "STORAGE") {
      // アップロードした素材
      StorageRefGetURL(props.file).then(
        (s) => mounted && s && setDownloadLink(s)
      )
      if (props.file.contentType) {
        formatFileType(intl.locale, props.file.contentType).then((s) => {
          if (s) {
            setTitle(s)
          }
        })
      }
    } else {
      return
    }
    return () => {
      mounted = false
    }
  }, [intl, props])

  return (
    <UFileElem {...attributes}>
      <a
        className="dl"
        href={downloadLink}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
      >
        <div className="clip">
          <i className="fal fa-paperclip"></i>
        </div>
        <div className="filename">{props.filename}</div>
        <div className="size">{props.size}</div>
      </a>
    </UFileElem>
  )
}

const formatFileType = async (locale: string, contentType: string) => {
  if (!(locale === "ja" || locale === "en")) return
  let messages: typeof import("@common/libs/translations/file_ja.json")
  switch (locale) {
    case "ja":
      messages = (await import("@common/libs/translations/file_ja.json"))
        .default
      break
    case "en":
      messages = (await import("@common/libs/translations/file_en.json"))
        .default
      break
    default:
      return
  }

  return messages.find((d) => {
    if (typeof d.mime === "string") {
      return d.mime === contentType
    } else {
      return d.mime.includes(contentType)
    }
  })?.kind
}

const UFileElem = styled.div`
  margin-top: 20px;
  .dl {
    display: flex;
    border-radius: 4px;
    width: 400px;
    padding: 12px 16px;
    background: ${colors.gray1};
    color: ${colors.base};
    line-height: 24px;
    &:hover {
      color: ${colors.primary};
      .clip {
        color: ${colors.primary};
      }
      .size {
        color: ${colors.primary};
      }
    }
  }
  .clip {
    width: 16px;
    margin: 0 10px 0 4px;
    font-size: 15px;
    font-weight: normal;
    color: #868c93;
  }
  .filename {
    font-size: 15px;
    font-weight: normal;
    width: 300px;
  }
  .size {
    display: block;
    text-align: right;
    width: 65px;
    font-size: 12px;
    font-weight: normal;
    color: ${colors.gray3};
  }
`
