import styled from "@emotion/styled"
import { getThemed, zIndex } from "foundation/styles/theme"

/** サイドバーとガイドラインコンテンツを含むレイアウト */
export const GuidelineLayout = styled.div`
  display: flex;
  font-family: ${(p: any) => getThemed(p as any, "fonts.user_sans")};
`
/** ガイドラインコンテンツのレイアウト用wrapper */
export const GuidelineMain = styled.div({
  paddingLeft: 240,
  width: "100%",
  minWidth: 840 + 240,
})

export const GuidelineSidebar = styled.div({
  position: "fixed",
  zIndex: zIndex.EditorOuterLayout,
  top: 0,
  bottom: 0,
})

/** ガイドラインコンテンツ
 *
 * コンテンツの幅は840pxで固定
 */
export const GuidelineContent = styled.div<{ readOnly?: boolean }>`
  box-sizing: content-box;
  width: 840px;
  padding: 0 32px;
  margin: 0 auto;
`

export const GuidelineLeftEditorSpace = 88 + 8 + 8 + 4 + 4
