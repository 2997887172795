import React from "react"
import { colors } from "foundation/styles/theme/colors"
import { Link } from "react-router-dom"
import { zIndex } from "foundation/styles/theme/zIndex"
import styled from "@emotion/styled"

const FooterElem = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1em;
  padding: 0 40px;
  height: 70px;
  position: relative;
  z-index: ${zIndex.appLayout};
  background: white;
  border-top: 1px ${colors.borderGray} solid;

  small {
    font-size: 14px;
    opacity: 0.6;
    a {
      color: ${colors.base};
    }
  }
`

/** ガイドラインのフッター */
export const GuidelineFooter: React.FC<{}> = (props) => (
  <FooterElem>
    <small>
      <Link to="/">Powered by Spirally.</Link>
    </small>
  </FooterElem>
)
