import styled from "@emotion/styled"
import { UserContext } from "context/auth/hooks/user-context"
import { __ } from "context/i18n/components/I18n"
import { AppDropdown, AppDropdownLink } from "foundation/App/dropdown"
import { Avatar } from "foundation/components/avatar"
import { AuthenticatedUser } from "foundation/utils/authenticate"
import { AuthenticatedUserTeam } from "foundation/utils/teams"
import React from "react"
import { Link } from "react-router-dom"
import { Text } from "rebass"

export const UserMenuIconElem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const LoadingMenu = (
  <UserMenuIconElem>
    <span>...</span>
  </UserMenuIconElem>
)

const GuestMenu = (
  <div>
    <Link to="/login">Login</Link>
  </div>
)

export const UserMenu: React.FC<{ user: UserContext }> = React.memo(
  ({ user: { checked, user } }) => {
    if (!checked) {
      return LoadingMenu
    }
    return user.type === "user" ? <UserMenuAuthorized user={user} /> : GuestMenu
  }
)

let __globalIconLoaded = false

export const UserMenuAuthorized: React.FC<{
  user: AuthenticatedUser
  teams?: AuthenticatedUserTeam[]
}> = ({ user, teams, children }) => {
  // ユーザーアイコンのフェードイン
  const [imageLoaded, setImageLoaded] = React.useState(__globalIconLoaded)
  const handleImageLoaded = React.useCallback(() => {
    __globalIconLoaded = true
    setImageLoaded(true)
  }, [])

  return (
    <AppDropdown
      width={240}
      gapX={8}
      gapY={8}
      fixed
      icon={
        <UserMenuIconElem>
          <Text mx={3} fontSize={2}>
            {user.name}
          </Text>
          <Avatar
            size={4}
            mx={1}
            alt="User Icon"
            src={user.photoURL}
            email={user.email}
            className={imageLoaded ? "loaded" : undefined}
            sx={{
              transition: "opacity 0.25s ease-in-out",
              opacity: 0,
              "&.loaded": {
                opacity: 1,
              },
            }}
            onLoad={handleImageLoaded}
          />
        </UserMenuIconElem>
      }
    >
      {children}
      <AppDropdownLink to="/account">
        {__`account.settings.user`}
      </AppDropdownLink>
      {teams && (
        <>
          <hr />
          <p>{__`teams.title`}</p>
          <ul>
            {teams.map((team) => {
              return (
                <li
                  key={team.key}
                  className={team.current ? "current" : "select"}
                >
                  {team.name}
                  {team.current && <i className="fal fa-cog team-setting"></i>}
                </li>
              )
            })}
          </ul>
          <hr />
        </>
      )}
      <AppDropdownLink to="/logout">
        {__`layout.nav.menu.account_logout`}
      </AppDropdownLink>
    </AppDropdown>
  )
}
