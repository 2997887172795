import styled from "@emotion/styled"
import { Flex, SxProps } from "rebass"
import {
  FlexboxProps,
  height,
  HeightProps,
  maxHeight,
  MaxHeightProps,
  minHeight,
  MinHeightProps,
  SpaceProps,
} from "styled-system"
import { Box } from "./box"

export const Col = styled(Box)`
  overflow: hidden;
`
Col.defaultProps = { ...Col.defaultProps, px: 3, width: 1 / 8 }

export const Row = styled<
  React.FC<
    SxProps &
      HeightProps &
      MinHeightProps &
      MaxHeightProps &
      FlexboxProps &
      SpaceProps
  >
>(Flex)(height, minHeight, maxHeight)
Row.defaultProps = { ...Row.defaultProps, mx: -3 }
