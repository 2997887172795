import React, { useRef, useLayoutEffect } from "react"
import Clipboard from "clipboard"

type ClipboardProps = {
  getClipboardContent?: () => string

  /** データをクリックした時点で用意する場合のイベントハンドラ
   *    事前に計算せずにデータが用意できる場合は`data`を使うが、計算が必要だったり通信をする場合はこれを使う
   */
  onCaptureData: () => string

  /** ツールチップを自前で出す場合とか他の処理をしたい時に使うイベントハンドラ */
  onAfterCopy?: (data: any) => void
  onError?: () => void
}

export const ClipBoard: React.FC<ClipboardProps> = (props) => {
  const childrenRef = useRef<HTMLElement>(null)
  const getClipboardContent = props.onCaptureData
  let handleError = () => {}
  if (props.onError !== undefined) {
    handleError = props.onError
  }

  useLayoutEffect(() => {
    if (!childrenRef.current) return
    if (getClipboardContent() === "") {
      console.error("no content")
      handleError()
      return
    }
    const clipboard = new Clipboard(childrenRef.current, {
      text: getClipboardContent,
    })

    clipboard.on("error", (e) => {
      console.error(e)
      handleError()
    })
    return () => {
      clipboard.destroy()
    }
  }, [getClipboardContent])

  // TODO: childrenのrefを使いたい
  return <span ref={childrenRef}>{props.children}</span>
}
