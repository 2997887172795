import { StorageRef } from "../common"

/** プロジェクト */
export type Project = {
  id: string

  /** 表示用の名前 */
  displayName: string

  /** プロジェクトの概要 */
  description: string

  /** ロゴ */
  logoImage?: StorageRef | null

  /** バナー画像 */
  coverImage?: StorageRef | null

  /** 公開日時
   *
   * この項目を更新するとhookPublishProjectHookによってデータがコピーされます
   */
  publishedAt?: number | null

  /** 公開処理の完了日時 */
  readonly publishCompletedAt?: number | null

  /** 公開URL用のSlug */
  readonly publicSlug?: string

  /* ページ構成 */
  pageTree: ProjectPageTree
}

export type ProjectDraft = Omit<Project, "id">

export const ProjectCollectionID = "projects"

/** プロジェクトページツリー */
export type ProjectPageTree = {
  category_order: string[]
  categories: {
    [key: string]: ProjectPageTreeCategory
  }
}

export type ProjectPageTreeCategory = {
  name: string
  pages: string[]
}

/** ページとしてフラットに表示するカテゴリのキー */
export const RootCategoryKey = "_pages"

/** トップページの固定のキー */
export type TTopPageKey = "top"
export const TopPageKey = "top"

/** チームレベルのパーミッション設定 */
export type ProjectMember = {
  role: "owner" | "member" | "reader"
  enabled: true
}

export const ProjectMemberCollectionID = "members"
