import { useUserContext } from "context/auth/hooks/user-context"
import React, { useMemo } from "react"
import {
  Route,
  RouteComponentProps,
  RouteProps,
  Redirect,
} from "react-router-dom"
import { PageLoading } from "./PageLoading"
import { setNextPath } from "context/auth"
import { UserRole } from "foundation/utils/authenticate"

/** ログイン中のみ見せるページ用のRoute
 * 見せられないページに関してはstateにログイン後のパスを保存するため、同時に複数のRouteがマッチしないようにすること
 */
export const SecretRoute: React.FC<ISecretRouteProps> = ({
  fallbackPath,
  fallback: FallbackArg,
  roles,
  ...routeProps
}) => {
  const Fallback = useMemo(() => {
    const DefaultFallback = (props: ISecretFallbackProps) => {
      if (fallbackPath) {
        const fallbackTo = fallbackPath && fallbackPath(props)
        if (fallbackTo) {
          return <Redirect to={fallbackTo} />
        }
      }

      // readonlyには見せないページ
      if (props.userRole === "readonly") {
        return <></>
      }

      return <Redirect to={defaultFallbackPath(props)} />
    }
    return FallbackArg
      ? (props: ISecretFallbackProps) => (
          <FallbackArg {...props} defaultFallback={DefaultFallback} />
        )
      : DefaultFallback
  }, [FallbackArg, fallbackPath])
  return (
    <SecretBaseComponent
      {...routeProps}
      loading={PageLoading}
      fallback={Fallback}
      roles={roles ?? ["user", "user:admin"]}
    />
  )
}

const defaultFallbackPath = (
  props: ISecretFallbackProps
): "/dashboard/welcome" | "/login" => {
  // 未認証ユーザーはメールアドレスの確認画面が常にフォールバック先
  if (props.userRole === "user:new") {
    return "/dashboard/welcome"
  }
  // ゲストはログイン画面がフォールバック先
  if (props.userType === "guest") {
    setNextPath(
      window.location.pathname,
      window.location.search,
      window.location.hash
    )
  }
  return "/login"
}

type ISecretRouteProps = RouteProps & {
  children?: never
  roles?: userRoles[]

  fallback?: React.FC<ISecretFallbackProps & { defaultFallback: any }>

  fallbackPath?: (props: ISecretFallbackProps) => string | undefined
  newUserRedirect?: boolean

  component: componentType
}

type componentType<P = {}, Q = any> =
  | React.ComponentType<RouteComponentProps<Q> & P>
  | React.ComponentType<P>

const SecretBaseComponent: React.FC<ISecretBaseProps> = ({
  // admin,
  component: Component,
  loading: LoadingElem = NoopComponent,
  fallback: FallbackElem = NoopComponent,
  roles,
  ...routerProps
}) => {
  const { user: auth, checked } = useUserContext()

  const userType = auth.type
  const userRole =
    (auth.type === "user" && (auth.verified ? auth.role : "user:new")) || null

  const invalidRole = !(userRole && roles?.includes(userRole))

  return (
    <Route
      {...routerProps}
      render={(props) => {
        if (!checked) return <LoadingElem {...props} />
        if (invalidRole) {
          return (
            <FallbackElem {...props} userType={userType} userRole={userRole} />
          )
        }
        // if (admin && role !== "admin") return InvalidRoleElem
        if (Component === undefined) throw new Error("use component in Route")
        return <Component {...props} />
      }}
    />
  )
}

const NoopComponent: React.FC<any> = () => null

type ISecretBaseRouteProps = RouteProps & {
  children?: never
  // 読み込み中に表示する
  loading?: componentType
  fallback?: componentType<ISecretFallbackProps>

  roles?: userRoles[]
}

type userRoles = UserRole | "user:new"

type ISecretFallbackProps = {
  userType: "user" | "guest" | "anonymous"
  userRole?: userRoles | null
}

type ISecretBaseProps = ISecretBaseRouteProps & {}
