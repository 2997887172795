import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { Base64 } from "js-base64"

/** クライアントサイド用のfirebase認証トークンを取得する */
export const clientCredentials = () => {
  try {
    const rawToken =
      process.env.FIREBASE_TOKEN || process.env.REACT_APP_FIREBASE_TOKEN || ""
    if (rawToken.length === 0) return undefined
    const decoded = Base64.decode(rawToken)
    return JSON.parse(decoded)
  } catch (e) {
    return undefined
  }
}

export const initializeFirebase = () => {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(clientCredentials())
  }
}
