import { css } from "../../styles/theme/theme"

const baseStyle = {
  radius: "4px",
}
const baseControlStyle = {
  radius: baseStyle.radius,
  borderWidth: "1px",
  height: "2.25em",
  lineHeight: "1.5",
  paddingVertical: [1, 2],
  paddingHorizontal: [2, 3],
}

const ControlMixin = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: ${baseControlStyle.borderWidth} solid transparent;
  border-radius: ${baseControlStyle.radius};
  box-shadow: none;
  display: inline-flex;
  justify-content: flex-start;

  font-size: 1em;
  height: 2.25em;
  line-height: 1.5;

  padding: calc(0.375em - 1px) calc(0.625em - 1px);
  position: relative;
  vertical-align: top;

  &:focus &.is-focused,
  &:active,
  &.is-active {
    outline: none;
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
`

const inputStyle = {
  backgroundColor: "white",
  borderColor: "#dbdbdb",
  radius: baseStyle.radius,
  color: "#363636",
  placeholderColor: "rgba(54,54,54,.3)",
  hoverBorderColor: "#b5b5b5",
  focusBorerColor: "#3273dc",
  focusBoxShadowSize: "0 0 0 0.125em",
  focusBoxShadowColor: "rgba(50,115,220,.25)",
  disabledColor: "#7a7a7a",
  disabledBackgroundColor: "#f5f5f5",
  disabledBorderColor: "#f5f5f5",
  disabledPlaceholderColor: "rgba(122,122,122,.3)",
}

export const InputMixin = css`
  ${ControlMixin}
  background-color: ${inputStyle.backgroundColor};
   border-color: ${inputStyle.borderColor};
   border-radius: ${inputStyle.radius};
   color: ${inputStyle.color};
   ::placeholder {
     color: ${inputStyle.placeholderColor};
   }
   &:hover,
   &.is-hovered {
     border-color: ${inputStyle.hoverBorderColor};
   }
   &:focus,
   &.is-focused,
   &:active,
   &.is-active {
     border-color: ${inputStyle.focusBorerColor};
     box-shadow: ${inputStyle.focusBoxShadowSize} ${inputStyle.focusBoxShadowColor};
    }

  &[disabled],
  fieldset[disabled] & {
    background-color: ${inputStyle.disabledBackgroundColor};
    border-color: ${inputStyle.disabledBorderColor};
    box-shadow: none;
    color: ${inputStyle.disabledColor};
    ::placeholder {
      color: ${inputStyle.disabledPlaceholderColor};
    }
  }
`
