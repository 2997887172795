import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"
import { getThemed } from "foundation/styles/theme"

/** リストブロック全体のマージン */
export const UList = styled.div`
  margin-top: 21px;
`

// リストの共通スタイル
const UListBase = styled("ul")`
  margin-top: 21px;
  line-height: 24px;
  font-size: 15px;
  font-family: ${(p: any) => getThemed(p as any, "fonts.user_sans")};
  list-style: none;
  li {
    position: relative;
    margin-top: 10px;
    margin-left: 30px;
    &::before {
      display: inline-block;
      position: absolute;
    }
    > div {
      margin-left: 0px;
    }
    ul {
      margin-top: 0;
    }
  }
`

/** リスト(ドット) */
export const UUl = styled(UListBase)`
  > li {
    &::before {
      content: "";
      left: -22px;
      top: 8px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: ${colors.base};
    }
  }
`

/** リスト(数字) */
export const UOl = styled(UListBase)`
  counter-reset: item;
  > li {
    &::before {
      counter-increment: item;
      content: counter(item) ".";
      left: -24px;
      top: 1px;
      margin: 0 10px 0 0;
      font-size: 11px;
      font-weight: 900;
    }
  }
`

/** `UList` の要素 */
export const UListItem = styled.li``
