let shared: UpdateManager

export class UpdateManager {
  callback?: (...args: any[]) => any

  static shared() {
    if (!shared) {
      shared = new UpdateManager()
    }
    return shared
  }

  setCallback(clbk: typeof UpdateManager.prototype.callback) {
    this.callback = clbk
  }

  allowUpdateSW() {
    this.callback && this.callback()
  }
}
