import React from "react"
import styled from "@emotion/styled"

export type ULayoutGridElemProps = {
  itemsPerLine: number
  attributes?: any
}

/** グリッド */
export const ULayoutGrid: React.FC<ULayoutGridElemProps> = (props) => {
  return (
    <ULayoutGridElem {...props.attributes} itemsPerLine={props.itemsPerLine}>
      {props.children}
    </ULayoutGridElem>
  )
}

const ULayoutGridElem = styled.div<ULayoutGridElemProps>`
  display: grid;
  margin-top: 28px;
  grid-template-columns: ${(props) =>
    new Array(props.itemsPerLine).fill("1fr").join(" ")};
  grid-gap: 36px 24px;
`
