export const colors = {
  base: "#404448",
  baseA75: "rgba(64, 68, 72, 0.75)",
  white: "#FFF",
  black: "#212529",
  darkGray: "#464646",
  gray: "#666",
  gray1: "#F7F7F8",
  gray2: "#D1D4D6",
  gray3: "#ABB0B5",
  gray4: "#858C93",
  gray5: "#62696F",
  lightGray: "#e6e6e6",
  textGray: "#62696F",
  borderGray: "#E4E6E7",
  link: "#0079D3",

  accent: "#FFDB00",
  accentDarker: "#91CD42",
  accentDark: "#1CB26F",
  mainLighter: "#009185",
  main: "#006C7C",
  mainDarker: "#222",

  primary: "#4D9EFE",
  darkerPrimary: "#3A77BF",
  darkPrimary: "#0D69D1",
  lightPrimary: "rgba(77, 158, 254, 0.07)",
  shadowPrimary: "rgba(77, 158, 254, 0.23)",
  halfPrimary: "rgba(77, 158, 254, 0.6)",
  danger: "#FF6359",
  darkerDanger: "#EE594F",
  darkDanger: "#BF4B43",
  halfDanger: "rgba(255, 99, 89, 0.6)",
  lightDanger: "rgba(255, 99, 89, 0.07)",
  information: "#0DCAD1",
  darkInformation: "#098C91",
  positive: "#0DD176",
  darkPositive: "#099152",
  warning: "#FFE527",
  darkWarning: "#CCB71F",
}

export const activeColors = {
  primary: {
    default: colors.primary,
    active: colors.darkerPrimary,
    hover: colors.darkPrimary,
  },
  danger: {
    default: colors.danger,
    active: colors.darkerDanger,
    hover: colors.darkDanger,
  },
}
