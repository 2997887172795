import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"

/** 引用 */
export const UQuote = styled.blockquote`
  & + blockquote {
    margin-top: 0;
  }
  margin-top: 31px;
  font-size: 14px;
  line-height: 25px;
  color: ${colors.textGray};
  display: flex;
  &::before {
    content: "";
    border-left: 6px solid ${colors.gray1};
    border-radius: 4px;
    margin-right: 16px;
  }
`
