import { useCallback, useState } from "react"
import { Dispatch } from "react"
import { SetStateAction } from "react"

type formValues = { [key: string]: string }

export type ErrorForValues<V> = Partial<{ [key in keyof V]: string }>

export type IuseForm<V, E = ErrorForValues<V>> = {
  /** input[onChange]にbindする関数
   *
   * inputのnameをVのキーに使用します
   */
  handleChange: (event: any) => void

  /** form[onSubmit]にbindする関数
   */
  handleSubmit: (event: any) => void

  /** 現在の値
   *
   * input[value]にセットするフォームの値
   * 初期値は`defaults`と同じ.
   */
  values: V
  /** フォーム外から値を変更するために用意した関数
   */
  setValues: Dispatch<SetStateAction<V>>

  /** バリデーションエラー */
  errors: undefined | Partial<E>
}

/** フォーム用hooks
 */
export const useForm = <
  /** フォームの値型 */
  V extends formValues,
  /** エラーの値型 */
  E = ErrorForValues<V>
>(
  /** デフォルト値 */
  defaults: V,
  /** 保存時のコールバック */
  callback: (values: V) => void,
  /** 保存直前のバリデーション関数
   * エラーがあるフィールドのエラーのみを返す。エラーがなければそのままreturn
   * 指定されない場合はバリデーションは行われない。
   */
  validate?: (values: V) => Partial<E> | void | undefined
): IuseForm<V, E> => {
  const [values, setValues] = useState<V>(defaults)
  const [errors, setErrors] = useState<Partial<E> | undefined>()

  const handleSubmit = useCallback(
    (event) => {
      if (event) event.preventDefault()
      const errors = (validate && validate(values)) || undefined
      setErrors(errors)

      if (!errors || Object.keys(errors!).length === 0) {
        callback(values)
      }
    },
    [callback, validate, values]
  )

  const handleChange = useCallback((event) => {
    event.persist()
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }, [])

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  }
}
