import React from "react"
import { Image, ImageProps } from "./Image"
import MD5 from "md5.js"

/** ユーザーアイコン等を表示するコンポーネント **/
export const Avatar: React.FC<AvatarProps> = React.forwardRef(
  ({ size = defaultSizeIndex, ...props }, ref) => {
    const fallback = props.email
      ? `https://www.gravatar.com/avatar/${new MD5()
          .update(props.email || "")
          .digest("hex")}?s=88&d=mp`
      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=88"
    return (
      <Image
        ref={ref}
        src={props.src ?? fallback}
        fallback={fallback}
        width={sizeMap[size] ?? sizeMap[defaultSizeIndex]}
        height={sizeMap[size] ?? sizeMap[defaultSizeIndex]}
        {...props}
        sx={{
          ...props.sx,
          borderRadius: "100%",
        }}
      />
    )
  }
)

// ページ上部のアイコン36pxを基準としたサイズのバリエーション
const sizeMap = [16, 20, 24, 32, 36, 40, 44, 48]
const defaultSizeIndex = 4

export type AvatarProps = {
  size?: number | string
  src?: ImageProps["src"] | undefined
  email?: string
} & Omit<ImageProps, "size" | "src" | "fallback">
