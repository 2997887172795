export type StorageRef = {
  /** gs://から始まるパス */
  path: string
  contentType?: string

  variants?: {
    [key: string]: StorageRefLeaf | null
  } | null

  metadata?: {
    [key: string]: any
  }

  /** ファイルの種類
   *
   * 画像以外を追加する時は足す
   */
  type: "image" | "file" | "other"
}

export type StorageRefLeaf = Omit<StorageRef, "variants">

export const VariantBhHash = "bh"

export const MetaProgress = "progress"

export const MetaFileSize = "size"

export type StorageRefFB = StorageRef

export const FileMetaStorePath = "store"

export type FileMetaStore = {
  // Firestoreのパス
  path: string
  // Firestoreのフィールド(ドット.区切りでネスト可能)
  field: string
}

export const FileMetaTrigger = "trigger"
