import { translationKeys } from "@common/libs/translation"
import { Word } from "foundation/components/text/word"
import React, { ComponentProps, ComponentPropsWithoutRef, useMemo } from "react"
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedPlural,
  FormattedRelativeTime,
  FormattedTime,
  useIntl,
} from "react-intl"

export interface FormattedMessageProp
  extends ComponentPropsWithoutRef<typeof FormattedMessage> {
  id: translationKeys
}

export const T = FormattedMessage as React.ComponentType<FormattedMessageProp>

/** メッセージを翻訳して文字列として返します
 * - _`translation.id`: 翻訳語文字列としてレンダリングする
 * - _('translation.id'): react-intlのFormattedMessageとしてレンダリングする
 */
export function __(id: TemplateStringsArray, ...keys: any[]): JSX.Element
export function __(
  id: translationKeys,
  options?: ComponentProps<typeof FormattedMessage>
): JSX.Element
export function __(
  id: string | TemplateStringsArray,
  ...options: any[]
): JSX.Element {
  if (typeof id === "string") {
    return (
      <FormattedMessage
        {...options[0]}
        id={id}
        defaultMessage={options[0]?.defaultMessage ?? id}
      />
    )
  }

  let tmplID: string = ""
  if (Array.isArray(options)) {
    for (let s of id) tmplID += s + (options.length ? options.shift() : "")
  } else {
    tmplID = id[0]
  }
  return <FormattedMessage id={tmplID} defaultMessage={tmplID} />
}

export const useTranslation = () => {
  const intl = useIntl()
  const t = useMemo(
    () => (id: translationKeys, ...options: any[]) =>
      intl.formatMessage({ ...options, id: id, defaultMessage: id }),
    [intl]
  )
  return { intl, t }
}

export const TTime = FormattedTime
export const TDate = FormattedDate
export const TNumber = FormattedNumber
export const TPlural = FormattedPlural
export const TRelative = FormattedRelativeTime

/* 分かち書き用 */
export const TDivided: React.FC<FormattedMessageProp> = (props) => {
  const child = (
    <FormattedMessage
      values={{ ...(props.values || {}), br: <br />, "/": <></> }}
      {...props}
    >
      {(...txts) =>
        React.Children.map(txts, (c) => {
          if (typeof c === "string") {
            return <Word>{c}</Word>
          }
          return c
        })
      }
    </FormattedMessage>
  )

  return React.createElement(props.tagName || "span", null, child)
}
