import { __ } from "context/i18n/components/I18n"
import { Box, Button, Row, Text } from "foundation/components"
import {
  Form,
  FormField,
  TextInput,
} from "foundation/components/form/contextual"
import { useMouted } from "foundation/components/hooks/use-mouted"
import { authWithPassword } from "foundation/utils/authenticate"
import React, { useCallback, useState } from "react"
import { Link } from "react-router-dom"

/** ログインフォームコンポーネント */
export const LoginForm = React.memo<{
  defaultEmail?: string
}>(({ ...props }) => {
  const [formState, setFormState] = useState<{
    error: string | undefined
    submitting: boolean
    done?: boolean
  }>({
    error: undefined,
    submitting: false,
  })
  const mouted = useMouted()
  const [email, setEmail] = useState("")

  const handleSubmit = useCallback(
    async (values: any) => {
      // 認証実行
      setFormState({ error: undefined, submitting: true })
      try {
        await authWithPassword(values.email, values.password)
        if (!mouted.current) return
        setFormState({ error: undefined, submitting: false, done: true })
      } catch (e) {
        if (!mouted.current) return
        setFormState({ error: e.message, submitting: false })
      }
    },
    [mouted]
  )

  const handleChange = useCallback(
    (e) => {
      console.log(e, setEmail)
    },
    [setEmail]
  )

  return (
    <Form
      css={{ minWidth: 250 }}
      onSubmit={handleSubmit}
      onChange={handleChange}
      formValues={{
        email: props.defaultEmail || process.env.REACT_APP_DEV_USER || "",
        password: process.env.REACT_APP_DEV_PASS || "",
      }}
      validationSchema={{
        email: { type: "email" },
        password: { type: "string" },
      }}
    >
      <Row mx={0} my={2}>
        <FormField width="100%">
          <Text fontSize={2} fontWeight="bold" mb={1}>
            {__`login.form.email`}
          </Text>
          <TextInput
            tabIndex={1}
            required
            readOnly={formState.submitting}
            placeholder="you@example.com"
            autoComplete="email"
            type="email"
            name="email"
          />
        </FormField>
      </Row>
      <Row mx={0} my={2}>
        <FormField width="100%">
          <Text fontSize={2} fontWeight="bold" mb={1}>
            {__`login.form.password`}
          </Text>
          <Link
            css={{ display: "none" }}
            tabIndex={4}
            to={`/login/reset?email=${email}`}
          >
            <Text fontSize={1}>{__`login.form.forgot`}</Text>
          </Link>
          <TextInput
            tabIndex={2}
            readOnly={formState.submitting}
            required
            autoComplete="current-password"
            type="password"
            name="password"
          />
        </FormField>
      </Row>
      <Row mx={0} my={2}>
        {formState.error ? (
          <Box>
            <Text>{formState.error}</Text>
          </Box>
        ) : null}
      </Row>
      <Row mx={0} justifyContent="center">
        <Button
          tabIndex={3}
          disabled={formState.submitting || formState.done}
          type="submit"
        >
          {__`login.form.login_button`}
        </Button>
      </Row>
    </Form>
  )
})
