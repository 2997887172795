import { StorageRef } from "../../common"

export enum EditorInline {
  /** @deprecated Use `EditorBlock.Img` */
  ImgDoNotUse = "image",
  File = "file",
  LayoutGrid = "layout-grid",
}

export const EditorFileOptionKey = "fileOption"

interface EditorFileOptionCommon {
  filename: string
  size: string
  dummy?: boolean
  url?: string | TemplateURL
}

export interface EditorFileOptionRemote extends EditorFileOptionCommon {
  type?: "REMOTE"
  url: string
  dummy?: false
}

export interface EditorFileOptionDummy extends EditorFileOptionCommon {
  type?: "DUMMY"
  url?: TemplateURL
  dummy: true
}

type TemplateURL = {
  template: string
  key: string
}

export interface EditorFileOptionStorage extends EditorFileOptionCommon {
  type: "STORAGE"
  file: StorageRef
  filename: string
  size: string
}

export type EditorFileOption =
  | EditorFileOptionRemote
  | EditorFileOptionDummy
  | EditorFileOptionStorage
