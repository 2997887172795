import { EditorBlock, ImageOption } from "./Block"

/** dataオブジェクト内でGridDataが格納されている場所のキー */
export const EditorGridBlockOptionKey = "gridData"

/** グリッド用 dataオブジェクト内のオプション */
export type GridData<T extends GridItemType = GridItemType> = {
  itemType: T
  items: GridItemOptionMap[T][]
}

export type GridItemType = EditorBlock.ColorPicker | EditorBlock.BlockLink

export type GridItemOption = ColorPickerOption[] | BlockLinkOption[]

export type GridItemOptionMap = {
  [EditorBlock.ColorPicker]: ColorPickerOption
  [EditorBlock.BlockLink]: BlockLinkOption
}

export type ColorPickerOption = {
  colorTitle: string
  colorHex: string
  colorRgb?: string
  colorCmyk?: string
  colorDic?: string
}
export type BlockLinkOption = {
  linkUrl: string
  linkImageSrc: string | ImageOption
  linkTitle?: string
  linkCategory?: string
}
