import styled from "@emotion/styled"
import { canUseEditor, useUserContext } from "context/auth/hooks/user-context"
import { __ } from "context/i18n"
import { colors } from "foundation/styles/theme/colors"
import { zIndex } from "foundation/styles/theme/zIndex"
import React from "react"
import { Link, NavLink } from "react-router-dom"
import { Logo } from "./Logo"
import { UserMenu } from "./UserMenu"

/** ページ上部のロゴ付きツールバー(ログイン済み) */
export const TopBar: React.FC<{ className?: string }> = (props) => {
  const userContext = useUserContext()
  const loggedin = userContext.user.type !== "guest"
  const editor = canUseEditor(userContext)

  return (
    <HeaderBar className={props.className}>
      <LogoBox>
        <Link to={loggedin ? "/dashboard" : "/"}>
          <Logo />
        </Link>
      </LogoBox>

      {editor && (
        <MiddleBox>
          <MenuItem>
            <NavLink to="/dashboard">
              {__("dashboard.index.projects_header")}
            </NavLink>
          </MenuItem>
        </MiddleBox>
      )}

      {editor && (
        <RightBox>
          <UserMenu user={userContext} />
        </RightBox>
      )}
    </HeaderBar>
  )
}

// TODO: localeに応じてcontextでリンク先を変える

/** ページ上部のロゴ付きツールバー(未ログイン) */
export const GuestTopBar = React.memo<{ className?: string }>((props) => (
  <HeaderBar className={props.className}>
    <LogoBox>
      <Link to="/login">
        <Logo />
      </Link>
      <small>組織の想いを継承し、共有する。</small>
    </LogoBox>
    <MiddleBox />
    <RightBox>
      <Link to="/signup">お申し込み</Link>
    </RightBox>
  </HeaderBar>
))

const HeaderBar = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  padding: 0 20px;
  z-index: ${zIndex.appLayout};
  height: 80px;
  background: white;
  border-bottom: 1px solid ${colors.lightGray};

  display: flex;

  small {
    padding-left: 0.25rem;
    font-size: 0.8rem;
    color: ${colors.darkGray};
  }
`

const LogoBox = styled.div`
  flex: 0 0 auto;
  padding-right: 4px;

  display: flex;
  align-items: center;
  padding-top: 0.2rem;
`

const MiddleBox = styled.div`
  flex: 1 1 auto;
  padding: 0 8px;
  display: flex;
  align-items: center;
`

const RightBox = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
`
