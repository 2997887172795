import {
  deserializeFBTimestamp,
  IFirebaseApp,
  serializeFBTimestamp,
} from "../../libs/firebase"
import { WithID } from "../common"
import { Project } from "./Project.type"

/** プロジェクト(Firebase) */
export type ProjectFB = Omit<
  Project,
  "id" | "publishedAt" | "publishCompletedAt"
> & {
  publishedAt?: IFirebaseApp.firestore.Timestamp | null
  publishCompletedAt?: IFirebaseApp.firestore.Timestamp | null
}
export type NewProjectPayload = Omit<ProjectFB, "id">

export const ProjectConverter: firebase.firestore.FirestoreDataConverter<Project> = {
  toFirestore(model) {
    const updateInput = { ...model }
    delete updateInput.id
    return {
      ...updateInput,
      publishedAt: serializeFBTimestamp(model.publishedAt),
      publishCompletedAt: serializeFBTimestamp(model.publishCompletedAt),
    }
  },

  fromFirestore(snapshot, options) {
    const input = snapshot.data() as ProjectFB
    return {
      ...input,
      id: snapshot.id,
      publishedAt: deserializeFBTimestamp(input.publishedAt),
      publishCompletedAt: deserializeFBTimestamp(input.publishCompletedAt),
    }
  },
}

export const serializeProject = (input: Project): ProjectFB => {
  return ProjectConverter.toFirestore(input) as any
}

export const deserializeProject = (input: WithID<ProjectFB>): Project => {
  return {
    ...input,
    publishedAt: deserializeFBTimestamp(input.publishedAt),
    publishCompletedAt: deserializeFBTimestamp(input.publishCompletedAt),
  }
}
