const SESSION_STORAGE_NEXT_PATH_KEY = "spirally.nextPath"

/** ログイン後のリダイレクトパスを設定する */
export const setNextPath = (path: string, search: string, hash: string) => {
  if (path.match(/^\/log(in|out)/)) {
    sessionStorage.removeItem(SESSION_STORAGE_NEXT_PATH_KEY)
    return
  }
  try {
    sessionStorage.setItem(
      SESSION_STORAGE_NEXT_PATH_KEY,
      [path, search, hash].join("")
    )
  } catch (e) {
    console.error("unable to setNextPath, ", e.message)
  }
}

/** ログイン後のリダイレクトパスを取得する */
export const popNextPath = () => {
  try {
    const item = sessionStorage.getItem(SESSION_STORAGE_NEXT_PATH_KEY)
    sessionStorage.removeItem(SESSION_STORAGE_NEXT_PATH_KEY)
    return item
  } catch (e) {
    console.error("unable to popNextPath, ", e.message)
    return null
  }
}
