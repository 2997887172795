import Validator, { ValidationRule } from "fastest-validator"
import { Box } from "foundation/components"
import { IuseForm, useForm } from "foundation/components/hooks/use-form"
import { throttle } from "lodash"
import React, { createContext, useContext, useMemo } from "react"
import { Text } from "rebass"
import {
  ITextAreaProps,
  ITextInputProps,
  TextArea as BaseTextArea,
  TextInput as BaseTextInput,
} from "../textinput"

export { FormField } from ".."

// TODO: 型ちゃんとする
export type IFormProps<V extends { [key: string]: any } = {}> = {
  formValues: V
  onSubmit: any
  onChange?: any
  ref?: any
  validationSchema: { [key: string]: ValidationRule }
}

const formCtx = createContext<
  { form: IuseForm<any>; schema: { [key: string]: ValidationRule } } | undefined
>(undefined)

/** フォーム
 * コンテキストによってフォームのハンドラーが1箇所にまとまって便利なやつ
 */
export const Form: React.FC<
  IFormProps & {
    className?: string
  }
> = ({ validationSchema, formValues, ref, ...props }) => {
  const validator = useMemo(() => {
    const v = new Validator()
    return v.compile(validationSchema)
  }, [validationSchema])

  const form = useForm(formValues, props.onSubmit, (values) => {
    // バリデーション実行してエラーを格納
    const validity = validator(values)
    if (validity === true) {
      return
    }
    return validity.reduce((vh, v) => {
      vh[v.field] = v.message
      return vh
    }, {})
  })

  const handleFormChange = useMemo(
    () => props.onChange && throttle(props.onChange, 200),
    [props.onChange]
  )

  return (
    <form
      className={props.className}
      ref={ref}
      onSubmit={form.handleSubmit}
      onChange={handleFormChange}
    >
      <formCtx.Provider value={{ form, schema: validationSchema }}>
        {form ? props.children : null}
      </formCtx.Provider>
    </form>
  )
}

export type IFormAccessories = {
  errorBox?: React.ComponentType<{ error?: string }>
}

export const TextInput: React.FC<
  Omit<ITextInputProps, "value"> & {
    name: string
  } & IFormAccessories
> = ({ errorBox: ErrorPresenter = ErrorsBox, ...props }) => {
  const {
    form: {
      values: { [props.name]: value = "" },
      handleChange,
      errors: { [props.name]: error } = {},
    },
  } = useContext(formCtx)!

  return (
    <>
      <BaseTextInput
        {...(props as any)}
        value={value}
        onChange={handleChange}
      />
      {error && <ErrorPresenter error={error} />}
    </>
  )
}

export const TextArea: React.FC<
  ITextAreaProps & {
    name: string
  } & IFormAccessories
> = ({ errorBox: ErrorPresenter = ErrorsBox, ...props }) => {
  const {
    form: {
      values: { [props.name]: value = "" },
      handleChange,
      errors: { [props.name]: error } = {},
    },
  } = useContext(formCtx)!

  return (
    <>
      <BaseTextArea {...(props as any)} value={value} onChange={handleChange} />
      {error && <ErrorPresenter error={error} />}
    </>
  )
}

export const ErrorsBox: Required<IFormAccessories>["errorBox"] = (props) =>
  props.error ? (
    <Box>
      <Text>{props.error}</Text>
    </Box>
  ) : null
