/**
 * オフライン処理用のユーティリティ
 *
 * @remarks 現状オフラインは無効になっています
 */

/** データ取得戦略
 *
 * 各戦略は https://developers.google.com/web/fundamentals/instant-and-offline/offline-cookbook/#stale-while-revalidate これをそのまま使っています。
 */
export enum Strategy {
  // キャッシュされたものを返しつつキャッシュを更新
  StatleWhileRevalidate = "staleWhileRevalidate",

  // ネットワーク優先で失敗したらキャッシュ
  NetworkFirst = "networkFirst",

  // ネットワークからのみ
  NetworkOnly = "networkOnly",

  // アップデートされない
  CacheFirst = "cacheFirst",

  // キャッシュからのみ
  CacheOnly = "cacheOnly",
}
