import React, { useEffect, useState, useCallback } from "react"
import { Box, Image as RImage, ImageProps as RImageProps } from "rebass"
import { omitBy } from "lodash"

/** rebass.ImageにPromise<string>を受け取る機能をつけたもの
 */
export const Image: React.FC<ImageProps> = ({
  src,
  loading,
  fallback,
  onLoad,
  // margin props
  m,
  margin,
  mt,
  marginTop,
  mb,
  marginBottom,
  ml,
  marginLeft,
  mr,
  marginRight,
  my,
  marginY,
  mx,
  marginX,
  ...props
}) => {
  const [srcURL, setSRCURL] = useState<string | undefined>(() =>
    typeof src === "string" ? src : fallback
  )
  const [loaded, setLoaded] = useState(false)
  const aspect = props.displaySize
    ? "ratio" in props.displaySize
      ? props.displaySize.ratio
      : props.displaySize.height / props.displaySize.width
    : undefined

  useEffect(() => {
    if (typeof src === "string") return
    let mounted = true

    setLoaded(false)

    if (!src) {
      setSRCURL(fallback)
      return
    }

    src
      .then((s) => {
        if (!mounted) {
          return
        }
        if (typeof s === "string") {
          setSRCURL(s)
        } else {
          setSRCURL(fallback)
        }
      })
      .catch((e) => {
        if (!mounted) {
          return
        }
        setSRCURL(fallback)
      })
    return () => {
      mounted = false
    }
  }, [src, setSRCURL, fallback])

  const handleLoad = useCallback<Required<ImageProps>["onLoad"]>(
    (e) => {
      onLoad?.(e)
      setLoaded(true)
    },
    [onLoad]
  )

  // FIXME: 冗長なのでなんとかしたい
  // MarginPropsをRImageではなくBoxに渡したい
  const wrapperMarginProps = omitUndefined({
    m,
    margin,
    mt,
    marginTop,
    mb,
    marginBottom,
    ml,
    marginLeft,
    mr,
    marginRight,
    my,
    marginY,
    mx,
    marginX,
  })

  return (
    <Box
      display={props.display ?? "inline-block"}
      width={props.width}
      height={props.height}
      minWidth={props.minWidth}
      minHeight={props.minHeight}
      maxWidth={props.maxWidth}
      maxHeight={props.maxHeight}
      paddingBottom={aspect ? `${aspect * 100}%` : undefined}
      sx={{ position: "relative" }}
      {...wrapperMarginProps}
    >
      {loading && (
        <Box
          display="block"
          overflow="hidden"
          sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <img src={loading} alt="loading" css={aspect && { height: "100%" }} />
        </Box>
      )}
      {srcURL ? (
        <RImage
          {...props}
          onLoad={handleLoad}
          src={srcURL}
          display="block"
          css={
            [
              aspect && {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
              loading && {
                opacity: 0,
                transition: "opacity 400ms ease 0ms",
              },
              loading &&
                loaded && {
                  opacity: 1,
                },
            ] as any
          }
        />
      ) : null}
    </Box>
  )
}

export type ImageProps = Omit<RImageProps, "src" | "css"> & {
  src?: string | Promise<string | undefined | null | void> | null
  fallback?: string
  fadeIn?: boolean

  // bhHashかloadingを表示する場合はdisplaySize必要
  loading?: string
  displaySize?:
    | { width: number; height: number }
    | { /** 縦/横 */ ratio: number }
}

const omitUndefined = (v: any) => omitBy(v, (v) => v === undefined)
