import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Callout } from "foundation/components/Callout"
import { get, getThemed, Theme } from "foundation/styles/theme"
import { colors } from "foundation/styles/theme/colors"
import { zIndex } from "foundation/styles/theme/zIndex"
import React, { ReactElement, useCallback, useRef, useState } from "react"
import { Link, LinkProps } from "react-router-dom"

export type AppDropdownProps = {
  width?: number

  /** アイコンとして表示するコンポーネント */
  icon?: ReactElement

  /** デフォルトのアイコン要素を使う場合のカスタマイズ用props
   *
   * iconが指定されている場合は無視されます
   */
  buttonProps?: Partial<AppDropdownButtonProps>

  /** デフォルトのアイコン要素を使う場合のFont awesome用CSS class
   *
   * `buttonProps.icon` のショートハンド
   */
  iconName?: string

  onRenderButton?: (props: {
    buttonRef: React.RefObject<HTMLElement>
    onClick: any
  }) => JSX.Element

  fixed?: boolean

  gapX?: number
  gapY?: number
}

/** ドロップダウン */
export const AppDropdown: React.FC<AppDropdownProps> = ({
  width = 200,
  buttonProps,
  icon,
  iconName,
  onRenderButton,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const dismiss = useCallback(() => {
    setIsVisible(false)
  }, [setIsVisible])

  // アイコンをクリックしたらメニューをトグルする
  const handleIconClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.defaultPrevented) return
      setIsVisible((visible) => !visible)
    },
    [setIsVisible]
  )

  return (
    <>
      {onRenderButton ? (
        onRenderButton({ buttonRef: buttonRef, onClick: handleIconClick })
      ) : (
        <AppDropdownElem ref={buttonRef} onClick={handleIconClick}>
          {icon ? (
            icon
          ) : (
            <AppDropdownButton
              {...buttonProps}
              icon={buttonProps?.icon ?? iconName}
              size={buttonProps?.size}
            />
          )}
        </AppDropdownElem>
      )}
      {isVisible && (
        <Callout
          target={buttonRef}
          width={width}
          fixed={props.fixed}
          noDismissOnScroll={props.fixed}
          gapY={props.gapY}
          gapX={props.gapX}
          onDismiss={dismiss}
          trapFocus
        >
          <AppDropdownMenu width={width}>
            {React.Children.map(
              React.Children.toArray(props.children),
              (child: any) => {
                // childrenは自由に差し込まれるので、AppDropdownItemのみを取り出し、このコンポーネントのdismissAllを子コンポーネントから呼べるようにpropsを編集して要素をクローンする
                if (
                  child &&
                  typeof child === "object" &&
                  (child.type === linkItemType || child.type === itemType)
                ) {
                  const item = child as React.FunctionComponentElement<
                    AppDropdownItemInternalProps
                  >
                  return React.cloneElement(item, {
                    ...item.props,
                    _dismiss: dismiss,
                  })
                }
                return child
              }
            )}
          </AppDropdownMenu>
        </Callout>
      )}
    </>
  )
}

const AppDropdownElem = styled.div`
  position: relative;
  display: inline-block;
`

type AppDropdownMenuProps = {
  width: number
}

const AppDropdownMenu = styled.div<AppDropdownMenuProps>`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.09);
  padding: 13px 0;
  width: ${(props) => props.width}px;
  z-index: ${zIndex.appPopMenu};

  p {
    padding: 8px 23px;
    color: ${colors.gray3};
    font-size: 13px;
    line-height: 1;
    font-weight: bold;
    font-family: ${(props) => getThemed(props, "fonts.sans")};
  }
  hr {
    height: 1px;
    background: ${colors.borderGray};
    margin: 10px 23px;
  }
  ul {
    font-size: 14px;
  }
  li {
    padding: 4px 23px 4px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 30px;
    line-height: 1.5;
    font-family: ${(props) => getThemed(props, "fonts.sans")};

    &.current {
      font-weight: bold;
    }
    .team-setting {
      display: block;
      font-size: 14px;
      cursor: pointer;
      color: ${colors.gray5};
      padding: 2px;
      opacity: 0.4;
      &:hover {
        color: ${colors.primary};
        opacity: 1;
      }
      &:active {
        color: ${colors.darkPrimary};
        opacity: 1;
      }
    }
    &.select {
      cursor: pointer;
    }
    &.select:hover {
      color: ${colors.primary};
      background: ${colors.lightPrimary};
    }
    &.select:active {
      color: ${colors.darkPrimary};
    }
  }
`

type AppDropdownButtonProps = {
  size?: number
  icon?: string
  circle?: boolean
  color?: string
}

const AppDropdownButton: React.FC<AppDropdownButtonProps> = (props) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: ${props.size ?? 1.15}rem;
        color: ${props.color ?? "inherit"};

        ${props.circle &&
        css`
          width: 32px;
          height: 32px;
          border-radius: 32px;
        `}
      `}
    >
      <i className={props.icon ?? "fal fa-ellipsis-h"} />
    </div>
  )
}

export type AppDropdownItemProps = {
  variant?: "default" | "danger"
}

type AppDropdownItemInternalProps = AppDropdownItemProps & {
  _dismiss?: (e: React.SyntheticEvent<any>) => void
}

/** ドロップダウンの要素 */
export const AppDropdownItem: React.FC<
  JSX.IntrinsicElements["button"] & AppDropdownItemProps
> = ({
  variant = "default",
  _dismiss,
  ...props
}: JSX.IntrinsicElements["button"] & AppDropdownItemInternalProps) => (
  <button
    {...props}
    onClick={(e) => {
      props.onClick && props.onClick(e)
      if (e.defaultPrevented) return
      _dismiss && _dismiss(e)
    }}
    css={dropdownCSS(variant)}
  >
    {props.children}
  </button>
)

/** ドロップダウンの要素(リンク) */
export const AppDropdownLink: React.FC<LinkProps & AppDropdownItemProps> = ({
  variant = "default",
  _dismiss,
  ...props
}: LinkProps & AppDropdownItemInternalProps) => (
  <Link
    {...props}
    onClick={(e) => {
      props.onClick && props.onClick(e)
      if (e.defaultPrevented) return
      _dismiss && _dismiss(e)
    }}
    css={dropdownCSS(variant)}
  />
)

const linkItemType = React.createElement(AppDropdownLink).type
const itemType = React.createElement(AppDropdownItem).type

export const AppDropdownSeparator = styled.hr``

export type IContextMenuProps = AppDropdownProps & {
  items: IContextMenuItem[]
  onItemClick?: (item: IContextMenuItem) => void
}

/** コンテキストメニュー
 * 宣言的に書けるDropdown
 */
export const ContextMenu: React.FC<IContextMenuProps> = ({
  onItemClick,
  items,
  ...props
}) => {
  const onClickItem = useCallback(
    (item: IContextMenuItem) => {
      item.onClick?.(item.key)
      onItemClick && onItemClick(item)
    },
    [onItemClick]
  )

  return (
    <AppDropdown {...props}>
      {items.map(
        (item) =>
          (item.visible !== false && (
            <AppDropdownItem
              key={item.key}
              onClick={onClickItem.bind(undefined, item)}
              disabled={item.disabled}
              variant={item.buttonVariant}
            >
              {item.displayName}
            </AppDropdownItem>
          )) ||
          null
      )}
    </AppDropdown>
  )
}

export type IContextMenuItem = {
  key: string
  displayName: string
  visible?: boolean
  disabled?: boolean
  buttonVariant?: "default" | "danger"
  onClick?: (key: string) => void
}

const dropdownCSS = (variant: "default" | "danger") => (theme: Theme) => {
  return [
    css`
      display: block;
      width: 100%;
      padding: 8px 23px;
      line-height: 1;
      text-align: left;
      border: none;
      background: none;
      white-space: nowrap;
      font-size: 14px;
      font-family: ${get(theme, "fonts.sans")};

      &:hover:not(:disabled) {
        cursor: pointer;
      }

      &:active {
        color: ${colors.darkPrimary};
      }
    `,
    variant === "default"
      ? css`
          color: ${colors.gray5};

          &:disabled {
            color: ${colors.lightGray}!important;
          }
          &:not(:disabled) {
            &:hover {
              color: ${colors.primary};
              background: ${colors.lightPrimary};
            }
          }
        `
      : variant === "danger"
      ? css`
          color: ${colors.danger};
          &:disabled {
            color: ${colors.halfDanger}!important;
          }

          &:not(:disabled) {
            &:hover {
              color: ${colors.danger};
              background: ${colors.lightDanger};
            }
            &:active {
              color: ${colors.darkDanger};
            }
          }
        `
      : null,
  ]
}
