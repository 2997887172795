import { UserContextProvider } from "context/auth/hooks/user-context"
import { useSelector } from "context/entrypoint/store"
import { TopBar } from "context/header"
import { I18nProvider } from "context/i18n"
import { AppLayout } from "context/layout/templates/AppLayout"
import { ThemeProvider } from "emotion-theming"
import { GlobalStyle } from "foundation/styles/theme/css"
import { getTheme } from "foundation/styles/theme/theme"
import React from "react"
import { Provider } from "react-redux"
import { AppRouter } from "../AppRouter"

export const App: React.FC<{
  store: any
}> = (props) => {
  return (
    <Provider store={props.store}>
      <UserContextProvider>
        <I18nProvider>
          <Main />
        </I18nProvider>
      </UserContextProvider>
    </Provider>
  )
}

const Main: React.FC<{}> = React.memo(() => {
  // TODO: 言語が固定のページはIntlを使わないか、現在のロケールを無視する(可能なら)
  const locale = useSelector((s) => s.root.locale)
  const theme = React.useMemo(() => getTheme(locale), [locale])

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <AppRouter layout={AppLayout} header={TopBar} />
      </>
    </ThemeProvider>
  )
})
