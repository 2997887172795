import { EmptyPage, Page, Pages, TopPageKey } from "@common/domain/project"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { assert } from "@common/libs"

export type IPageEditorStore = {
  pages: { [key: string]: Page }
}

const initialState: IPageEditorStore = {
  pages: {},
}

const PageEditorSlice = createSlice({
  name: "projectPage",
  initialState,
  reducers: {
    setPage: (
      state,
      action: PayloadAction<
        | { page: Page; merge?: false }
        | { page: Partial<Page> & Pick<Page, "id">; merge: true }
      >
    ) => {
      if (action.payload.merge) {
        const page = mergePages({
          current: state.pages[action.payload.page.id],
          page: action.payload.page,
        })
        state.pages[action.payload.page.id] = page
      } else {
        state.pages[action.payload.page.id] = action.payload.page
      }
    },
    deletePage: (state, action: PayloadAction<{ id: string }>) => {
      delete state.pages[action.payload.id]
    },
    setPages: (state, action: PayloadAction<{ pages: Pages }>) => {
      state.pages = action.payload.pages

      // NOTE: 本来は作成時にすべてページがあるはずだが、開発中データで無いものが存在するため
      if (
        Object.keys(state.pages).length === 0 ||
        state.pages[TopPageKey] === undefined
      ) {
        state.pages = { ...state.pages, top: EmptyPage(TopPageKey) }
      }
    },
  },
})

export const { setPages, deletePage, setPage } = PageEditorSlice.actions
export default PageEditorSlice.reducer

export const mergePages = (payload: {
  current: Page | undefined
  page: Partial<Page> & Pick<Page, "id">
}) => {
  // 新規ページの時はcurrent === undefined
  const current = payload.current

  // Pageを浅くmergeする
  const displayName = payload.page.displayName ?? current?.displayName
  const content = payload.page.content ?? current?.content
  const coverImage =
    payload.page.coverImage !== undefined
      ? payload.page.coverImage
      : current?.coverImage

  const assets: Page["assets"] = {
    ...current?.assets,
    ...payload.page.assets,
  }

  assert(displayName && content, "displayName and content is needed")
  return {
    id: payload.page.id,
    displayName,
    content,
    coverImage,
    assets,
  }
}
