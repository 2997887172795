import { css as EmotionCSS } from "@emotion/core"
import {
  get,
  Theme as StyledSystemTheme,
  TLengthStyledSystem,
  ObjectOrArray,
} from "styled-system"
import * as CSS from "csstype"
import { ComponentsTheme } from "../../components/interface"
import { colors } from "./colors"
export { css as sx } from "@styled-system/css"
export { get } from "styled-system"

/** ロケールに応じたテーマを取得 */
export const getTheme = (locale: string): Theme => {
  return localedTheme[locale] || defaultTheme
}

// /** テーマとして受け入れるProps */
export interface Theme extends StyledSystemTheme, ComponentsTheme, RebassTheme {
  colors: typeof colors
  locale: string
}

interface RebassTheme {
  variants?: ObjectOrArray<CSS.StandardProperties>
  text?: ObjectOrArray<CSS.StandardProperties>
  buttons?: ObjectOrArray<CSS.StandardProperties>
}

export type Themed<P> = P & { theme?: Theme }

export const getThemed = (themed: any, ...args: TLengthStyledSystem[]) =>
  get(themed.theme as any, ...args)

export const css = EmotionCSS

const fontSizes = [
  "11px",
  "13px",
  "14px",
  "16px",
  "18px",
  "24px",
  "27px",
  "32px",
  "48px",
  "64px",
]

/** デフォルトテーマ */
const defaultTheme: Theme = {
  locale: "",
  colors: colors,
  lineHeights: {
    body: 1.5,
  },
  fontSizes,
  text: {
    heading: {
      fontFeatureSettings: `"kern"`,
      fontFamily: "inherit",
      color: "base",
      fontWeight: "bold",
    },
    title: {
      fontFeatureSettings: `"palt"`,
      fontFamily: "inherit",
      fontWeight: "bold",
      lineHeight: "inherit",
      color: "base",
    },
  },
  // TODO: responsive font-size
  inputSizes: [
    {
      borderRadius: "2px",
      fontSize: fontSizes[1],
    },
    { fontSize: fontSizes[2] },
    { fontSize: fontSizes[3] },
    { fontSize: fontSizes[4] },
  ],
  inputStyles: {
    rounded: {
      paddingLeft: "1em",
      paddingRight: "1em",
      borderRadius: "290486px",
    },
  },
  buttons: {
    icon: {
      border: 0,
      color: "inherit",
      backgroundColor: "inherit",
    },
  },
  shadows: {
    level1: "0 0 4px rgba(0,0,0,.125)",
  },
  fonts: {
    sans:
      'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
    user_sans:
      'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
  },
}

/** 日本語環境用テーマ */
const jaTheme: Theme = {
  ...defaultTheme,
  locale: "ja",
  lineHeights: {
    body: 1.7,
  },
  fonts: {
    // アプリケーションUI用 sans
    sans:
      'BlinkMacSystemFont, -apple-system, "noto-sans-cjk-jp", "Segoe UI", Roboto, "Helvetica Neue", "游ゴシック Medium", "Yu Gothic", YuGothic, YuGothicMedium, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif',

    // コンテンツ表示用 sans
    user_sans: "noto-sans-cjk-jp, sans-serif",
  },
}

const localedTheme: {
  [key: string]: Theme
} = {
  ja: jaTheme,
}
