import styled from "@emotion/styled"
import { css } from "foundation/styles/theme"
import { activeColors, colors } from "foundation/styles/theme/colors"
import React from "react"
import {
  BoxKnownProps,
  Button as RebassButton,
  ButtonProps as RebassButtonProps,
} from "rebass"
import { maxWidth, MaxWidthProps, minWidth, MinWidthProps } from "styled-system"
import { assert } from "@common/libs"

/** 標準のボタン **/
export const Button: React.FC<ButtonProps> = (props) => {
  const { variant, buttonColor, hasNext, hasPrev, icon, ...buttonProps } = props
  return (
    <RebassButton
      {...buttonProps}
      sx={[maxWidth, minWidth]}
      css={css`
        ${buttonCSS(props)}
        ${buttonBaseCSS}
        ${buttonBlockCSS(props)}
      `}
    />
  )
}

const buttonBlockCSS = (props: ButtonProps) =>
  haveVariant(props.variant, "block")
    ? css`
        display: block;
        width: 100%;
      `
    : undefined

const buttonBaseCSS = css`
  border-radius: 26px;
  cursor: pointer;
  line-height: 1em;

  &:focus {
    outline: 0;
    text-decoration: underline;
  }

  &:disabled {
    color: rgba(98, 105, 111, 0.4);
    background: #f7f7f8;
    border: none;
    cursor: no-drop;
    &:hover {
      background: #f7f7f8;
    }
  }
`

const buttonCSS = ({ variant, buttonColor }: ButtonProps) => {
  const outline = haveVariant(variant, "outline")
  const gradient = haveVariant(variant, "gradient")
  const text = haveVariant(variant, "text")

  if (outline && text) {
    return css`
      background-color: inherit;
      color: ${colors.primary};
      border: 1px solid transparent;

      &:hover:not(:disabled) {
        border: 1px solid;
      }
    `
  } else if (outline) {
    return css`
      background-color: transparent;
      border: 1px solid;
      border-color: ${colors.borderGray};
      color: ${colors.textGray};
      color: ${buttonColor ? activeColors[buttonColor].default : "#868C93"};
      border-color: ${buttonColor
        ? activeColors[buttonColor].default
        : "#868C93"};
      &:hover {
        background-color: #f7f7f8;
        border-color: ${buttonColor
          ? activeColors[buttonColor].default
          : "#868C93"};
      }
    `
  } else if (gradient) {
    return css`
      background-color: unset;
      background: linear-gradient(
        90deg,
        #4d9dfd 0%,
        #c28ac2 52.62%,
        #ff6359 100%
      );
      font-weight: bold;
      text-shadow: 0 1px 4px rgba(77, 158, 254, 0.3);
      box-shadow: 0 2px 10px 0 rgba(89, 164, 255, 0.1);
      transition: 0.2s linear opacity;
      color: white;

      &:link,
      &:visited,
      &:hover {
        color: #fff;
      }

      &:hover {
        opacity: 0.65;
        cursor: pointer;
      }
    `
  } else if (text) {
    return css`
      background-color: inherit;
      color: ${colors.primary};

      &:hover {
        text-decoration: underline;
      }
    `
  } else {
    // Use theme default
    return css`
      font-weight: bolder;
      background-color: ${activeColors[buttonColor || "primary"].default};
      &:hover {
        background-color: ${activeColors[buttonColor || "primary"].hover};
      }
      &:hover:focus {
        text-decoration: none;
      }
      &:active {
        background-color: ${activeColors[buttonColor || "primary"].active};
      }
    `
  }
}

const haveVariant = (variant: BoxKnownProps["variant"], key: string) => {
  if (!variant) return false
  // NOTE: サポートしたい
  assert(
    !(!Array.isArray(variant) && typeof variant === "object"),
    "this variant not support Inline variants(https://styled-system.com/variants)"
  )
  if (typeof variant === "string") {
    return variant === key
  }
  return variant && Object.values(variant).includes(key)
}

Button.defaultProps = {
  ...Button.defaultProps,
  py: `${12.13 / 13}em`,
  px: `${32.5 / 13}em`,
  fontSize: 1,
}
Button.displayName = "Button"

// ボタンの他のpropsも受け取る
export type ButtonProps = RebassButtonProps &
  MaxWidthProps &
  MinWidthProps & {
    hasNext?: boolean
    hasPrev?: boolean
    buttonColor?: keyof typeof activeColors
    icon?: string
  }

const GradientButtonStyle = css`
  background-color: unset;
  background: linear-gradient(90deg, #4d9dfd 0%, #c28ac2 52.62%, #ff6359 100%);
  font-weight: bold;
  text-shadow: 0 1px 4px rgba(77, 158, 254, 0.3);
  box-shadow: 0 2px 10px 0 rgba(89, 164, 255, 0.1);
  transition: 0.2s linear opacity;
  color: white;

  &:link,
  &:visited,
  &:hover {
    color: #fff;
  }

  &:hover {
    opacity: 0.65;
    cursor: pointer;
  }
`

/** 目立つボタン **/
export const GradientButton = styled<React.FC<GradientButtonProps>>(
  Button as any
)`
  ${GradientButtonStyle}
`

export type GradientButtonProps = ButtonProps & {}
