import React from "react"
import { Theme, get } from "foundation/styles/theme"
import cn from "classnames"
import tileImage from "./placeholder_tile.png"

export const ImageShimmer: React.FC<
  {
    className?: string

    /** アイコンの名前: Font awesomeのフォント指定を使用できます */
    iconName?: string

    /** アイコンの色 */
    iconColor?: string

    /** アイコンのサイズ
     * デフォルト: 2
     */
    iconSize?: number
    bgColor?: string

    onClick?: React.HTMLAttributes<HTMLDivElement>["onClick"]
    clickable?: boolean

    /** 文字を指定することができます */
    label?: string
    labelPosition?: "bottom" | "right" | "left"
    labelColor?: string

    border?: boolean
  } & (
    | {
        /** アスペクト比 */
        aspect: number
      }
    | { width?: number; height?: number }
  )
> = ({ iconSize = 2, ...props }) => (
  <div
    className={props.className}
    onClick={props.onClick}
    css={(theme: Theme) => [
      {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        ":hover": {
          cursor: props.clickable || props.onClick ? "pointer" : "unset",
        },
        "& .content": {
          display: "flex",
          width: "100%",
          height: "100%",
          padding: iconSize * 11,
          background: props.bgColor ?? "#f6f9fc",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          pointerEvents: "none",
        },
        "& .centered": {
          display: "flex",
          flexDirection:
            props.labelPosition === "right" || props.labelPosition === "left"
              ? "row"
              : "column",
          margin: iconSize * 5,
          alignItems: "center",
          justifyContent: "center",
        },
        "& .icon": {
          width: iconSize * 22,
          height: iconSize * 22,
          fontSize: iconSize * 22,
          margin: iconSize * 5,
          color: props.iconColor ?? get(theme, "colors.gray3"),
        },
        "& .label": {
          margin: iconSize * 5,
          color:
            props.labelColor ?? props.iconColor ?? get(theme, "colors.gray3"),
          lineHeight: `${iconSize * 22}px`,
          height: `${iconSize * 22}px`,
          fontWeight: "bolder",
        },
        "& .label--bottom": {
          marginTop: 0,
        },
        "& .label--right": {
          marginLeft: 0,
        },
        "& .label--left": {
          marginRight: 0,
        },
      },

      "width" in props && {
        width: props.width,
        height: props.height,
      },

      "height" in props && {
        height: props.height,
      },

      "aspect" in props &&
        props.aspect > 0 && {
          ".aspect": {
            display: "block",
            width: "1px",
            marginLeft: "-1px",
            height: 0,
            float: "left",
            paddingTop: `${100 * props.aspect}%`,
          },
          "& .content": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          },
        },
    ]}
  >
    {"aspect" in props && props.aspect > 0 && <span className="aspect"></span>}
    <div className="content">
      <span className="centered">
        <i className={cn("icon", props.iconName ?? "far fa-image")}></i>
        {props.label && (
          <span
            className={cn("label", `label--${props.labelPosition ?? "bottom"}`)}
          >
            {props.label}
          </span>
        )}
      </span>
    </div>
  </div>
)

export const SimpleImageShimmer: React.FC<
  | {
      /** アスペクト比 */
      aspect: number
    }
  | { width?: number; height?: number }
> = (props) => {
  return (
    <div
      css={[
        {
          position: "relative",
          overflow: "hidden",
          width: "100%",
          "& .content": {
            display: "flex",
            width: "100%",
            height: "100%",
            background: "black",
            backgroundImage: `url(${tileImage})`,
            backgroundSize: "50px 50px",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none",
            pointerEvents: "none",
          },
        },
        "height" in props && {
          width: props.width,
          height: props.height,
        },
        "aspect" in props &&
          props.aspect > 0 && {
            ".aspect": {
              display: "block",
              width: "1px",
              marginLeft: "-1px",
              height: 0,
              float: "left",
              paddingTop: `${100 * props.aspect}%`,
            },
            "& .content": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            },
          },
      ]}
    >
      {"aspect" in props && props.aspect > 0 && (
        <span className="aspect"></span>
      )}
      <div className="content"></div>
    </div>
  )
}
