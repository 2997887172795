import { StorageRef } from "@common/domain/common"
import { FirebaseShim } from "@common/libs/firebase"
import cache from "memory-cache"
/** StoargeRefからダウンロード可能なURLを発行します。
 * - FirebaseShimに依存
 */
export const StorageRefGetURL = async (
  ref: StorageRef,
  /** 取得するvariantのキー, 未指定で元ファイル */
  variant?: string,
  options?: {
    /** Firebaseのインスタンス */
    app?: typeof FirebaseShim["app"]
    /** メモリ上に結果をキャッシュしない */
    noCache?: boolean
  }
): Promise<string | void> => {
  const path = variant ? ref.variants?.[variant]?.path : ref.path
  if (!path) return
  const useCache = !options?.noCache

  const cached = useCache && cacheGetImagePath(path)
  if (cached) return cached

  const getApp = options?.app ?? FirebaseShim.app
  return await getApp()
    .storage()
    .refFromURL(path)
    .getDownloadURL()
    .then((v) => {
      useCache && cachePutImagePath(path, v)
      return v
    })
}
const cacheGetImagePath = (path: string): string | null =>
  cache.get(`storageRef:path_${path}`)
const cachePutImagePath = (path: string, url: string): string =>
  cache.put(`storageRef:path_${path}`, url)
