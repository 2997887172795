import {
  FileMetaStore,
  FileMetaStorePath,
  FileMetaTrigger,
  MetaProgress,
  StorageRef,
  VariantBhHash,
} from "@common/domain/common"
import {
  HeaderImagePath,
  PagePath,
  ProjectPath,
  ContentImagePath,
  ContentFilePath,
} from "@common/domain/project"
import { ConfigurationError, ValidationError } from "@common/libs"
import { FirebaseShim } from "@common/libs/firebase"
import { AppThunk } from "context/entrypoint/store"
import { ShowAppNotice } from "foundation/App/notice"
import { encodeBlurHashParams, encodeImage } from "foundation/utils/blurhash"
import produce from "immer"
import { ulid } from "ulid"
import { setPage } from "../pageEditorSlice"
import { updateProjectThumbnailAction } from "../projectAction"
import firebase from "firebase/app"

/** ファイル選択ダイアログを表示してヘッダー画像をアップロードします */
export const selectAndUploadHeaderImageAction = (payload: {
  pageID: string
  uid: string
}): AppThunk<Promise<any>> => async (dispatch, getState) => {
  const s = getState()
  const bucket = s.root.resources.userContentBucket
  const currentProject = s.editor.project
  if (!currentProject.project) return
  if (!bucket) {
    throw new ConfigurationError(
      "REACT_APP_STORAGE_USER_BUCKETを環境変数に設定してください"
    )
  }
  const { teamID, id } = currentProject.project
  const app = FirebaseShim.app()
  const store = app.firestore()
  const storage = app.storage("gs://" + bucket).ref()

  // ファイル選択
  const selected = await selectFile({ accept: "image/*" })
  if (!selected) return
  const { file, extName } = selected

  const pageAssetBackup = getState().editor.projectPage.pages[payload.pageID]
    .assets

  const fileNameBase = ulid()
  const fileName = `${fileNameBase}${extName}`

  try {
    const storageRef = await uploadImageResource({
      file,
      uid: payload.uid,

      target: HeaderImagePath(storage, {
        teamID: currentProject.project.teamID,
        projectID: currentProject.project.id,
        revisionID: "HEAD", // TODO: 履歴ができたら変更する
        pageID: payload.pageID,
      }).child(fileName),

      // 変換形式とパスの保存先をFileのメタデータとして指定
      fileMetaTrigger: "coverImage",
      fileMetaStorePath: [
        {
          path: PagePath(
            app.firestore(),
            currentProject.project.teamID,
            currentProject.project.id,
            payload.pageID
          ).path,
          field: `assets.coverImage`,
        },
        {
          path: ProjectPath(
            app.firestore(),
            currentProject.project.teamID,
            currentProject.project.id
          ).path,
          field: `coverImage`,
        },
      ],
      onProgress: async (msg) => {
        switch (msg.type) {
          case "PROGRESS":
            dispatch(
              setPage({
                merge: true,
                page: {
                  id: payload.pageID,
                  assets: {
                    coverImage: msg.ref,
                  },
                },
              })
            )
            break
        }
      },
    })

    await Promise.all([
      // NOTE: Actionが面倒なので直接更新
      PagePath(store, teamID, id, payload.pageID).update(
        "assets.coverImage",
        storageRef
      ),
      dispatch(
        updateProjectThumbnailAction({
          id,
          teamID,
          ref: storageRef,
        })
      ),
    ])

    console.log("uploaded data persisted")
  } catch (e) {
    console.error(e)
    ShowAppNotice("アップロードに失敗しました。", "danger") // 危険じゃないよネガティブだよ
    // Restore backup
    dispatch(
      setPage({
        merge: true,
        page: {
          id: payload.pageID,
          assets: pageAssetBackup,
        },
      })
    )
  }
}

/** ファイル選択ダイアログを表示してヘッダー画像をアップロードします */
export const selectAndUploadEditorImage = async (payload: {
  path: [string, string, string] // team, project, page
}) => {
  const bucket = process.env.REACT_APP_STORAGE_USER_BUCKET
  if (!bucket) {
    throw new ConfigurationError(
      "REACT_APP_STORAGE_USER_BUCKETを環境変数に設定してください"
    )
  }
  const [teamID, projectID, pageID] = payload.path
  const app = FirebaseShim.app()
  const uid = app.auth().currentUser?.uid!
  const storage = app.storage("gs://" + bucket).ref()

  // ファイル選択
  const selected = await selectFile({ accept: "image/*" })
  if (!selected) return
  const { file, extName } = selected

  const imageID = ulid()
  const fileNameBase = imageID
  const fileName = `${fileNameBase}${extName}`
  const storageRef = await uploadImageResource({
    file,
    uid,

    target: ContentImagePath(storage, {
      teamID,
      projectID,
      revisionID: "HEAD", // TODO: 履歴ができたら変更する
      pageID: pageID,
    }).child(fileName),

    onProgress: async (msg) => {
      console.log(msg)
    },
  })
  return storageRef
}

/** ファイル選択ダイアログを表示してファイルをアップロードします
 *
 * ファイルが大きくアップロードに時間がかかることを想定して、コールバックでタスク中の状態を確認できます。
 */
export const selectAndUploadEditorFile = async (
  payload: {
    path: [string, string, string] // team, project, page
  },
  onFile?: (file: File, ext: string | null) => null | void,
  onProgress?: (progress: number) => void
) => {
  const bucket = process.env.REACT_APP_STORAGE_USER_BUCKET
  if (!bucket) {
    throw new ConfigurationError(
      "REACT_APP_STORAGE_USER_BUCKETを環境変数に設定してください"
    )
  }
  const [teamID, projectID, pageID] = payload.path
  const app = FirebaseShim.app()
  const uid = app.auth().currentUser?.uid!
  const storage = app.storage("gs://" + bucket).ref()

  // ファイル選択
  const selected = await selectFile({ accept: "*", noExtFromFileType: true })
  if (!selected) return
  const { file, extName } = selected

  if (onFile?.(file, extName) === null) return null

  if (file.size > 5e7) {
    throw new ValidationError(
      "invalid file size",
      "common.form.invalid_filesize_50MB"
    )
  }

  const fileID = ulid()
  const fileNameBase = fileID
  const fileName = extName ? `${fileNameBase}${extName}` : fileNameBase
  onProgress?.(0)
  const storageRef = await uploadFileResource({
    file,
    uid,

    target: ContentFilePath(storage, {
      teamID,
      projectID,
      revisionID: "HEAD", // TODO: 履歴ができたら変更する
      pageID: pageID,
    }).child(fileName),

    onProgress: async (msg) => {
      console.log(msg)
      onProgress?.(msg.progress)
    },
  })
  return { storageRef, size: file.size, filename: file.name }
}

async function uploadImageResource({
  file,
  uid,
  generateBh = true,
  target,
  fileMetaTrigger,
  fileMetaStorePath,
  onProgress,
}: {
  // Resource and context
  file: File
  uid: string

  // Pre processing
  generateBh?: boolean

  // Target
  target: firebase.storage.Reference

  // Post processing
  fileMetaStorePath?: FileMetaStore[]
  fileMetaTrigger?: string

  // callback
  onProgress?: (p: { type: "PROGRESS"; ref: StorageRef }) => void
}) {
  const image = await fileToImage(file)

  // blurhash生成
  let bhData:
    | { bh?: string | undefined; width: number; height: number }
    | undefined
  if (generateBh) {
    try {
      bhData = await encodeImage(image.data)
    } catch (err) {
      console.warn(err)
    }
  }

  // アップロード
  // 画像データや保存後の処理に必要なメタデータを含めてファイルをアップロードする
  let fileCustomMeta: { [key: string]: string } = {
    uid: uid,
    width: image.width.toString(),
    height: image.height.toString(),
  }
  if (fileMetaTrigger) fileCustomMeta[FileMetaTrigger] = fileMetaTrigger
  if (fileMetaStorePath)
    fileCustomMeta[FileMetaStorePath] = JSON.stringify(fileMetaStorePath)
  const uploadTask = target.put(file, {
    customMetadata: fileCustomMeta,
  })

  // Firestoreに保存するファイルの参照と進捗報告
  let fileMetaRef: StorageRef = {
    type: "image",
    path: target.toString(),
    contentType: file.type,
    variants: {},
    metadata: {
      // 進捗
      [MetaProgress]: 0.0,
      // 画像用メタデータ
      width: image.width,
      height: image.height,
    },
  }

  if (bhData?.bh) {
    fileMetaRef.variants![VariantBhHash] = {
      type: "image",
      path: encodeBlurHashParams({ ...bhData, bh: bhData.bh! }),
      metadata: {
        width: bhData.width,
        height: bhData.height,
      },
    }
  }

  if (onProgress) {
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes
      console.log("ファイルアップロード進捗:", progress)
      if (progress < 1) {
        onProgress({
          type: "PROGRESS",
          ref: produce(fileMetaRef, (v) => {
            v.metadata![MetaProgress] = progress
          }),
        })
      }

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break
      }
    })
  }

  await uploadTask
  const doneFileMetaRef = produce(fileMetaRef, (v) => {
    v.metadata![MetaProgress] = 1
  })
  onProgress?.({
    type: "PROGRESS",
    ref: doneFileMetaRef,
  })
  return doneFileMetaRef
}

async function uploadFileResource({
  file,
  uid,
  target,
  onProgress,
}: {
  // Resource and context
  file: File
  uid: string

  // Target
  target: firebase.storage.Reference

  // callback
  onProgress?: (p: {
    type: "PROGRESS"
    ref: StorageRef
    progress: number
  }) => void
}) {
  // アップロード
  // 画像データや保存後の処理に必要なメタデータを含めてファイルをアップロードする
  let fileCustomMeta: { [key: string]: string } = {
    uid: uid,
  }
  const uploadTask = target.put(file, {
    customMetadata: fileCustomMeta,
  })

  // Firestoreに保存するファイルの参照と進捗報告
  let fileMetaRef: StorageRef = {
    type: "file",
    path: target.toString(),
    contentType: file.type,
    variants: {},
    metadata: {
      // 進捗
      [MetaProgress]: 0.0,
    },
  }

  if (onProgress) {
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes
      console.log("ファイルアップロード進捗:", progress)
      if (progress < 1) {
        onProgress({
          type: "PROGRESS",
          progress: progress,
          ref: produce(fileMetaRef, (v) => {
            v.metadata![MetaProgress] = progress
          }),
        })
      }

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break
      }
    })
  }

  await uploadTask
  const doneFileMetaRef = produce(fileMetaRef, (v) => {
    v.metadata![MetaProgress] = 1
  })
  onProgress?.({
    type: "PROGRESS",
    progress: 1,
    ref: doneFileMetaRef,
  })
  return doneFileMetaRef
}

const extNameFromFileType = (fileType: string) => {
  switch (fileType) {
    case "image/jpeg":
      return ".jpeg"
    case "image/gif":
      return ".gif"
    case "image/png":
      return ".png"
    default:
      throw new ValidationError(
        "invalid file type",
        "common.form.invalid_filetype"
      )
  }
}

/** ブラウザネイティブのファイル選択ダイアログを表示してファイルを選択します */
async function selectFile<
  M extends boolean | undefined = undefined,
  NE extends boolean | undefined = undefined,
  /** noExtFromFileType = trueの場合はextNameがnullになりうる */
  EXT = NE extends true ? string | null : string,
  /** multiple = trueの場合は配列を返す */
  RT = M extends true
    ? { file: File; extName: EXT }[]
    : { file: File; extName: EXT } | undefined
>({
  multiple = false,
  noExtFromFileType = false,
  accept,
}: {
  accept: string
  noExtFromFileType?: NE
  multiple?: M
}): Promise<RT | undefined> {
  const files: FileList | null = await new Promise((done) => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = accept
    input.multiple = multiple
    input.onchange = (evt: Event) =>
      done((evt?.target as HTMLInputElement).files)
    input.click()
  })
  if (!files) return
  if (!multiple && files.length !== 1) return

  let ret: { file: File; extName: string | null }[] = []
  for (let index = 0; index < files.length; index++) {
    const file = files.item(index)
    if (!file) continue
    const extName = !noExtFromFileType
      ? extNameFromFileType(file.type)
      : file.name.split(".").pop() ?? null
    ret.push({ file, extName })
  }
  return (multiple ? ret : ret[0]) as any
}

async function fileToImage(file: File) {
  // 画像を読み込む
  return await new Promise<{
    data: HTMLImageElement
    width: number
    height: number
  }>((done, fail) => {
    const image = new Image()
    image.onload = function () {
      done({
        data: image,
        width: image.naturalWidth,
        height: image.naturalHeight,
      })
    }
    image.src = URL.createObjectURL(file)
  })
}
