import { FocusTrap, ScrollTrap } from "foundation/components/Trap"
import { Layer } from "foundation/components/Layer"
import { zIndex } from "foundation/styles/theme"
import React, {
  useCallback,
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
} from "react"

export type Target = React.RefObject<Element> | string | IRect

/** 通常のコンテンツに重なってユーザーの注意を引くコンポーネントを表示する
 *
 */
export const Callout: React.FC<{
  /** Calloutを表示する場所の基準点 */
  target?: Target

  /** targetの上にかぶさるように表示する */
  coverTarget?: boolean

  /** 他の場所にフォーカスできないようにする。アラート等で使うことができる */
  trapFocus?: boolean
  /** スクロールできないようにする */
  trapScroll?: boolean

  /** スクロールで閉じる */
  noDismissOnScroll?: boolean
  /** ウィンドウリサイズで閉じる */
  noDismissOnResize?: boolean

  onDismiss?: (e?: any) => void

  className?: string
  width?: number
  maxWidth?: number
  fixed?: boolean
  gapX?: number
  gapY?: number

  hidden?: boolean
}> = ({
  target,
  hidden,
  fixed,
  coverTarget,
  onDismiss,
  noDismissOnScroll,
  noDismissOnResize,
  trapFocus,
  trapScroll,
  ...props
}) => {
  const [targetRect, setTargetRect] = useState<IRects>()
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (hidden || !target) return

    let targetRect: IRect | null
    if (typeof target === "string") {
      targetRect =
        document.querySelector(target)?.getBoundingClientRect() ?? null
    } else if ("current" in target) {
      targetRect = target.current?.getBoundingClientRect() ?? null
    } else {
      targetRect = target
    }
    if (!targetRect) return

    setTargetRect({
      clientRect: targetRect,
      documentRect: getDocumentRect(targetRect),
      window: {
        top: window.pageYOffset - document.documentElement.clientTop,
        left: window.pageXOffset - document.documentElement.clientLeft,
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }, [hidden, target])

  // 中身以外をクリックしたらメニューを閉じる
  // FIXME: フォーカスでの管理に変更する
  useEffect(() => {
    if (hidden) return
    const handler = (e: MouseEvent) => {
      if (!onDismiss) return
      if (!ref.current) return
      if (e.defaultPrevented) return
      e.preventDefault()

      if (!ref.current.contains(e.target as any)) {
        onDismiss()
      }
    }
    document.addEventListener("click", handler)
    return () => document.removeEventListener("click", handler)
  }, [hidden, onDismiss])

  // スクロール
  useEffect(() => {
    if (hidden) return
    if (!onDismiss) return
    if (noDismissOnScroll) return
    if (trapScroll) return

    const h = function (ev: Event) {
      onDismiss()
      document.removeEventListener("scroll", h)
    }
    document.addEventListener("scroll", h)
    return () => document.removeEventListener("scroll", h)
  }, [hidden, noDismissOnScroll, onDismiss, trapScroll])

  // リサイズ
  useEffect(() => {
    if (hidden) return
    if (!onDismiss) return
    if (noDismissOnResize) return

    const h = function () {
      onDismiss()
      window.removeEventListener("resize", onDismiss)
    }
    window.addEventListener("resize", h)
    return () => window.removeEventListener("resize", h)
  }, [hidden, noDismissOnResize, onDismiss])

  const styles = useCallback(() => {
    if (!targetRect) return null
    const selfHeight = ref.current?.clientHeight ?? 0
    const selfWidth = props.maxWidth ?? props.width ?? 240
    const targetHeight = targetRect.clientRect.height
    const targetWidth = targetRect.clientRect.width

    // 右に表示するようではみ出るならleftMode
    const leftMode =
      targetRect.clientRect.left + selfWidth > targetRect.window.width
    // 下に表示するようではみ出るならtopMode
    const topMode =
      targetRect.clientRect.top + targetRect.clientRect.height + selfHeight >
      targetRect.window.height

    const gapY = (topMode ? -1 : 1) * (props.gapY ?? 0)
    const gapX = (leftMode ? -1 : 1) * (props.gapX ?? 0)

    // targetの上から表示位置までの距離
    const heightDiff = topMode ? -selfHeight : coverTarget ? 0 : targetHeight

    // targetの左端から表示位置までの距離
    const widthDiff = leftMode ? targetWidth - selfWidth : 0

    // 表示場所の基準に使用するrect
    const baseRect = fixed ? targetRect.clientRect : targetRect.documentRect

    return {
      position: fixed ? "fixed" : "absolute",
      top: `${baseRect.top + heightDiff + gapY}px`,
      left: `${baseRect.left + widthDiff + gapX}px`,
      zIndex: zIndex.appPopMenu,
    }
  }, [
    coverTarget,
    fixed,
    props.gapX,
    props.gapY,
    props.maxWidth,
    props.width,
    targetRect,
  ])

  let content = (
    <div ref={ref} css={styles as any} className={props.className}>
      {props.children}
    </div>
  )

  content = trapFocus ? <FocusTrap>{content}</FocusTrap> : content
  content = trapScroll ? <ScrollTrap>{content}</ScrollTrap> : content
  return <Layer>{content}</Layer>
}

type IRects = {
  clientRect: IRect
  documentRect: IRect
  window: IRect
}

type IRect = {
  left: number
  top: number
  width: number
  height: number
}

const getDocumentRect = (r: IRect) => {
  return {
    top: r.top + window.pageYOffset - document.documentElement.clientTop,
    left: r.left + window.pageXOffset - document.documentElement.clientLeft,
    width: r.width,
    height: r.height,
  }
}
