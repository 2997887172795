import React from "react"
import styled from "@emotion/styled"
import { colors } from "foundation/styles/theme/colors"
import { Flex, Image } from "rebass"

/** `BookmarkCard`のProps */
export type BookmarkCardProps = {
  // タイトル
  title: string

  // 説明文
  desc: string

  // リンク先部分に表示される文字列(通常はドメイン)
  linkDomain: string

  // リンク先URL
  linkTo: string

  // サムネイル画像のURL(常に112x112pxで表示されます)
  imgSrc: string
}

/** ブックマークカードコンポーネント
 *
 * リンクとサムネイル、説明文、ドメインを表示します
 */
export const BookmarkCard: React.FC<BookmarkCardProps> = (props) => (
  <BookmarkCardBox as="a" href={props.linkTo}>
    <BookmarkThumbImage src={props.imgSrc} alt={props.title} />
    <BookmarkMain flexDirection={"column"}>
      <BookmarkTitle>{props.title}</BookmarkTitle>
      <BookmarkDesc>{props.desc}</BookmarkDesc>
      <BookmarkLinkDesc>{props.linkDomain}</BookmarkLinkDesc>
    </BookmarkMain>
  </BookmarkCardBox>
)

const BookmarkTitle = styled.h2`
  font-weight: bold;
  line-height: 22px;
  font-size: 15px;
`

const BookmarkThumbImage = styled(Image)`
  width: 112px;
  height: 112px;
  min-width: auto;
  max-width: auto;
  border-radius: 4px;
`

const BookmarkMain = styled(Flex)``

const BookmarkDesc = styled.p`
  color: ${colors.base};
  font-size: 12px;
`

const BookmarkLinkDesc = styled.p`
  font-size: 12px;
  color: ${colors.gray3};
  margin-top: auto; /* コンテナ下部に表示 */
`

const BookmarkCardBox = styled(Flex)`
  border: solid 2px #eeeeee;
  border-radius: 4px;
  color: ${colors.base};
  padding: 17px 8.5px;

  ${BookmarkThumbImage},${BookmarkMain} {
    margin: 0 8.5px;
  }
`
