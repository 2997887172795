import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

export const Scroll: React.FC = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    //ページ遷移したら先頭までスクロール
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
