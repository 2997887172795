import styled from "@emotion/styled"
import { getThemed } from "foundation/styles/theme"
import React from "react"
import { UImage } from "./UImage"
import { ImageOption } from "@common/domain/editor/EditorDataType"

export const UBlockLinkEdit = styled.div`
  display: inline-block;
`

export type UBlockLinkProps = {
  link: string
  imageSrc: string | ImageOption
  onClick?: (src: string) => void
  alt?: string
  category: string
  title: string
}

/* 画像リンクを表示するコンポーネント
 */
export const UBlockLink: React.FC<UBlockLinkProps> = ({
  link,
  imageSrc,
  onClick,
  alt,
  category,
  title,
}) => {
  return (
    <BlockLinkElem
      onClick={
        onClick
          ? (e) => {
              e.preventDefault()
              onClick(link)
            }
          : undefined
      }
      href={link}
    >
      <UImage
        option={
          typeof imageSrc === "string"
            ? { type: "REMOTE", src: imageSrc }
            : imageSrc
        }
        alt={alt}
      />
      <p className="link-category">{category}</p>
      <p className="link-title">{title}</p>
    </BlockLinkElem>
  )
}

const BlockLinkElem = styled.a`
  width: 100%;
  display: inline-block;
  word-break: break-all;
  img {
    display: block;
    margin-bottom: 12px;
    border-radius: 4px;
    border: 1px solid
      ${(props: any) => getThemed(props as any, "colors.borderGray")};
    box-shadow: 0 2px 1px -1px rgba(77, 158, 254, 0.23);
  }
  .link-category {
    color: ${(props: any) => getThemed(props as any, "colors.gray3")};
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    display: block;
    margin-bottom: 2px;
  }
  .link-title {
    color: ${(props: any) => getThemed(props as any, "colors.gray5")};
    font-size: 15px;
    font-weight: normal;
    line-height: 22px;
  }
`
