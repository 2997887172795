import React from "react"
import { usePortal } from "./hooks/use-portal"
import styled from "@emotion/styled"

type animationType = "none" | "fade"

export type ModalProps = {
  animationType?: animationType | false | null | undefined
  // 背景を透明にする
  transparent?: boolean
  visible: boolean
  onRequestClose?: () => void
  onShow?: () => void
  onDismiss?: () => void
  children: React.ReactNode
}

export const Modal: React.FC<ModalProps> = ({
  children,
  transparent = false,
  animationType = "fade",
  onRequestClose,
  onDismiss,
  onShow,
  visible,
}) => {
  const withPortal = usePortal()
  const [hidden, setHidden] = React.useState(false)
  const modalContentRef = React.useRef<HTMLDivElement>(null)

  // 透明のときは外側のクリックで閉じる
  const onClickOutside = React.useCallback(
    (e: React.MouseEvent<any>) => {
      if (!transparent) {
        return
      }

      // 対象がモーダルの内部であれば何もしない
      if (!modalContentRef.current) return
      if (e.target && modalContentRef.current.contains(e.target as any)) return

      onRequestClose && onRequestClose()
      setHidden(true)
    },
    [onRequestClose, transparent]
  )

  React.useEffect(() => {
    if (hidden) {
      onDismiss && onDismiss()
    } else if (visible) {
      onShow && onShow()
    }
  }, [hidden, onDismiss, onShow, visible])

  return visible
    ? withPortal(
        <ModalWrapper
          transparent={transparent}
          onClick={onClickOutside}
          animationType={animationType ? animationType : "fade"}
        >
          <div className="modal-scroll-inner">
            <div className="modal-content" ref={modalContentRef}>
              {children}
            </div>
          </div>
        </ModalWrapper>
      )
    : null
}

type ModalStyleProps = {
  transparent: boolean
  animationType?: animationType | false | null | undefined
}

// TODO: フェードアウト
const ModalWrapper = styled.div<ModalStyleProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) =>
    props.transparent ? "transparent" : "rgba(64, 68, 72, 0.75)"};
  z-index: 1000;

  ${(props) =>
    switchByAnimationType(
      !props.transparent ? props.animationType : undefined,
      "animation: modal-fade 0.2s ease 0s 1 normal;",
      "animation: none;"
    )}

  .modal-scroll-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @keyframes modal-fade {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: rgba(64, 68, 72, 0.75);
    }
  }
`

const switchByAnimationType = (
  type: animationType | undefined | false | null,
  fadeProp: string,
  noneProp: string
) => {
  switch (type) {
    case "fade":
      return fadeProp
    default:
      return noneProp
  }
}
