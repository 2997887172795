import React, { useLayoutEffect } from "react"

/** このコンポーネントで囲まれた要素以外を選択できなくする */
export const FocusTrap: React.FC<{ trapScroll?: boolean }> = (props) => {
  return (
    <>
      <FocusTrapLayer />
      {props.children}
    </>
  )
}

/** このコンポーネントを使用するとbodyのスクロールを禁止します */
export const ScrollTrap: React.FC<{}> = (props) => {
  useLayoutEffect(() => {
    // このコンポーネントが表示されている間はbodyをfixedにしてスクロールさせない
    freezeScrollPosition()

    return () => {
      // コンポーネントが非表示になった時点で元の位置に戻す
      restoreScrollPosition()
    }
  }, [])
  return <>{props.children}</>
}

const freezeScrollPosition = () => {
  const scrollY = window.scrollY
  const scrollX = window.scrollX
  document.body.style.position = "fixed"
  document.body.style.top = `-${scrollY}px`
  document.body.style.left = `-${scrollX}px`
  document.body.style.right = "0"
  document.body.style.bottom = "0"
}

const restoreScrollPosition = () => {
  const scrollY = document.body.style.top
  const scrollX = document.body.style.left
  document.body.style.position = ""
  document.body.style.top = ""
  document.body.style.left = ""
  document.body.style.right = ""
  document.body.style.bottom = ""
  window.scrollTo(
    parseInt(scrollX || "0", 10) * -1,
    parseInt(scrollY || "0", 10) * -1
  )
}

/** PopupやAlertより下に表示され、コンテンツより上に表示されてクリック等を阻害するコンポーネント
 * NOTE: 本当はchildrenのみイベントに反応するようにしたいが後々
 */
const FocusTrapLayer = () => (
  <div
    css={{
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
    }}
  />
)
