import { FirebaseShim } from "@common/libs/firebase"
import firebase from "firebase/app"

/** 現在のfirebase.Userを取得する
 * いきなり呼ぶと基本はnullが返ってくるので、その用途以外はsubscribeAuthChangeを使うべき
 */
export const getCurrentUser = async () => {
  return FirebaseShim.auth().currentUser
}

/** 認証状態が変化したイベントを監視する */
export const subscribeAuthChange = async (
  callback: (
    user: firebase.User | null,
    idToken?: firebase.auth.IdTokenResult | null
  ) => void
) => {
  return FirebaseShim.auth().onAuthStateChanged(async (user) => {
    callback(user, await user?.getIdTokenResult(true))
  })
}

/** ログアウト */
export const unauthenticateFB = async () => {
  return FirebaseShim.auth().signOut()
}

/** パスワードログイン */
export const authFBWithPassword = async (email: string, password: string) => {
  const userCred = await FirebaseShim.auth().signInWithEmailAndPassword(
    email,
    password
  )
  return userCred.user
}

/** ユーザー登録して、メールアドレス確認メールを送信する */
export const signupFBWithPassword = async (
  email: string,
  password: string,
  nextURL?: string
) => {
  const userCred = await FirebaseShim.auth().createUserWithEmailAndPassword(
    email,
    password
  )

  if (userCred.user) {
    setTimeout(() => {
      if (userCred.user) {
        userCred.user.sendEmailVerification(
          nextURL
            ? {
                url: nextURL,
              }
            : undefined
        )
      }
    })
  }

  return userCred.user
}

/**パスワードリセットメールを送信する */
export const sendFBPasswordReset = async (email: string, nextURL?: string) => {
  return FirebaseShim.auth().sendPasswordResetEmail(
    email,
    nextURL ? { url: nextURL } : undefined
  )
}

/** ユーザーデータの作成,マイグレーションを行う */
export const runAsyncMigration = async (uid: string) => {
  // ユーザーデータがなければ作成する
  const store = FirebaseShim.firestore()
  store.collection("users").doc(uid).set({}, { merge: true })
  console.log("run user data migration: %s", uid)
}
