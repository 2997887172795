import styled from "@emotion/styled"
import React, { useCallback, useEffect, useState } from "react"
import { Flex } from "rebass"
import { GithubCSS } from "./styles/github-css"
import { SolarizedDarkCSS } from "./styles/solarized-dark-css"
import { ClipBoard } from "foundation/components/clipboard"
import { Tooltip } from "foundation/components/tooltip"

const hljs = require("highlight.js/lib/highlight") as typeof import("highlight.js/lib/highlight")

/** `UCodeBlock`のProps */

export type UCodeBlockProps = {
  /** 言語
   *
   * @default シンタックスハイライトなし
   */
  language?: UCodeBlockSupportedLanguages

  /** シンタックスハイライトのテーマ
   *
   * @default 'github'
   */
  theme?: UCodeBlockSupportedThemes

  /** 中身 */
  content: string
}

/** コードブロック */
export const UCodeBlock: React.FC<UCodeBlockProps> = ({
  language,
  content,
  ...props
}) => {
  const [renderedContent, setRenderedContent] = useState<any>()

  useEffect(() => {
    if (language && hljsLangs[language]) {
      hljs.registerLanguage(language, hljsLangs[language]())
      try {
        const ret = hljs.highlight(language, content, true)
        setRenderedContent(ret.value)
      } catch (e) {
        console.error(e)
      }
    }
  }, [language, setRenderedContent, content])

  const onCopyCode = useCallback(() => content, [content])

  const codeProps = renderedContent
    ? {
        dangerouslySetInnerHTML: { __html: renderedContent },
      }
    : { children: content }

  return (
    <ThemedCode codeTheme={props.theme}>
      {language && (
        <CodeBlockToolBar
          language={language}
          getClipboardContent={onCopyCode}
        />
      )}
      <pre>
        <code className={`hljs ${language}`} {...codeProps} />
      </pre>
    </ThemedCode>
  )
}

const TextButton = styled.button({
  color: "#868C93",
  border: 0,
  background: 0,
})

/** ツールバー */
// TODO: ツールチップを出したい, ツールチップのコンポーネントがほしい
const CodeBlockToolBar: React.FC<{
  language: UCodeBlockSupportedLanguages
  getClipboardContent: () => string
}> = ({ getClipboardContent, ...props }) => {
  const [error, setError] = useState(false)
  const handleError = () => {
    setError(true)
  }
  return (
    <Flex
      sx={{
        backgroundColor: "#F7F7F8",
        height: "35px",
        lineHeight: "19px",
        padding: "7px 15px",
        color: "#868C93",
      }}
      flexDirection="row-reverse"
    >
      <TextButton>{lang2DisplayLabel[props.language]}</TextButton>
      <Tooltip
        tooltipLabel={error ? "コピーできませんでした" : "コピーしました"}
        handler="click"
        top="30px"
        left={error ? "-75px" : "-30px"}
      >
        <ClipBoard
          onCaptureData={getClipboardContent}
          onError={() => {
            handleError()
          }}
        >
          <TextButton>
            <Icon className="far fa-clipboard" color="#868C93" />
          </TextButton>
        </ClipBoard>
      </Tooltip>
    </Flex>
  )
}

const Icon = styled.i<{ color?: string }>((p) => ({
  color: p.color,
  display: "block",
  paddingRight: "11px",
  paddingLeft: "11px",
  textAlign: "center",
}))

const ThemedCode = styled.div<{ codeTheme?: UCodeBlockSupportedThemes }>([
  {
    background: "#F7F7F8",
    borderRadius: "4px",
    overflow: "hidden",
    pre: {
      background: "#F7F7F8",
      padding: 0,
    },
    code: {},
    margin: "2rem 0",
  },
  (props) => hljsThemes[props.codeTheme || "github"],
])

export type UCodeBlockSupportedLanguages =
  | "css"
  | "less"
  | "stylus"
  | "scss"
  | "xml"
  | "html"
  | "php"
  | "typescript"
  | "coffeescript"
  | "markdown"
  | "diff"
  | "yaml"
  | "json"
  | "javascript"

export type UCodeBlockSupportedThemes = "github" | "soralized-dark"

const lang2DisplayLabel: { [K in UCodeBlockSupportedLanguages]: string } = {
  css: "CSS",
  less: "LESS",
  stylus: "Stylus",
  scss: "SCSS",
  xml: "XML",
  html: "HTML",
  php: "PHP",
  typescript: "TypeScript",
  coffeescript: "CoffeeScript",
  markdown: "Markdown",
  diff: "diff",
  yaml: "YAML",
  json: "JSON",
  javascript: "JavaScript",
}

/**
 * シンタックス
 *
 * 数が少ないので完全にbundleを分けるのはまだやらないでおく
 */
const hljsLangs: { [K in UCodeBlockSupportedLanguages]: any } = {
  css: () => require("highlight.js/lib/languages/css"),
  less: () => require("highlight.js/lib/languages/less"),
  stylus: () => require("highlight.js/lib/languages/stylus"),
  scss: () => require("highlight.js/lib/languages/scss"),
  xml: () => require("highlight.js/lib/languages/xml"),
  html: () => require("highlight.js/lib/languages/htmlbars"),
  diff: () => require("highlight.js/lib/languages/diff"),
  yaml: () => require("highlight.js/lib/languages/yaml"),
  json: () => require("highlight.js/lib/languages/json"),
  markdown: () => require("highlight.js/lib/languages/markdown"),
  coffeescript: () => require("highlight.js/lib/languages/coffeescript"),
  php: () => require("highlight.js/lib/languages/php"),
  typescript: () => require("highlight.js/lib/languages/typescript"),
  javascript: () => require("highlight.js/lib/languages/javascript"),
}

const hljsThemes: { [K in UCodeBlockSupportedThemes]: typeof GithubCSS } = {
  github: GithubCSS,
  "soralized-dark": SolarizedDarkCSS,
}
