import { __ } from "context/i18n"
import { AppNoticeProvider } from "foundation/App/notice"
import { LayerHost, LayerProvider } from "foundation/components/Layer"
import { Scroll } from "foundation/components/Scroll"
import FujihomePage from "pages/guideline/hujihome/hujihome"
import ThirdScope from "pages/guideline/third-scope/third-scope"
import React, { lazy, Suspense } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"
import { PublishedPageRoutePath } from "../routelib"
import { PublicRoute } from "./Router/PublicRoute"
import { SecretRoute } from "./Router/SecretRoute"
import { UpdateNotify } from "./UpdateNotify"

// 重いページとあんまり使わないページはlazyで読み込んでbundleを分ける
const DashboardPage = lazy(() => {
  import("context/project-editor/ProjectEditorRouter") // ダッシュボードの時点でエディタも読み込んでおく
  return import("pages/dashboard")
})

const LogoutPage = lazy(() => import("pages/login/logout"))
const AccountPage = lazy(() => import("pages/account/account"))

// エディタ
const ProjectEditorRouter = lazy(() =>
  import("context/project-editor/ProjectEditorRouter")
)

// 閲覧用ページ
const ProjectViewer = lazy(() =>
  import("context/user-content/components/ProjectViewerApp")
)

// 新規ユーザー用ページ
const WelcomePage = lazy(() => import("pages/welcome/welcome"))
const VerifyPage = lazy(() => import("pages/welcome/verify_complete"))

// ゲスト用ページ ログイン関連
const LoginPage = lazy(() => import("pages/login/login"))
const ResetPage = lazy(() => import("pages/login/reset"))

export const AppRouter: React.FC<{
  layout?: React.ComponentType<{ header?: React.ComponentType }>
  header?: React.ComponentType
}> = ({ layout: Layout = React.Fragment, header: Header = React.Fragment }) => (
  <Router>
    <LayerProvider>
      <>
        <Layout header={Header}>
          <Suspense fallback={__("common.loading")}>
            <Scroll />
            <Switch>
              <Redirect exact from="/" to="/dashboard" />
              {/** オンボーディング */}
              <Route
                path="/dashboard/welcome/verify_complete"
                component={VerifyPage}
              />
              <SecretRoute
                roles={["user", "user:admin", "user:new"]}
                exact
                path="/dashboard/welcome"
                component={WelcomePage}
              />

              {/** 公開ページ */}
              <SecretRoute
                roles={["user:admin", "readonly"]}
                path="/_/fujihome"
                component={FujihomePage}
              />
              <SecretRoute
                roles={["user:admin", "readonly"]}
                path="/_/third-scope"
                component={ThirdScope}
              />

              <Route path={PublishedPageRoutePath} component={ProjectViewer} />

              {/** アプリ本体 */}
              <SecretRoute path="/dashboard" component={DashboardPage} />
              <SecretRoute path="/account" component={AccountPage} />
              <SecretRoute
                path="/projects/:teamID/:projectID"
                component={ProjectEditorRouter}
              />
              <SecretRoute
                roles={["user", "user:admin", "user:new", "readonly"]}
                path="/logout"
                component={LogoutPage}
              />

              {/** パブリック */}
              <PublicRoute exact path="/login" component={LoginPage} />
              <PublicRoute path="/login/reset" component={ResetPage} />
              <Route>
                <div>Not Found</div>
              </Route>
            </Switch>
          </Suspense>
        </Layout>
        <AppNoticeProvider />
        <UpdateNotify />
        <LayerHost gatewayID="spirally-portal" className="spirally-portal" />
      </>
    </LayerProvider>
  </Router>
)
