import {
  configureStore as configureStoreRedux,
  getDefaultMiddleware,
} from "@reduxjs/toolkit"

import rootReducer from "./rootReducer"

const middleware = [...getDefaultMiddleware()]

export const configureStore = () => {
  const store = configureStoreRedux({
    reducer: rootReducer,
    middleware,
  })

  if (process.env.NODE_ENV === "development") {
    if (module.hot) {
      console.log("HMR enabled for store")
      module.hot.accept("./rootReducer", () => {
        console.log("update reducer")
        store.replaceReducer(rootReducer)
      })
    }
  }
  return store
}
