import React from "react"

/** パネルのコンテナー **/
export const Panel: React.FC<PanelProps> = () => <></>

export type PanelProps = {}

/** パネルタイトル **/
export const PanelTitle: React.FC<PanelTitleProps> = () => <></>

export type PanelTitleProps = {}

/** パネルコンテンツ **/
export const PanelContent: React.FC<PanelContentProps> = () => <></>

export type PanelContentProps = {}

/** パネルフッターー **/
export const PanelFooter: React.FC<PanelFooterProps> = () => <></>

export type PanelFooterProps = {}
