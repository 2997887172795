import { Project, ProjectDraft } from "@common/domain/project"
import {
  createProject,
  createTeam,
  listProjectAcrossTeams,
  TeamAndProjectList,
} from "./projectAction"
import { Team, TeamDraft } from "@common/domain/team"
import { UnauthorizedError, assert } from "@common/libs"
import { FirebaseShim } from "@common/libs/firebase"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "context/entrypoint/store"
import { IRecord } from "utils"

export type IProjectStore = {
  // TODO: ネストしない構造にする
  // teams: { [key:string]: Team }
  // projects: { [key:string]: Projects }
  // teamProjects: { [key:string]: string[] }
  projects: IRecord<TeamAndProjectList>
}

const initialState: IProjectStore = {
  projects: { value: {}, state: undefined },
}

const ProjectSlice = createSlice({
  initialState,
  name: "project",
  reducers: {
    updateProjects: (
      state,
      action: PayloadAction<{
        startLoading?: true
        result?: IProjectStore["projects"]
      }>
    ) => {
      if (action.payload.startLoading) {
        state.projects.error = undefined
        state.projects.state = "loading"
      } else if (action.payload.result) {
        state.projects = action.payload.result
      }
    },
  },
})

export const { updateProjects } = ProjectSlice.actions
export default ProjectSlice.reducer

export const fetchMyProjectsAction = (): AppThunk<Promise<void>> => async (
  dispatch
) => {
  dispatch(updateProjects({ startLoading: true }))
  try {
    const projectsHash = await listProjectAcrossTeams({
      uid: FirebaseShim.auth().currentUser?.uid!,
    })

    dispatch(
      updateProjects({ result: { state: "success", value: projectsHash } })
    )
  } catch (e) {
    assert(e.code !== "permission-denied")
    dispatch(
      updateProjects({
        result: { state: "failure", error: { error: e.message } },
      })
    )
  }
}

export const createProjectAction = (payload: {
  teamID: string
  project: Pick<ProjectDraft, "displayName" | "description">
}): AppThunk<Promise<Project>> => async (dispatch) => {
  try {
    return await createProject(payload)
  } catch (e) {
    console.error(e)
    throw e
  }
}

// NOTE: とりあえずここに置いてあるけどcontext/teamに移動する
export const createTeamAction = (payload: {
  team: Pick<TeamDraft, "description" | "displayName">
}): AppThunk<Promise<Team>> => async (dispatch) => {
  const uid = FirebaseShim.auth().currentUser?.uid
  if (!uid) {
    throw new UnauthorizedError()
  }
  try {
    return await createTeam({ ...payload, uid })
  } catch (e) {
    // TODO: User friendly error
    console.error(e)
    throw e
  }
}
