import { T, __ } from "context/i18n/components/I18n"
import React from "react"
import { ErrorDetail } from "utils"

/** `ErrorDetail` を受け取ってJSX.Elementとして表示します */
export const ErrorMessage: React.FC<{ error: ErrorDetail }> = ({ error }) => {
  if (error.errorID) {
    return __(error.errorID as any, {
      defaultMessage: __("errors.unknown.with_detail", {
        values: {
          detail: error.error,
        },
      }),
    })
  }
  if (error.error) {
    return (
      <T id="errors.unknown.with_detail" values={{ detail: error.error }} />
    )
  }
  return <T id="errors.unknown.simple" />
}
