import NProgress from "nprogress"

NProgress.configure({ showSpinner: false })

let progressBarTimeout: ReturnType<typeof setTimeout> | null = null

const clearProgressBarTimeout = () => {
  if (progressBarTimeout) {
    clearTimeout(progressBarTimeout)
    progressBarTimeout = null
  }
}

/**
 * show progress bar after delay
 */
export const startProgressBar = () => {
  clearProgressBarTimeout()
  progressBarTimeout = setTimeout(() => {
    NProgress.start()
  }, 200)
}

/**
 * stop and hide progress bar
 */
export const stopProgressBar = () => {
  clearProgressBarTimeout()
  NProgress.done()
}
