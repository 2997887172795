// cssのz-indexの値
export const zIndex = {
  Editor: 30,

  EditorPopUp: 33,

  // エディタのコンテンツ
  EditorContent: 30,

  // エディタを内包するレイアウト要素
  EditorLayout: 35,

  // エディタの編集機能
  EditorTool: 40,

  // エディタの本文外のレイアウト要素
  EditorOuterLayout: 44,

  // エディタの編集機能(ユーザーの操作に応じて出てくるもの)
  EditorToolAttentional: 45,

  appLayout: 50,
  appPopMenu: 70,
  appNotice: 100, // とりあえず重要なので100に設定した
  appPrompt: 200, // ユーザーの操作を中断させるダイアログ等
}
