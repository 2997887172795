import React, { useEffect } from "react"
import { IntlProvider } from "react-intl"
import { useDispatch } from "react-redux"
import { useSelector } from "context/entrypoint/store"
import { loadLocaleAction } from "context/entrypoint/slices/rootSlice"

export const I18nProvider: React.FC<{}> = (props) => {
  // 翻訳データはたぶんReduxに無くて良いのでこちらに移動したい
  const dispatch = useDispatch()
  const [locale, messages] = useSelector((s) => [
    s.root.locale,
    s.root.messages,
  ])

  useEffect(() => {
    dispatch(loadLocaleAction(locale))
  }, [locale, dispatch])

  return (
    messages && (
      <IntlProvider locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
    )
  )
}
