import MD from "markdown-it"

const md = MD({
  html: false,
  linkify: true,
  typographer: true,
  breaks: true,
}).disable(["image", "link"])

// md.linkify.tlds(require("tlds"))

//md.linkify.add("id:", {
//  validate: function(text, pos, self) {
//    const tail = text.slice(pos);
//
//    if (!self.re.entry) {
//      self.re.entry = new RegExp(
//        "^([^/s]){1,15}(?!_)(?=$|" + self.re.src_ZPCc + ")"
//      );
//    }
//    if (self.re.entry.test(tail)) {
//      if (pos >= 2 && tail[pos - 2] === "@") {
//        return false;
//      }
//      return tail.match(self.re.entry!)![0].length;
//    }
//    return 0;
//  },
//  normalize: function(match) {
//    match.text = match.text.replace("id:", "");
//    match.url = `/${match.url.replace("id:", "")}`;
//  }
//});

const defaultRender =
  md.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options)
  }

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const elem = tokens[idx]
  const href = elem.attrGet("href")!
  if (!href.match(/https?:\/\/spirally.app/)) {
    elem.attrPush(["target", "_blank"])
    elem.attrPush(["rel", "noopener"])
  }
  return defaultRender(tokens, idx, options, env, self)
}

export const Markdown = md
