import React from "react"
import { createPortal } from "react-dom"

/** React.ReactPortalを作成するhook
 *
 * コンポーネントアンマウント時に作成したportlもDOMから削除されます。
 */
export const usePortal = () => {
  const rootElemRef = React.useRef<HTMLDivElement>()
  if (document && !rootElemRef.current) {
    rootElemRef.current = document.createElement("div")
  }

  React.useLayoutEffect(() => {
    const root = rootElemRef.current
    if (!root || !document) return
    document.body.appendChild(root)
    return () => {
      root.remove()
    }
  }, [])

  return (children: React.ReactElement) => {
    return createPortal(children, rootElemRef.current!)
  }
}
