import { combineReducers } from "@reduxjs/toolkit"
import projectPage from "./pageEditorSlice"
import project from "./projectEditorSlice"
import { StoreType } from "utils/redux"

export * from "./pageEditorSlice"
export * from "./projectEditorSlice"
export * from "./pageAction"
export * from "./projectAction"

const editorReducer = combineReducers({
  project,
  projectPage,
})

export default editorReducer

export type EditorStoreType = StoreType<typeof editorReducer>
