import styled from "@emotion/styled"
import { TopBar } from "context/header"
import { getProps } from "foundation/utils/styled"
import React from "react"
import { useLocation } from "react-router-dom"
import { space, SpaceProps } from "styled-system"

type StyleProp = SpaceProps

type LayoutProp = StyleProp & {
  footer?: React.ReactNode
  header?: React.ComponentType
}

/** アプリケーション内で共通で使用するレイアウト */
export const AppLayout: React.FC<LayoutProp> = React.memo<LayoutProp>(
  ({ header: Header = TopBar, ...props }) => {
    const location = useLocation()
    // 公開プロジェクトの場合はレイアウトを変更
    if ((location.pathname as string).startsWith("/_/")) {
      return <PublicDocLayout>{props.children}</PublicDocLayout>
    }

    // TODO: URLではなくページ側でレイアウトを選択する
    const thinContent = (location.pathname as string).startsWith("/projects/")
    let layoutProps: StyleProp | undefined = !thinContent
      ? getProps(props, space.propNames)
      : { m: 0 }

    // 編集画面もレイアウトを変更
    if ((location.pathname as string).startsWith("/projects/")) {
      return (
        <EditorLayout>
          <LayoutContent {...layoutProps}>{props.children}</LayoutContent>
        </EditorLayout>
      )
    }

    return (
      <NormalLayout>
        <Header css={{ position: "sticky", width: "100%" }} />
        <LayoutContent {...layoutProps}>{props.children}</LayoutContent>
        {props.footer && <LayoutFooter>{props.footer}</LayoutFooter>}
      </NormalLayout>
    )
  }
)

AppLayout.displayName = "Layout"

/** 通常のレイアウト */
const NormalLayout = styled.div<{}>`
  position: relative;
  z-index: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props: any) => props.theme.colors.gray1};
`

/** 公開ドキュメントのレイアウト */
const PublicDocLayout = styled.div`
  position: relative;
  z-index: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const EditorLayout = styled.div`
  position: relative;
  z-index: 0;
`

const LayoutContent = styled.div<StyleProp>(space, {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
})

LayoutContent.defaultProps = {
  m: 22,
}

const LayoutFooter = styled.div`
  margin-top: auto;
`
