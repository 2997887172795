import { dataWithID } from "@common/domain/common"
import {
  deserializePublicProject,
  PublicProject,
  PublicProjectCollectionID,
  PublicProjectFB,
  DefaultPageTree,
} from "@common/domain/project"
import { ApplicationError, errorI18n } from "@common/libs"
import { FirebaseShim } from "@common/libs/firebase"
import { PayloadAction } from "@reduxjs/toolkit"
import { ErrorDetail, IRecord } from "utils"
import { createSlice } from "../../../utils/redux"
import { AppThunk } from "../../entrypoint/store"

type IUserContentProject = {
  project: PublicProject
  pageCategoryIndex: { [key: string]: string }
}

export type IUserContentStore = {
  projectID?: string
  content: IRecord<IUserContentProject>
}

const initialState: IUserContentStore = {
  content: { state: undefined },
}

const UserContentSlice = createSlice({
  name: "userContent",
  initialState,
  reducers: {
    setProjectLoading: (
      state,
      action: PayloadAction<{
        projectID: string
      }>
    ) => {
      state.projectID = action.payload.projectID
      state.content.error = undefined
      state.content.state = "loading"
    },
    setProjectLoaded: (
      state,
      action: PayloadAction<
        {
          projectID: string
        } & ({ value: PublicProject; error?: null } | { error: ErrorDetail })
      >
    ) => {
      state.projectID = action.payload.projectID
      if (!action.payload.error) {
        state.content.state = "success"
        state.content.value = {
          project: action.payload.value,
          pageCategoryIndex: Object.fromEntries(
            Object.entries(
              action.payload.value.project.pageTree.categories
            ).flatMap(([categoryID, v]) => v.pages.map((p) => [p, categoryID]))
          ),
        }
        state.content.error = undefined
      } else {
        state.content.state = "failure"
        state.content.value = undefined
        state.content.error = action.payload.error
      }
    },
  },
})

export const { setProjectLoading, setProjectLoaded } = UserContentSlice.actions
export default UserContentSlice.reducer

export const loadPublicProjectAction = (payload: {
  projectID: string
}): AppThunk<Promise<void>> => async (dispatch) => {
  // 読み込み開始
  dispatch(setProjectLoading(payload))

  try {
    // 公開プロジェクトを取得する
    const store = FirebaseShim.firestore()
    const projectDoc = await store
      .collection(PublicProjectCollectionID)
      .doc(payload.projectID)
      .get()
    if (!projectDoc.exists) {
      throw new ApplicationError(
        "project not exist",
        "user_content.errors.not_found"
      )
    }

    const pubProject = deserializePublicProject(
      dataWithID<PublicProjectFB>(projectDoc)
    )

    // 開発中のデータ向け
    if (!pubProject.project.pageTree) {
      pubProject.project.pageTree = DefaultPageTree
    }

    // OK
    dispatch(
      setProjectLoaded({
        projectID: payload.projectID,
        value: pubProject,
      })
    )
  } catch (e) {
    console.error(e)
    const msg = errorI18n(e)

    // NG
    dispatch(
      setProjectLoaded({
        projectID: payload.projectID,
        error: {
          error: e.message,
          errorID: (msg?.id as any) ?? undefined,
        },
      })
    )
    return
  }
}
