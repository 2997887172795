// import React from "react"
import { useDispatch, useSelector, useStore } from "react-redux"
import { Action, AnyAction, Reducer, Store } from "redux"
import { createSlice as createSliceRedux } from "@reduxjs/toolkit"
import { ThunkDispatch } from "redux-thunk"

/** スペシャルなredux用hooksを作成 */
export function createTypedReduxHooks<TState>(
  reducer: Reducer<TState>
): {
  useDispatch: {
    (): ThunkDispatch<TState, any, Action>
  }
  useSelector: <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
  ) => TSelected
  useStore: <TState, A extends Action = AnyAction>() => Store<TState, A>
} {
  return {
    useDispatch,
    useSelector,
    useStore,
  }
}

/** stateの型だけを指定できるcreateSliceにしたいができず、、 */
// ref: https://github.com/reduxjs/redux-starter-kit/issues/98
export const createSlice = createSliceRedux

export type StoreType<T> = T extends Reducer<infer K> ? K : never
