import { Box as RebassBox, BoxKnownProps } from "rebass"

import {
  maxWidth,
  minWidth,
  border,
  MaxWidthProps,
  MinWidthProps,
  width,
  WidthProps,
  compose,
} from "styled-system"
import styled from "@emotion/styled"

export type BoxProps = BoxKnownProps &
  MaxWidthProps &
  MinWidthProps &
  WidthProps

const boxStyles = compose(maxWidth, minWidth, width, border)

export const Box = styled<React.FC<BoxProps>>(RebassBox)(boxStyles)
