import React from "react"
import { Helmet } from "react-helmet"

// TODO: 多言語のリンク(link[rel=alternate])

/** 標準の`<head />`タグの中身
 *
 * これらは特定機能のコンポーネントというわけでも無いのでstaticへの直リンクします
 */
export const Heads = () => (
  <Helmet>
    <title key="title">Spirally</title>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/static/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/static/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/static/favicon-16x16.png"
    />
    <link rel="manifest" href="/static/site.webmanifest" />
    <link
      rel="mask-icon"
      href="/static/safari-pinned-tab.svg"
      color="#5bbad5"
    />
    <meta name="apple-mobile-web-app-title" content="Spirally" />
    <meta name="application-name" content="Spirally" />
    <meta name="msapplication-TileColor" content="#2b5797" />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
)
