import { ValidationRule } from "fastest-validator"

export type WithID<T> = T extends undefined
  ? undefined
  : T & {
      id: string
    }

export function dataWithID<T>(snap: {
  [key: string]: any
  id: string
  data: (...args: any[]) => any
}): WithID<T> {
  const data = snap.data()
  if (!data) return undefined as any
  return { ...(data as any), id: snap.id }
}

export type SchemaOf<T, K extends keyof T> = {
  [key in keyof Pick<Required<T>, K>]: ValidationRule
}
