import { Theme } from "foundation/styles/theme"
import React from "react"
import { Text } from "rebass"

/** ページカテゴリ、ページタイトル */
export type PageTitleProps = {
  category?: string
  title: string
  className?: string

  onRenderElement?: (props: { current: string }) => JSX.Element
}

/** ページのタイトルを表示する */
export const PageTitle: React.FC<PageTitleProps> = ({
  category,
  title,
  ...props
}) => (
  <h1 className={props.className}>
    {category && (
      <div
        css={(theme: Theme) => ({
          fontSize: 15,
          color: theme.colors.gray4,
        })}
        className="category"
      >
        {category}
      </div>
    )}
    <Text variant={"title"} fontSize={33}>
      {props.onRenderElement?.({ current: title }) ?? title}
    </Text>
  </h1>
)
