import { colors } from "foundation/styles/theme/colors"
import React, { useCallback } from "react"
import { Flex, Text } from "rebass"
import { reloadWebsite } from "../slices/rootSlice"
import { useDispatch, useSelector } from "../store/rootReducer"
import { Layer } from "foundation/components/Layer"

export const UpdateNotify: React.FC<{
  presenter?: React.ComponentType<IUpdateNotifyPresenter>
}> = (props) => {
  const dispatch = useDispatch()
  const available = useSelector((s) => s.root.updateAvailable)
  const handleReload = useCallback(() => {
    dispatch(reloadWebsite())
  }, [dispatch])
  return (
    <UpdateNotifyContent
      {...props.presenter}
      available={available}
      handleReload={handleReload}
    />
  )
}

export const UpdateNotifyContent: React.FC<{
  presenter?: React.ComponentType<IUpdateNotifyPresenter>
  available: boolean
  handleReload?: any
}> = ({ presenter: Presenter = UpdateNotifyPresenter, ...props }) => {
  return (
    <Presenter available={props.available} handleReload={props.handleReload} />
  )
}

type IUpdateNotifyPresenter = {
  available: boolean
  handleReload?: any
}

const UpdateNotifyPresenter: React.FC<IUpdateNotifyPresenter> = (props) => {
  return props.available ? (
    <Layer>
      <Flex
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10000,
          padding: 3,
          background: colors.darkPrimary,
          color: colors.white,
        }}
      >
        <Text>
          アップデートがあります。正常に使用するためにリロードしてください。
          <button onClick={props.handleReload}>リロード</button>
        </Text>
      </Flex>
    </Layer>
  ) : null
}
