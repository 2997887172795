import { IFirebaseApp, FirebaseShim } from "./index"

export function serializeFBTimestamp(
  input?: number | null
): IFirebaseApp.firestore.Timestamp | null {
  return (input && FirebaseShim.firestore.Timestamp.fromMillis(input)) || null
}
export function deserializeFBTimestamp(
  input?: IFirebaseApp.firestore.Timestamp | null | undefined
): number | null {
  return (input && input.toMillis()) || null
}
