import useWhatInput from "react-use-what-input"
import {
  ProjectPageTree,
  ProjectPageTreeCategory,
  RootCategoryKey,
  TopPageKey,
} from "@common/domain/project"
import { css, jsx } from "@emotion/core"
import styled from "@emotion/styled"
import cn from "classnames"
import { zIndex } from "foundation/styles/theme"
import { colors } from "foundation/styles/theme/colors"
import { prettyScrollCSS } from "foundation/styles/mixins"
import React, { useCallback, useMemo } from "react"
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  Droppable,
} from "react-beautiful-dnd"
import { Image } from "rebass"

export type ProjectSidebarProps = ProjectSidebarBaseProps &
  (ProjectSidebarLogoImageProps | ProjectSidebarLogoElementProps)

export type ProjectSidebarBaseProps = {
  className?: string
} & ProjectSidebarStateProps &
  ProjectSidebarLogoEventProps &
  ProjectSidebarDnDProps &
  ProjectSidebarRenderEventProps &
  (ProjectSidebarDataPropsOld | ProjectSidebarDataProps)

type ProjectSidebarStateProps = {
  currentKey: string
  onSelectPage: (key: string) => void
}

type ProjectSidebarLogoEventProps = {
  onClickLogo?: (e: any, current?: string) => void
}

type ProjectSidebarRenderEventProps = {
  /** 各ページ一覧の項目名の後に描画されるコンポーネント */
  renderAfterItem?: (
    key: string,
    item?: Item,
    categoryKey?: string
  ) => React.ReactNode
  /** 各ページ一覧の最後の項目として追加で描画されるコンポーネント */
  renderAfterItems?: (key: string) => React.ReactNode
  /** 各カテゴリの項目名の後に描画されるコンポーネント */
  renderAfterCategoryItem?: (
    key: string,
    cat: ProjectPageTreeCategory
  ) => React.ReactNode
  /** 各カテゴリのページ一覧の下に描画されるコンポーネント */
  renderAfterCategory?: (key: string) => React.ReactNode
  /** 各ページ、カテゴリーの前に描画されるコンポーネント */
  renderBeforeItemAndCategory?: () => React.ReactNode
  /** 最後のカテゴリの下に描画されるコンポーネント */
  renderFooter?: () => React.ReactNode
}

type ProjectSidebarDnDProps = {
  sortable?: boolean
  onMoveItem?: (from: ItemPosition, to: ItemPosition) => void
  onMoveCategory?: (from: CategoryPosition, to: CategoryPosition) => void
}

/** 古い形式のデータを受けとる場合のprops */
type ProjectSidebarDataPropsOld = {
  dataDontUse: DataProps[]
}

type DataProps = {
  category: CategoryProps
  pages: PageProps[]
}
type PageProps = {
  key: string
  label: string
}
type CategoryProps = {
  key: string
  label: string
}

/** 受けとるデータを定義するprops */
type ProjectSidebarDataProps = {
  data: ProjectPageTree & {
    pages: {
      [key: string]: Item
    }
  }
}

const migrateOldData = (
  oldData: ProjectSidebarDataPropsOld["dataDontUse"]
): ProjectSidebarDataProps["data"] => ({
  categories: oldData.reduce<{ [key: string]: ProjectPageTreeCategory }>(
    (acc, c) => {
      acc[c.category.key] = {
        name: c.category.label,
        pages: c.pages.map((p) => p.key),
      }
      return acc
    },
    {}
  ),
  category_order: oldData.map((o) => o.category.key),
  pages: oldData.reduce<{ [key: string]: Item }>((acc, c) => {
    for (const p of c.pages) {
      if (!acc[p.key]) {
        acc[p.key] = {
          displayName: p.label,
        }
      }
    }
    return acc
  }, {}),
})

/** ロゴに画像を設定する際のprops */
export type ProjectSidebarLogoImageProps = {
  logoSrc: string
  logoAlt: string
}

/** ロゴに `React.ReactElement`を設定する際のprops */
export type ProjectSidebarLogoElementProps = {
  logo: React.ReactElement
}

export type ItemPosition = { categoryKey: string; index: number }

export type CategoryPosition = { index: number }

type Item = {
  displayName: string
}

/** サイドバー */
export const ProjectSidebar: React.FC<ProjectSidebarProps> = ({
  onClickLogo,
  currentKey,
  onMoveCategory,
  onMoveItem,
  ...props
}) => {
  // イベントハンドラー
  const handleSelectPage = props.onSelectPage
  const cachedHandler = useMemo(() => {
    const cache: any = {}
    return (key: string): ((...args: any) => any) => {
      return cache[key] ?? (cache[key] = handleSelectPage.bind(undefined, key))
    }
  }, [handleSelectPage])

  // 並び換え
  const sortable = props.sortable
  const handleMoveItem = useCallback<DragDropContextProps["onDragEnd"]>(
    (result, _provided) => {
      if (!sortable) return
      if (!result.destination) return
      switch (result.type) {
        case "item":
          onMoveItem &&
            onMoveItem(
              {
                categoryKey: result.source.droppableId,
                index: result.source.index,
              },
              {
                categoryKey: result.destination.droppableId,
                index: result.destination.index,
              }
            )
          return
        case "category":
          onMoveCategory &&
            onMoveCategory(
              { index: result.source.index },
              { index: result.destination.index }
            )
          return
      }
    },
    [onMoveCategory, onMoveItem, sortable]
  )

  // ロゴ
  const logo =
    "logo" in props ? (
      props.logo
    ) : (
      <Image src={props.logoSrc} alt={props.logoAlt} />
    )
  const logoIdent = "logoSrc" in props ? props.logoSrc : undefined
  const handleClickLogo = useCallback(
    (e: any) => {
      onClickLogo && onClickLogo(e, logoIdent)
    },
    [logoIdent, onClickLogo]
  )

  // データ
  const oldData = ("dataDontUse" in props && props.dataDontUse) || undefined
  const currentData = ("data" in props && props.data) || undefined
  // 古いデータ形式の場合の変換処理
  const data = useMemo<ProjectSidebarDataProps["data"]>(() => {
    return currentData ?? migrateOldData(oldData!)
  }, [oldData, currentData])

  return (
    <SidebarPresenter
      {...props}
      onMoveItem={handleMoveItem}
      onClickLogo={handleClickLogo}
      currentKey={currentKey}
      logoComponent={logo}
      cachedHandler={cachedHandler}
      data={data}
    />
  )
}

type SidebarPresenterInternal = {
  logoComponent: React.ReactElement
  onClickLogo: (e: any) => void
  onMoveItem?: any
  cachedHandler: (key: string) => (...args: any) => any
} & ProjectSidebarStateProps &
  ProjectSidebarLogoEventProps &
  ProjectSidebarRenderEventProps

const SidebarPresenter: React.FC<
  ProjectSidebarDataProps &
    SidebarPresenterInternal & {
      className?: string
      sortable?: boolean
    }
> = (props) => (
  <DragDropContext onDragEnd={props.onMoveItem}>
    <SidebarWrapper className={props.className}>
      <SidebarStaticContent>{props.logoComponent}</SidebarStaticContent>
      <SidebarItem
        text="トップ"
        tabIndex={0}
        tag="div"
        active={props.currentKey === TopPageKey}
        onClick={props.cachedHandler(TopPageKey)}
        afterText={props.renderAfterItem?.(TopPageKey)}
      />
      <Droppable droppableId={RootCategoryKey} type="item">
        {(provided) => (
          <SidebarList ref={provided.innerRef} {...provided.droppableProps}>
            {props.data.categories[RootCategoryKey]?.pages
              .map((pageKey) => [pageKey, props.data.pages[pageKey]] as const)
              .map(([pageKey, page], idx) =>
                page ? (
                  <Draggable
                    key={pageKey}
                    draggableId={pageKey}
                    index={idx}
                    isDragDisabled={pageKey === TopPageKey || !props.sortable}
                  >
                    {(provided, _snapshot) => (
                      <SidebarItem
                        elementRef={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        tabIndex={0}
                        active={props.currentKey === pageKey}
                        onClick={props.cachedHandler(pageKey)}
                        text={page.displayName}
                        afterText={props.renderAfterItem?.(pageKey, page)}
                        beforeText={props.renderBeforeItemAndCategory?.()}
                      />
                    )}
                  </Draggable>
                ) : null
              )}
            {provided.placeholder}
          </SidebarList>
        )}
      </Droppable>
      <Droppable droppableId="categories" type="category">
        {(provided, _snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.data.category_order.map((key, cidx) => {
              const cat = props.data.categories[key]
              const pages = cat.pages.map((pageKey) => {
                const page = props.data.pages[pageKey]
                if (!page) {
                  console.warn("sidebar: unexpected page key: %s", pageKey)
                  return [pageKey, undefined] as const
                }
                return [pageKey, page] as const
              })
              return (
                <React.Fragment key={key}>
                  <Draggable
                    draggableId={key}
                    index={cidx}
                    isDragDisabled={!props.sortable}
                  >
                    {(provided, _snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SidebarHeading
                          text={cat.name}
                          afterText={props.renderAfterCategoryItem?.(key, cat)}
                          beforeText={props.renderBeforeItemAndCategory?.()}
                        />
                        <Droppable droppableId={key} type="item">
                          {(provided) => (
                            <SidebarList
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {pages.length > 0
                                ? pages.map(([pageKey, page], pidx) =>
                                    page ? (
                                      <Draggable
                                        key={pageKey}
                                        draggableId={pageKey}
                                        index={pidx}
                                        isDragDisabled={!props.sortable}
                                      >
                                        {(provided, _snapshot) => (
                                          <SidebarItem
                                            key={pageKey}
                                            elementRef={provided.innerRef}
                                            tabIndex={0}
                                            level={1}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            active={
                                              pageKey === props.currentKey
                                            }
                                            onClick={props.cachedHandler(
                                              pageKey
                                            )}
                                            text={page.displayName}
                                            afterText={props.renderAfterItem?.(
                                              pageKey,
                                              page,
                                              key
                                            )}
                                            beforeText={props.renderBeforeItemAndCategory?.()}
                                          />
                                        )}
                                      </Draggable>
                                    ) : null
                                  )
                                : null}
                              {props.renderAfterItems && (
                                <SidebarListItem>
                                  {props.renderAfterItems(key)}
                                </SidebarListItem>
                              )}
                              {provided.placeholder}
                            </SidebarList>
                          )}
                        </Droppable>
                        {props.renderAfterCategory &&
                          props.renderAfterCategory(key)}
                      </div>
                    )}
                  </Draggable>
                </React.Fragment>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {props.renderFooter && props.renderFooter()}
    </SidebarWrapper>
  </DragDropContext>
)

const SidebarList = styled.ul`
  padding-bottom: 14px;
  list-style: none;
`

const SidebarListItem = styled.li`
  padding-bottom: 14px;
  list-style: none;
`

/** サイドバーの項目 */
const SidebarItem: React.FC<
  JSX.IntrinsicElements["li"] & {
    tag?: string
    text?: string
    active?: boolean
    onClick?: any
    beforeText?: React.ReactNode
    afterText?: React.ReactNode
    elementRef?: JSX.IntrinsicElements["li"]["ref"]
    level?: number
  }
> = ({
  text,
  active,
  afterText,
  elementRef,
  beforeText,
  tag,
  level,
  ...props
}) => {
  const [currentInput] = useWhatInput()

  return jsx(
    tag ?? "li",
    {
      ...props,
      className: cn(props.className, {
        active: active,
      }),
      onKeyPress: props.onClick,
      tabIndex: 0,
      ref: elementRef,
      style: { outline: currentInput === "mouse" ? "none" : "" },
    },
    <ItemRow
      active={active}
      level={level}
      css={css`
        &:hover {
          color: ${colors.gray3};
          background: ${active ? colors.lightPrimary : "#eff0f1"};
          cursor: pointer;
          outline: none;
          span {
            color: ${colors.primary};
          }
        }
      `}
    >
      {beforeText && <BeforeItemAccessory>{beforeText}</BeforeItemAccessory>}
      <ItemContent level={level} isPageContent={true}>
        {text}
      </ItemContent>
      {afterText && <AfterItemAccessory>{afterText}</AfterItemAccessory>}
    </ItemRow>
  )
}

const SidebarStaticContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`

const SidebarWrapper = styled.div`
  width: 240px;
  height: 100%;
  padding: 24px 0 100px;
  overflow-y: scroll;
  background: #f8f8f7;
  z-index: ${zIndex.EditorOuterLayout};

  ${prettyScrollCSS}

  ${SidebarStaticContent} {
    padding-bottom: 20px;
  }

  .dragging {
    opacity: 0.5;
  }
`

/** サイドバーのカテゴリ等に使用するヘッダー */
const SidebarHeading: React.FC<{
  text?: string
  beforeText?: React.ReactNode
  afterText?: React.ReactNode
  className?: string
}> = ({ beforeText, afterText, text, ...props }) => (
  <h3 className={props.className}>
    <ItemRow
      css={css`
        color: ${colors.gray3};
        font-size: 13px;
        font-weight: bold;
        line-height: 19px;
        padding-top: 10px;
        padding-bottom: 10px;
      `}
    >
      {beforeText && <BeforeItemAccessory>{beforeText}</BeforeItemAccessory>}
      <ItemContent>{text}</ItemContent>
      {afterText && (
        <AfterItemAccessory isCategory={true}>{afterText}</AfterItemAccessory>
      )}
    </ItemRow>
  </h3>
)

const ItemContent = styled.span<{ level?: number; isPageContent?: boolean }>`
  width: 100%;
  padding-left: ${(props) => props.level ?? 0}em;
  padding-right: 8px;
  ${(props) =>
    props.isPageContent &&
    css`
      cursor: pointer;
    `}
`

/** ItemRowで使用するアクセサリ部分 */
const AfterItemAccessory = styled.div<{ isCategory?: boolean }>`
  margin-left: auto;
  margin-right: 10px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${(props) =>
    props.isCategory &&
    css`
      justify-content: space-between;
    `}
`
const BeforeItemAccessory = styled.div`
  height: 12px;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  display: flex;
  align-items: center;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`

const ItemRow = styled.div<{ active?: boolean; level?: number }>`
  position: relative;
  padding-left: 32px;
  width: 100%;
  display: flex;
  font-size: 14px;
  line-height: 30px;

  ${(props) =>
    props.active &&
    css`
      background: ${colors.lightPrimary};
      font-weight: bold;
      span {
        color: ${colors.primary};
      }
    `}

  ${AfterItemAccessory} {
    visibility: hidden;
  }
  ${BeforeItemAccessory} {
    visibility: hidden;
  }

  &:hover {
    ${AfterItemAccessory} {
      visibility: visible;
    }
    ${BeforeItemAccessory} {
      visibility: visible;
    }
  }
`
