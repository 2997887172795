import { ApplicationError } from "@common/libs"
import { ErrorDetail } from "../../utils"
import { parseFirebaseError } from "./firebase/firebaseError"

/** `Error`を`ErrorDetail`に変換する */
export const parseError = (e: Error): ErrorDetail => {
  if (e instanceof ApplicationError) {
    return {
      errorID: e.userMessage.id as any,
      error: e.userMessage.defaultMessage,
    }
  }
  const ferr = parseFirebaseError(e)
  if (ferr) return ferr
  return {
    errorID: "errors.unknown.simple",
  }
}
