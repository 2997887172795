import React from "react"
import styled from "@emotion/styled"

import logoImage from "./logo.png"
import logo2xImage from "./logo@2x.png"
import logoInvertImage from "./logo-inv.png"
import logoInvert2xImage from "./logo-inv@2x.png"

const LogoImg = styled.img`
  width: 118px;
  height: 30px;
  object-fit: none;
  object-position: left;
  margin-right: 4px;
`

type LogoProps = {
  /** 色をを反転させる
   *
   * @default false
   */
  inverted?: boolean
}

/** ロゴ画像
 *
 * 背景色に応じてロゴを切り替えます
 */
export const Logo = React.memo<LogoProps>((props) => {
  const logo = props.inverted ? logoInvertImage : logoImage
  const logo2x = props.inverted ? logoInvert2xImage : logo2xImage
  return (
    <picture>
      <LogoImg
        height="30"
        src={logo}
        srcSet={`${logo},
        ${logo2x} 2x`}
        alt="spirally.app"
      />
    </picture>
  )
})
