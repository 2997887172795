import React from "react"
import { Flex, Text } from "rebass"
import styled from "@emotion/styled"
import { margin, MarginProps, padding, PaddingProps } from "styled-system"
import { Box, BoxProps } from "../box"

export type MediaObjectProps = {
  mediaStyle?: MediaObjectStyle

  /** メインコンテンツ */
  content: React.ReactNode

  /** サブコンテンツ */
  detail?: React.ReactNode

  /** 画像 */
  image?: React.ReactNode

  /** アクセサリ */
  accessory?: React.ReactNode
  accessoryProps?: BoxProps

  children?: never
} & MarginProps &
  PaddingProps

export enum MediaObjectStyle {
  Default = 0,
  RightDetail = 1,
}

const MediaObjectElement: React.FC<MediaObjectProps> = (props) => {
  const {
    image,
    detail,
    accessory,
    mediaStyle = MediaObjectStyle.Default,
    content,
    accessoryProps,
    ...rest
  } = props

  return (
    <Flex mx={-2} alignItems="center" {...rest}>
      {/* 画像 */}
      {image ? <MediaObjectBox px={2}>{image}</MediaObjectBox> : null}

      {/* 本体 */}
      <Flex flex="1 1" flexDirection={mediaStyle === 0 ? "column" : undefined}>
        <Box px={2}>{<Text fontSize={2}>{content}</Text>}</Box>
        {detail ? (
          <Box px={2}>
            <Text fontSize={1}>{detail}</Text>
          </Box>
        ) : null}
      </Flex>

      {/* アクセサリ */}
      {accessory ? (
        <Box ml={"auto"} px={2} {...(accessoryProps || {})}>
          {accessory}
        </Box>
      ) : null}
    </Flex>
  )
}

/** 複合したコンテンツを配置するコンテナ
 *
 * Bulmaのmediaに加えて、
 * UITableViewCellのスタイルと同じものを実現できる
 *
 * @kind molecules
 * **/
export const MediaObject = styled(MediaObjectElement)(margin, padding)

MediaObject.displayName = "MediaObject"

// 画像を
const MediaObjectBox = styled(Box)`
  img {
    display: block;
  }
`
